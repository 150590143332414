import React, {  useState } from 'react';
import Signup from './signup';
import Index from './index';

const MatchOrSignup = () => {
  // Check if 'ck_id' is present in localStorage
  const ckId=localStorage.getItem('match_ck_id')



  // Conditionally render components based on the presence of 'ck_id'
  return ckId ? <Index /> : <Signup />;
};

export default MatchOrSignup;

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import { Grid, Stack } from '@mui/material';
import CreatePopup from './create';
import EditPopup from './edit';
import { styled } from '@mui/material/styles';
import Moment from 'moment';
import Alert from '../../components/alert';
import Loader from '../../components/loader';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#07090A",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function Index() {
  const navigate = useNavigate();
  // const [open, setOpen] = useState({});
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  // const [openDelete, setOpenDelete] = useState(false);
  const [alert, setAlert] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [type, setType] = useState('');
  const [data, setData] = useState([]);

  const [onFilter, setOnFilters] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [data_id, setDataID] = useState(null);
  const [record, setRecord] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [message, setMessage] = useState('');
  const login_id = localStorage.getItem("ck_bki");
  const bundle = localStorage.getItem("ck_aat");

  const callApi = () => {
    setLoader(true);
    if (bundle != null) {
      // setAccessToken(bundle);
    } else {
      setAlert(true);
      setType('error');
      setMessage('No Token Found');
      navigate('/login', { replace: true });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${bundle}`
      },
    };

    fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/deals?page=${page}&size=${size}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if ('error' in data) {
          setLoader(false)
          setAlert(true);
          setType('error');
          setMessage(data.message);
        } else {
          if (data.dataItems.length !== undefined) {
            setData(data.dataItems);
            setTotalUsers(data.totalItems);
            setTotalItems(data.totalItems % size ? (Math.floor(data.totalItems / size) + 1) : Math.floor(data.totalItems / size));
            setLoader(false);
          } else {
            setLoader(false)
            setAlert(true);
            setType('error');
            setMessage(data.message);
          }
        }
      });
  }
  useEffect(() => {
    callApi();
  }, [page, size]);// eslint-disable-line react-hooks/exhaustive-deps

  const openCreatePopup = () => {
    setOpenCreate(!openCreate);
  };
  const openEditPopup = (e, id) => {
    setDataID(id);
    setRecord(data.find(o => o.id === id));
    setOpenEdit(!openEdit);
  };

  if (onFilter === true) {
    callApi();
    setOnFilters(false);
  }

  const handlePagination = (e, p) => {
    setPage(p - 1);
  }
  const refreshTable = () => {
    callApi();
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" >
        <Typography variant="h5" sx={{ paddingY: '10px' }}>Deals</Typography>
      </Stack>
      {isLoading === true ? <Loader show={isLoading} /> :
        <>
          <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
            <Table sx={{ minWidth: 650 }} aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px',  }}>ID</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px', width:'80%' }}>Deal</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Created Date</StyledTableCell>
                  {/* <StyledTableCell sx={{ textAlign:'center',  fontSize: '13px', padding: '4px'  }}>Action</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {(data.length > 0 && data !== undefined) ? data.map((item, i) => (
                  <React.Fragment key={item.id}>
                    <TableRow >
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', padding: '0px',  }}>{item.id}</TableCell>
                      <TableCell sx={{ textAlign: 'justify', margin: '0px', fontSize: '13px', padding: '0px', width:'80%' }}>{item.deal}</TableCell>
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', padding: '0px' }}>{new Moment(new Date(item.createdAt)).format('DD-MM-yyyy hh:mm:ss A')}</TableCell>
                    </TableRow>
                  </React.Fragment>
                )) : <TableRow>
                  <TableCell component="th" scope="item" colSpan={3}>No Record Found</TableCell>
                </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4}><Typography sx={{ paddingTop: '20px', paddingRight: '10px', fontWeight: '600' }}>Total Keywords: {totalUsers}</Typography></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              {data.length > 0 ? <Pagination count={totalItems} page={page + 1} color="primary" sx={{ paddingY: '20px' }} onChange={(e, page) => handlePagination(e, page)} /> : ''}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormControl sx={{ minWidth: 100, marginTop: '15px', float: 'right' }} size="small">
                <InputLabel id="demo-simple-select-helper-label">Size Per Page</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={size}
                  label="Size Per Page"
                  onChange={(e) => setSize(e.target.value)}
                >
                  <MenuItem value="25">25</MenuItem>
                  <MenuItem value="50">50</MenuItem>
                  <MenuItem value="100">100</MenuItem>
                  <MenuItem value="500">500</MenuItem>
                  <MenuItem value="1000">1000</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <CreatePopup openPopup={openCreate} setOpenPopup={openCreatePopup} setMessage={setMessage} setAlert={setAlert} setType={setType} admin_id={login_id} refreshTable={refreshTable} />
          <EditPopup openPopup={openEdit} setOpenPopup={openEditPopup} setMessage={setMessage} setAlert={setAlert} setType={setType} admin_id={login_id} refreshTable={refreshTable} data_id={data_id} record={record} />
          <Alert open={alert} type={type} message={message} setOpen={setAlert} />
        </>
      }
    </>);
}

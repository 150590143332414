import * as React from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Alert from './../../components/alert';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { TextField, Typography } from '@mui/material';
import Fab from '@mui/material/Fab';
import CryptoJS from "crypto-js";

export default function Login() {
    const navigate = useNavigate();
    const [alert, setAlert] = useState(false);
    const [type, setType] = useState('');
    const [message, setMessage] = useState('');

    // Define the hardcoded credentials
    const validUser = {
        email: "admin@cashkaro.com",
        password: "admin@123" 
    };

    const formik = useFormik({
        initialValues: {
          email: '',
          password: '',
        },
        onSubmit: values => {
            if (values.email === validUser.email && values.password === validUser.password) {
                localStorage.setItem('user', JSON.stringify({ email: values.email }));
                navigate('/admin/index'); // Redirect to dashboard or another route on success
            } else {
                setAlert(true);
                setType('error');
                setMessage('Invalid email or password');
            }
        }
    });

    const { handleSubmit, getFieldProps } = formik;

    return (
        <Box style={{backgroundColor:'#2F3C97', minHeight:'100vh', maxHeight:'100vh', overflow: 'hidden'}}>
            <Grid container>
                <Grid item xs={12} sm={12} md={4}></Grid>
                <Grid item xs={12} sm={12} md={4} sx={{marginTop:'110px', textAlign: 'center'}}>
                    <div style={{justifyContent:'center', fontSize:'20px', fontFamily:'BarlowLight'}}>
                        <img src="https://asset20.ckassets.com/wp/wp-content/uploads/sites/37/2020/04/5e8dbdd7383b9.png" alt=""/><br />
                        <span style={{ color: '#EE7430' }}>CashKaro</span><span style={{ color: '#FFFFFF' }}> Leaderboard</span>
                    </div>
                    <Card sx={{ padding:'20px', margin:'10px'}}>
                        <CardContent>
                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <Typography variant="h5" sx={{fontWeight:'600'}}>Login to your account</Typography>
                                    <Typography variant="subtitle1" sx={{marginBottom:'10px'}}>Enter your username and password</Typography>
                                    <TextField
                                        fullWidth
                                        type="email"
                                        variant="outlined"
                                        label="Enter Email"
                                        {...getFieldProps('email')}
                                        autoFocus
                                        sx={{marginBottom:'10px'}}
                                    />
                                    <TextField
                                        fullWidth
                                        type="password"
                                        variant="outlined"
                                        label="Enter Password"
                                        {...getFieldProps('password')}
                                        sx={{marginBottom:'10px'}}
                                    />
                                    <Fab variant="extended" type="submit" sx={{ width: '100%', bottom:'-20px',backgroundColor:'#F07431', textTransform:'capitalize', color:'white', ':hover': {bgcolor: '#07090A', color: '#FFFFFF', }}} >Submit</Fab>
                                </Form>
                            </FormikProvider>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <div style={{ color:'#FFFFFF', display:'flex', marginBottom:'10px', justifyContent:'center'}}>
              <p>Copyright © 2024 CashKaro. All Rights Reserved</p>
            </div>
            <Alert open={alert} type={type} setOpen={setAlert} message={message} />
        </Box>
    );
}

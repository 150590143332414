import * as React from 'react';
import ShortnerSchema from '../validations/shortner';
import { useFormik, Form, FormikProvider } from 'formik';
import { useState, useEffect,  } from 'react';
import { useNavigate,useSearchParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Alert from './../components/alert'
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { TextField, Typography } from '@mui/material';
import Fab from '@mui/material/Fab';
import CryptoJS from "crypto-js";
import Loader from '../components/loader';
import NotFoundImage from '../images/not_found.jpg';

export default function Shortner() {
    const navigate = useNavigate();
    
    return (
        <Box style={{ backgroundColor: '#2F3C97', minHeight: '100vh', maxHeight: '100vh', overflow: 'hidden' }}>
            <Grid container >
                <Grid item xs={12} sm={12} md={12} >
                    <img src={NotFoundImage} style={{ width: '100%', height: '100vh' }}/>
                </Grid>
            </Grid>
        </Box>
    );
}

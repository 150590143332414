import React, { useRef } from 'react';

import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { Box } from '@mui/material';

export default function View(props) {
    const dialogBox = useRef()
    const { openPopup, setOpenPopup } = props;
    const login_id = localStorage.getItem("ck_bki");
    const record = props?.record ? props.record : {};
  
    return (
        <Dialog
            ref={dialogBox}
            open={openPopup}
            onClose={() => { setOpenPopup(false) }}
            maxWidth="md"
           
            >   
            <Box sx={{ margin: 2 }}>
                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography variant="h6" sx={{fontWeight:600}} >Store Details</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Button variant="text" type="button" size="small" color="primary" sx={{ float: 'right', textTransform: 'capitalize', marginRight: '10px' }} onClick={() => setOpenPopup(!openPopup) }>Close</Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Network Name:</span> {record.network_name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Network Link:</span> {record.network_link}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Network URL:</span> {record.network_url}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>UTM Parameter:</span> {record.network_utm}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Missing Cashback:</span> {record.missing_cb}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Rankng:</span> {record.ranking}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Tracking Time:</span> {record.tracking_time}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Confirmation Time:</span> {record.confirmation_time}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Cashback Type:</span> {record.cashback_type}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Total Commission:</span> ₹{record.total_commission ? parseFloat(record.total_commission).toFixed(2) : '0'}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Cashback:</span> ₹{record.cashback ? parseFloat(record.cashback).toFixed(2) : '0'}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Cashback %:</span> {record.cashback_percent ? parseFloat(record.cashback_percent).toFixed(1) : '0'}%</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Include in Search:</span> {record.search === true ? <span style={{color:'#1A5F35'}}>ON</span> : <span style={{color:'#DB1411'}}>OFF</span>}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Include in Sitemap:</span> {record.sitemap === true ? <span style={{color:'#1A5F35'}}>ON</span> : <span style={{color:'#DB1411'}}>OFF</span>}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Status:</span> {record.status === true ? <span style={{color:'#1A5F35'}}>ON</span> : <span style={{color:'#DB1411'}}>OFF</span>}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Button Text:</span> {record.button_text}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Image :</span> </Typography>
                        <img src={record.image} style={{width:'200px'}} alt=""/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Paytm Title:</span> {record.paytm_title}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Paytm Description:</span></Typography>
                        <div dangerouslySetInnerHTML={{__html: record.paytm_description}} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                        <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Paytm T&C:</span></Typography>
                        <div dangerouslySetInnerHTML={{__html: record.paytm_tnc}} />
                    </Grid>
                </Grid>
            </Box>
            
        </Dialog>
    )
}
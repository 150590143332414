import React, { useRef, useEffect } from 'react';
import { useState } from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Grid, Paper, Typography, SwipeableDrawer, TextField, Box, CircularProgress, Divider } from '@mui/material';
// import Slice1 from '../../images/matchkaro/Slices1.gif';
import Slice1 from '../../images/Bonanza/Section1.png';
import Slice2 from '../../images/Bonanza/Section2.png';
import Slice3 from '../../images/Bonanza/Section3.webp';
import mask from '../../images/Bonanza/mask.png';
import IconAmazon from '../../images/Bonanza/amazon-icon.png';
import BgIcon from '../../images/Bonanza/iconbg.png';
import Icon3 from '../../images/Bonanza/icon3.png';
import Icon1 from '../../images/Bonanza/icon1.png';
import Icon5 from '../../images/Bonanza/icon5.png';
import Icon6 from '../../images/Bonanza/icon6.png';
import Icon7 from '../../images/Bonanza/icon7.png';
import Icon32 from '../../images/Bonanza/icon32.png';
import Badge from '../../images/Bonanza/badge.png';
import Tip from '../../images/Bonanza/tip.png';
import Line from '../../images/Bonanza/line.png';
import BGLogo from '../../images/Bonanza/logoBG.png';
import Icon from '@mui/icons-material/CheckCircle';
import Section4 from '../../images/Bonanza/Section4.png';
import Section5 from '../../images/Bonanza/section5.png';
import Section6 from '../../images/Bonanza/section6.png';
import logo1 from '../../images/Bonanza/logos/logo1.png';
import logo18 from '../../images/Bonanza/logos/logo18.png';
import logo3 from '../../images/Bonanza/logos/logo3.png';
import logo4 from '../../images/Bonanza/logos/logo4.png';
import logo5 from '../../images/Bonanza/logos/logo5.png';
import logo6 from '../../images/Bonanza/logos/logo6.png';
import logo7 from '../../images/Bonanza/logos/logo7.png';
import logo8 from '../../images/Bonanza/logos/logo8.png';
import logo9 from '../../images/Bonanza/logos/logo9.png';
import logo10 from '../../images/Bonanza/logos/logo10.png';
import logo16 from '../../images/Bonanza/logos/logo16.png';
import logo19 from '../../images/Bonanza/logos/logo19.png';
import logo20 from '../../images/Bonanza/logos/logo20.png';
import logo21 from '../../images/Bonanza/logos/logo21.png';
import logo22 from '../../images/Bonanza/logos/logo22.png';
import logo23 from '../../images/Bonanza/logos/logo23.png';

import badge1 from '../../images/Bonanza/badge1.png';
import badge2 from '../../images/Bonanza/badge2.png';
import badge3 from '../../images/Bonanza/badge3.png';
import CustomProgressBar from '../../components/CustomProgressBar';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import matchKaroSchema from '../../validations/matckarosignupSchema';
import zIndex from '@mui/material/styles/zIndex';
import { useTheme, useMediaQuery, } from '@mui/material';
import { Padding } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    '& .MuiLinearProgress-bar': {
        borderRadius: 5,
        background: theme.palette.mode === ' #FFFFFF33'
            ? 'linear-gradient(270deg, #00FF57 0%, #6BFF9D 100%)'
            : 'linear-gradient(270deg, #00FF57 0%, #6BFF9D 100%)',
    },
}));


const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .MuiTooltip-tooltip`]: {
        backgroundColor: '#54FF8E4D',
        color: 'white', // Set the text color if needed
        marginBottom: '1px !important',
    },
});

export default function Signup() {
    const [alert, setAlert] = useState(false);

    const navigate = useNavigate();
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [fieldset, setfieldset] = useState(false)
    let mobile = localStorage.getItem('match_mobile')
    const [milestone1, setMilestone1] = useState(0);
    const [milestone2, setMilestone2] = useState(0);
    const [milestone3, setMilestone3] = useState(0);
    const [segment, setSegment] = useState(null)

    const [btnOn, setBtnOn] = React.useState(true);
    const [checked, setChecked] = React.useState(true);
    const formRef = useRef(null)
    const formReff = useRef(null)
    const scrollToElementt = () => {
        formReff.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    const [type, setType] = useState('');
    const [message, setMessage] = useState('');
    const [progress, setProgress] = useState(0);
    const [showContent, setShowContent] = useState(false);
    const [signupSuccess, setSignupSuccess] = useState(true);
    const [loading, setLoading] = useState(true);
    const [encId, setEncId] = useState('');
    // const [match_ck_id, setck_id] = useState({
    //     enc_id: '',
    // });
    // const [userData, setUserData] = useState({
    //     matchMobile: '',
    //     matchCkId: ''
    // });
    const [storevisit, setStorevisit] = useState();
    const [exits, setExist] = useState();
    const [dailyopen, setDailyOpen] = useState();
    const [earning, setEarning] = useState();
    const [transaction, setTransaction] = useState();
    const [refferal, setRefferal] = useState();
    const [withdraw, setWithdraw] = useState();
    const [imageLoading,setImageLoading] = useState(true)
    const [currentValue, setCurrentValue] = useState(null); // for community
    // const maxValue = 10000000; // for community
    // const percentage = (currentValue / maxValue) * 100;
    const [dailyLimits,setDailyLimits] = useState(null)

    const [milestoneProgress, setMilestoneProgress] = useState(null)
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const [dailySteakValue, setDailySteakValue] = useState(15); // for daily steak
    // const dailySteakmaxValue = 30; // for daily steak
    // const dailypercentage = (dailySteakValue / dailySteakmaxValue) * 100;

    function formatNumber(number) {
        if (number >= 100000) {
            return (number / 100000).toFixed(1) + 'L';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'K';
        } else {
            return number.toString();
        }
    }

    const updateDailyLimits = (segment) => {
        switch (segment) {
          case 1:
            setDailyLimits({
              appLaunches: 2,
              storePageVisits: 10,
              exits: 25,
              transactions: 'No cap',
              referralInitiated: 10,
              withdrawals: 5,
              myEarningsPageVisits: 2,
            });
            break;
          case 2:
            setDailyLimits({
              appLaunches: 2,
              storePageVisits: 4,
              exits: 10,
              transactions: 'No cap',
              referralInitiated: 2,
              withdrawals: 5,
              myEarningsPageVisits: 1,
            });
            break;
          case 3:
            setDailyLimits({
              appLaunches: 2,
              storePageVisits: 4,
              exits: 10,
              transactions: 'No cap',
              referralInitiated: 2,
              withdrawals: 5,
              myEarningsPageVisits: 1,
            });
            break;
          case 4:
            setDailyLimits({
              appLaunches: 2,
              storePageVisits: 4,
              exits: 10,
              transactions: 'No cap',
              referralInitiated: 2,
              withdrawals: 5,
              myEarningsPageVisits: 1,
            });
            break;
          case 5:
            setDailyLimits({
              appLaunches: 2,
              storePageVisits: 4,
              exits: 10,
              transactions: 'No cap',
              referralInitiated: 2,
              withdrawals: 5,
              myEarningsPageVisits: 1,
            });
            break;
          case 6:
            setDailyLimits({
              appLaunches: 2,
              storePageVisits: 4,
              exits: 10,
              transactions: 'No cap',
              referralInitiated: 2,
              withdrawals: 5,
              myEarningsPageVisits: 1,
            });
            break;
          default:
            console.error('Invalid segment number');
        }
      };
    

    const updateMilestones = (segment) => {
        switch (segment) {
            case 1:
                setMilestone1(1100);
                setMilestone2(1500);
                setMilestone3(2000);
                break;
            case 2:
                setMilestone1(600);
                setMilestone2(800);
                setMilestone3(1100);
                break;
            case 3:
                setMilestone1(600);
                setMilestone2(800);
                setMilestone3(1100);
                break;
            case 4:
                setMilestone1(400);
                setMilestone2(600);
                setMilestone3(800);
                break;
            case 5:
                setMilestone1(400);
                setMilestone2(600);
                setMilestone3(800);
                break;
            case 6:
                setMilestone1(400);
                setMilestone2(600);
                setMilestone3(800);
                break;
            default:
                console.error('Invalid segment number');
        }
    };
    useEffect(() => {      //for tooltip
        const timeout = setTimeout(() => {
            setTooltipOpen(true);
        }, 500);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        const ck_id = localStorage.getItem('bonanza_ck_id'); // Get the id first to check it's not null
        const fetchUserScore = async () => {
            try {
                // Correctly embedding the local storage value in the URL
                if (!ck_id) {
                    throw new Error('No ck_id found in local storage.');
                }

                const url = `${process.env.REACT_APP_SITE_URL_BIGBONANZA}/api/user/getuserdata_dc?ck_id=${ck_id}`;
                const response = await fetch(url, {
                    method: 'GET', // Assuming GET is the method to use
                    headers: {
                        'Content-Type': 'application/json',
                    },

                });

                let res = await response.json()
                console.log(res)
                if(!res?.user_data){
                    localStorage.removeItem('bonanza_ck_id')
                    window.location.reload()
                }
                setSegment(res?.user_data?.segment)
                updateMilestones(res?.user_data?.segment)
                updateDailyLimits(res?.user_data?.segment)
                setDailySteakValue(res?.user_data?.streak)
                setCurrentValue(res?.total_community_points)
                setMilestoneProgress(res?.user_data?.total_points)
                setWithdraw(res?.user_data?.withdrawals)
                setRefferal(res?.user_data?.referrals)
                setTransaction(res?.user_data?.transactions)
                setEarning(res?.user_data?.my_earnings_page_visits)
                setDailyOpen(res?.user_data?.app_launches)
                setExist(res?.user_data?.exits)
                setStorevisit(res?.user_data?.store_page_visits)
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.error('Error fetching score:', error);
            }

        };

        if (ck_id) {
            fetchUserScore()
        }


    }, []);
    return (
        <div>


            {loading ? <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress color="inherit" />
            </Box> :
                <Grid container spacing={0} sx={{ background: '#0D0D0D' }}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} >
                        <Grid item xs={12}>
                            <img loading="lazy" onLoad={()=>{
                                
                                setImageLoading(false)
                            }} src={Slice1} alt="Slice1" style={{ width: '100%', padding: '0', margin: '0' }} />
                        </Grid>

                        <Grid container xs={12} mt={-0.1} pt={14} pb={6} style={{ backgroundImage: `url(${Slice2})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {/* <Grid item xs={12} mt={5} style={{ textAlign: 'center', }}>
                                <img loading="lazy" src={Badge} alt="Badge" style={{ maxWidth: '85%', }} />
                            </Grid> */}
                            <Grid container mt={5}>
                                <Grid item xs={4} sm={12} md={4} lg={4} >
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                        <img loading="lazy" src={badge1} alt="badge1" style={{ width: '60%', filter: milestoneProgress >= milestone1 ? 'none' : 'grayscale(100%)' }} />
                                    </Box>
                                </Grid>

                                <Grid item xs={4} sm={12} md={4} lg={4} ml={-0.875}>
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                        <img loading="lazy" src={badge2} alt="badge2" style={{ width: '80%', filter: milestoneProgress >= milestone2 ? 'none' : 'grayscale(100%)' }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={4} sm={12} md={4} lg={4} ml={-1}>
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                        <img loading="lazy" src={badge3} alt="badge3" style={{ width: '80%', filter: milestoneProgress >= milestone3 ? 'none' : 'grayscale(100%)' }} />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container mt={3}>
                                <Grid item xs={4} sm={12} md={4} lg={4} ml={0.287} position="relative">
                                    <Box display="flex" justifyContent="center" alignItems="center" position="relative">
                                        <img loading="lazy" src={Tip} alt="Tip" style={{ width: '60%' }} />
                                        <Typography
                                            mt={-2}
                                            sx={{
                                                fontSize: '0.75rem',
                                                fontFamily: 'Metropolis',
                                                fontWeight: '500',
                                                color: "#fff",
                                                position: 'absolute',
                                                // top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, 70%)',
                                                textAlign: 'center', // Optional: Center text horizontally
                                            }}
                                        >
                                            {milestone1} 💎
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={4} sm={12} md={4} lg={4} ml={-1} position="relative">
                                    <Box display="flex" justifyContent="center" alignItems="center" position="relative">
                                        <img loading="lazy" src={Tip} alt="Tip" style={{ width: '60%' }} />
                                        <Typography
                                            mt={-2}
                                            sx={{
                                                fontSize: '0.75rem',
                                                fontFamily: 'Metropolis',
                                                fontWeight: '500',
                                                color: "#fff",
                                                position: 'absolute',
                                                // top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, 70%)',
                                                textAlign: 'center', // Optional: Center text horizontally
                                            }}
                                        >
                                            {milestone2} 💎
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4} sm={12} md={4} lg={4} ml={-1} position="relative">
                                    <Box display="flex" justifyContent="center" alignItems="center" position="relative">
                                        <img loading="lazy" src={Tip} alt="Tip" style={{ width: '60%' }} />
                                        <Typography
                                            mt={-2}
                                            sx={{
                                                fontSize: '0.75rem',
                                                fontFamily: 'Metropolis',
                                                fontWeight: '500',
                                                color: "#fff",
                                                position: 'absolute',
                                                // top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, 70%)',
                                                textAlign: 'center', // Optional: Center text horizontally
                                            }}
                                        >
                                            {milestone3} 💎
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} sm={12} md={12} px={4} pt={4} sx={{ width: '100%', }}>
                                <Box position="relative">
                                    <ProgressBar
                                        completed={milestoneProgress}
                                        maxCompleted={milestone3}
                                        className="wrapper"
                                        bgColor={'linear-gradient(#1ef93b , #75db75)'}
                                        width='100%'
                                        baseBgColor={`#FFFFFF33`}
                                        border={'1px solid #FFFFFF66'}
                                        height='18px'
                                        customLabelStyles={{ padding: 0 }}
                                        borderRadius={'3px'}
                                        customLabel={
                                            <div
                                                style={{
                                                    zIndex: '99999',
                                                    display: 'flex',
                                                    // marginLeft: milestoneProgress === 0 ? '0' : '-20px',
                                                    justifyContent: 'center',
                                                    position: 'absolute',
                                                    alignItems: 'center',
                                                    overflow: 'visible',
                                                }}
                                            >
                                                {!imageLoading && tooltipOpen && (
                                                    <CustomTooltip
                                                        title={`You are at ${formatNumber(milestoneProgress)} 💎`}
                                                        open
                                                        placement="bottom"
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: '-25px', // Adjust as needed
                                                               // Adjust as needed
                                                            zIndex: 9999,
                                                        }}
                                                    >
                                                        <ArrowDropDownIcon sx={{ color: milestoneProgress === 0 ? '#FFFFFF33' : '#59e562' }} />
                                                    </CustomTooltip>
                                                )}
                                            </div>
                                        }

                                    />

                                </Box>

                            </Grid>

                        </Grid>

                        <Grid container xs={12} mt={-0.5} sx={{ backgroundImage: `url(${mask})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', }}>
                            <Grid item xs={12} pt={3} px={2}>
                                <Typography py={0.675} sx={{
                                    fontWeight: '500', fontSize: '0.75rem', fontFamily: 'Metropolis', color: '#fff', textAlign: 'center', background: '#FF8A0040', borderRadius: '4px'
                                }}><ErrorOutlineIcon sx={{ fontSize: '1rem' }} /> Points updated every 48-72 hours subject to <a onClick={scrollToElementt}><span><u><b>T&C.</b></u></span></a></Typography>
                            </Grid>
                            <Grid item xs={12} py={6}>
                                <Typography sx={{ fontWeight: '700', fontSize: '1.25rem', color: '#fff', fontFamily: 'Metropolis', textAlign: 'center' }}>Your guide to earning big!</Typography>
                                <Typography sx={{ fontWeight: '400', fontSize: '0.75rem', color: '#fff', fontFamily: 'Metropolis', textAlign: 'center' }}>Complete these activities & level up your points! </Typography>
                            </Grid>
                            <Grid container px={2}>
                                <Grid item xs={4} md={4} >
                                    <Box display="flex" justifyContent="center" >
                                        <img loading="lazy" src={Icon1} alt="Icon1" style={{ width: '70%', }} />
                                    </Box>
                                    <Box display="flex" justifyContent="center" alignItems="center" position="relative" mt={-2.25}>
                                        <img loading="lazy" src={BgIcon} alt="BgIcon" style={{ width: '90%' }} />
                                        <Typography
                                            mt={-2.4}
                                            sx={{
                                                fontSize: '0.625rem',
                                                fontFamily: 'Metropolis',
                                                fontWeight: '500',
                                                color: "#fff",
                                                position: 'absolute',
                                                // top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, 70%)',
                                                textAlign: 'center', // Optional: Center text horizontally
                                                width: '100%'
                                            }}
                                        >
                                            Earned {dailyopen} 💎
                                        </Typography>
                                    </Box>
                                    <Box mx={2}>
                                        <Typography mt={2} sx={{
                                            fontSize: '9px',
                                            fontFamily: 'Metropolis',
                                            fontWeight: '600',
                                            color: "#fff",
                                            textAlign: 'center'
                                        }}>Daily cap: {dailyLimits.appLaunches}</Typography>
                                        <img src={Line} alt='line' style={{ width: '100%' }} />
                                        <Typography sx={{ fontSize: '400', color: '#fff', fontSize: '11px', textAlign: 'center', fontFamily: 'Metropolis' }}>Open CashKaro app daily</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={4} >
                                    <Box display="flex" justifyContent="center" >
                                        <img loading="lazy" src={IconAmazon} alt="IconAmazon" style={{ width: '70%' }} />
                                    </Box>
                                    <Box display="flex" justifyContent="center" alignItems="center" position="relative" mt={-2.25}>
                                        <img loading="lazy" src={BgIcon} alt="BgIcon" style={{ width: '90%' }} />
                                        <Typography
                                            mt={-2.4}
                                            sx={{
                                                fontSize: '0.625rem',
                                                fontFamily: 'Metropolis',
                                                fontWeight: '500',
                                                color: "#fff",
                                                position: 'absolute',
                                                // top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, 70%)',
                                                textAlign: 'center', // Optional: Center text horizontally
                                                width: '100%'
                                            }}
                                        >
                                            Earned {storevisit} 💎
                                        </Typography>
                                    </Box>
                                    <Box mx={2}>
                                        <Typography mt={2} sx={{
                                            fontSize: '9px',
                                            fontFamily: 'Metropolis',
                                            fontWeight: '600',
                                            color: "#fff",
                                            textAlign: 'center'
                                        }}>Daily cap: {dailyLimits.storePageVisits}</Typography>
                                        <img src={Line} alt='line' style={{ width: '100%' }} />
                                        <Typography sx={{ fontSize: '400', color: '#fff', fontSize: '11px', textAlign: 'center', fontFamily: 'Metropolis' }}>Visit any of your favorite brands like Amazon & Flipkart on CashKaro</Typography>
                                    </Box>


                                </Grid>
                                <Grid item xs={4} md={4} >
                                    <Box display="flex" justifyContent="center" >
                                        <img loading="lazy" src={Icon32} alt="Icon32" style={{ width: '70%' }} />
                                    </Box>
                                    <Box display="flex" justifyContent="center" alignItems="center" position="relative" mt={-2.25}>
                                        <img loading="lazy" src={BgIcon} alt="BgIcon" style={{ width: '90%' }} />
                                        <Typography
                                            mt={-2.4}
                                            sx={{
                                                fontSize: '0.625rem',
                                                fontFamily: 'Metropolis',
                                                fontWeight: '500',
                                                color: "#fff",
                                                position: 'absolute',
                                                // top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, 70%)',
                                                textAlign: 'center', // Optional: Center text horizontally
                                                width: '100%'
                                            }}
                                        >
                                            Earned {exits} 💎
                                        </Typography>
                                    </Box>
                                    <Box mx={2}>
                                        <Typography mt={2} sx={{
                                            fontSize: '9px',
                                            fontFamily: 'Metropolis',
                                            fontWeight: '600',
                                            color: "#fff",
                                            textAlign: 'center'
                                        }}>Daily cap: {dailyLimits.exits}</Typography>
                                        <img src={Line} alt='line' style={{ width: '100%' }} />
                                        <Typography sx={{ fontSize: '400', color: '#fff', fontSize: '11px', textAlign: 'center', fontFamily: 'Metropolis' }}>Go to a brand’s website or app through CashKaro</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container px={2} py={3}>
                                <Grid item xs={4} md={4} >
                                    <Box display="flex" justifyContent="center" >
                                        <img loading="lazy" src={Icon3} alt="Icon3" style={{ width: '70%', }} />
                                    </Box>
                                    <Box display="flex" justifyContent="center" alignItems="center" position="relative" mt={-2.25}>
                                        <img loading="lazy" src={BgIcon} alt="BgIcon" style={{ width: '90%' }} />
                                        <Typography
                                            mt={-2.4}
                                            sx={{
                                                fontSize: '0.625rem',
                                                fontFamily: 'Metropolis',
                                                fontWeight: '500',
                                                color: "#fff",
                                                position: 'absolute',
                                                // top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, 70%)',
                                                textAlign: 'center', // Optional: Center text horizontally
                                                width: '100%'
                                            }}
                                        >
                                            Earned {transaction} 💎
                                        </Typography>
                                    </Box>
                                    <Box mx={2}>
                                        <Typography mt={2} sx={{
                                            fontSize: '9px',
                                            fontFamily: 'Metropolis',
                                            fontWeight: '600',
                                            color: "#fff",
                                            textAlign: 'center'
                                        }}>No daily cap</Typography>
                                        <img src={Line} alt='line' style={{ width: '100%' }} />
                                        <Typography sx={{ fontSize: '400', color: '#fff', fontSize: '11px', textAlign: 'center', fontFamily: 'Metropolis' }}>Make a purchase on any brand through CashKaro</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={4} >
                                    <Box display="flex" justifyContent="center" >
                                        <img loading="lazy" src={Icon5} alt="Icon5" style={{ width: '70%' }} />
                                    </Box>
                                    <Box display="flex" justifyContent="center" alignItems="center" position="relative" mt={-2.25}>
                                        <img loading="lazy" src={BgIcon} alt="BgIcon" style={{ width: '90%' }} />
                                        <Typography
                                            mt={-2.4}
                                            sx={{
                                                fontSize: '0.625rem',
                                                fontFamily: 'Metropolis',
                                                fontWeight: '500',
                                                color: "#fff",
                                                position: 'absolute',
                                                // top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, 70%)',
                                                textAlign: 'center', // Optional: Center text horizontally
                                                width: '100%'
                                            }}
                                        >
                                            Earned {refferal} 💎
                                        </Typography>
                                    </Box>
                                    <Box mx={2}>
                                        <Typography mt={2} sx={{
                                            fontSize: '9px',
                                            fontFamily: 'Metropolis',
                                            fontWeight: '600',
                                            color: "#fff",
                                            textAlign: 'center'
                                        }}>Daily cap: {dailyLimits.referralInitiated}</Typography>
                                        <img src={Line} alt='line' style={{ width: '100%' }} />
                                        <Typography sx={{ fontSize: '400', color: '#fff', fontSize: '11px', textAlign: 'center', fontFamily: 'Metropolis' }}>Refer CashKaro to at least 1 friend</Typography>
                                    </Box>


                                </Grid>
                                <Grid item xs={4} md={4} >
                                    <Box display="flex" justifyContent="center" >
                                        <img loading="lazy" src={Icon6} alt="Icon6" style={{ width: '70%' }} />
                                    </Box>
                                    <Box display="flex" justifyContent="center" alignItems="center" position="relative" mt={-2.25}>
                                        <img loading="lazy" src={BgIcon} alt="BgIcon" style={{ width: '90%' }} />
                                        <Typography
                                            mt={-2.4}
                                            sx={{
                                                fontSize: '0.625rem',
                                                fontFamily: 'Metropolis',
                                                fontWeight: '500',
                                                color: "#fff",
                                                position: 'absolute',
                                                // top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, 70%)',
                                                textAlign: 'center', // Optional: Center text horizontally
                                                width: '100%',
                                                fontFamily: 'Metropolis'
                                            }}
                                        >
                                            Earned {withdraw} 💎
                                        </Typography>
                                    </Box>
                                    <Box mx={2}>
                                        <Typography mt={2} sx={{
                                            fontSize: '9px',
                                            fontFamily: 'Metropolis',
                                            fontWeight: '600',
                                            color: "#fff",
                                            textAlign: 'center'
                                        }}>Daily cap: {dailyLimits.withdrawals}</Typography>
                                        <img src={Line} alt='line' style={{ width: '100%' }} />
                                        <Typography sx={{ fontSize: '400', color: '#fff', fontSize: '11px', textAlign: 'center', fontFamily: 'Metropolis' }}>Withdraw your cashback or rewards</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container px={2} pb={3}>
                                <Grid item xs={4} md={4} >
                                </Grid>
                                <Grid item xs={4} md={4} >
                                    <Box display="flex" justifyContent="center" >
                                        <img loading="lazy" src={Icon7} alt="Icon7" style={{ width: '70%' }} />
                                    </Box>
                                    <Box display="flex" justifyContent="center" alignItems="center" position="relative" mt={-2.25}>
                                        <img loading="lazy" src={BgIcon} alt="BgIcon" style={{ width: '90%' }} />
                                        <Typography
                                            mt={-2.4}
                                            sx={{
                                                fontSize: '0.625rem',
                                                fontFamily: 'Metropolis',
                                                fontWeight: '500',
                                                color: "#fff",
                                                position: 'absolute',
                                                // top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, 70%)',
                                                textAlign: 'center', // Optional: Center text horizontally
                                                width: '100%'
                                            }}
                                        >
                                            Earned {earning} 💎
                                        </Typography>
                                    </Box>
                                    <Box mx={2}>
                                        <Typography mt={2} sx={{
                                            fontSize: '9px',
                                            fontFamily: 'Metropolis',
                                            fontWeight: '600',
                                            color: "#fff",
                                            textAlign: 'center'
                                        }}>Daily cap: {dailyLimits.myEarningsPageVisits}</Typography>
                                        <img src={Line} alt='line' style={{ width: '100%' }} />
                                        <Typography sx={{ fontSize: '400', color: '#fff', fontSize: '11px', textAlign: 'center', fontFamily: 'Metropolis' }}>Visit your earnings page to track your savings and more</Typography>
                                    </Box>


                                </Grid>
                                <Grid item xs={4} md={4} >

                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ position: 'relative' }}>
                                <img
                                    loading="lazy"
                                    src={Section4}
                                    alt="Section4"
                                    style={{ width: '100%', padding: 0, margin: 0 }}
                                />
                                <div style={{ position: 'absolute', top: '80%', left: '10%', width: '80%', paddingX: '1rem' }}>
                                    <ProgressBar
                                        completed={dailySteakValue}
                                        className="wrapper"
                                        maxCompleted={30}
                                        bgColor={'linear-gradient(#1ef93b , #75db75)'}
                                        baseBgColor={`#FFFFFF33`}
                                        border={'1px solid #FFFFFF66'}
                                        height='18px'
                                        borderRadius={'3px'}
                                        customLabel={
                                            <div
                                                style={{
                                                    zIndex: '99999',
                                                    display: 'flex',
                                                    marginLeft: dailySteakValue === 0 ? '0' : '-12px',
                                                    left:'10px !important',
                                                    justifyContent: 'center',
                                                    position: 'absolute',
                                                    alignItems: 'center',
                                                    overflow: 'visible',
                                                }}
                                            >
                                                <CustomTooltip
                                                    title={`You are at Day ${formatNumber(dailySteakValue)} 💎`}
                                                    open
                                                    placement="bottom"
                                                >
                                                    <ArrowDropDownIcon sx={{ color: dailySteakValue === 0 ? '#FFFFFF33' : '#59e562' }} />
                                                </CustomTooltip>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Box xs={12} sx={{ backgroundImage: `url(${BGLogo})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', }}>
                            <Box pt={3.2}>
                                <Typography sx={{ fontWeight: '700', fontSize: '1.25rem', fontFamily: 'Metropolis', color: '#fff', textAlign: 'center' }}>Spotlight Brands</Typography>
                                <Typography px={6} sx={{ fontWeight: '400', fontSize: '0.875rem', fontFamily: 'Metropolis', color: '#fff', textAlign: 'center' }}>Get Extra 50 💎 on purchases from these brands.</Typography>
                            </Box>
                            <Grid container pt={4} px={2}>
                                <Grid item xs={4} md={4} mt={-0.75}>
                                    <a href="cashkaro://stores/cadbury-gift-coupons">
                                        <Box display="flex" justifyContent="center" alignItems="center" position="relative">
                                            <img loading="lazy" src={logo18} alt="logo18" style={{ width: '100%' }} />
                                            <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    fontFamily: 'Metropolis',
                                                    fontWeight: '600',
                                                    color: "#fff",
                                                    position: 'absolute',
                                                    bottom: '20%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, 70%)',
                                                    textAlign: 'center', // Optional: Center text horizontally
                                                    width: '100%'
                                                }}
                                            >
                                                40% Cashback
                                            </Typography>
                                        </Box></a>
                                </Grid>
                                <Grid item xs={4} md={4} >
                                    <a href="cashkaro://stores/puer-coupon-codes">
                                        <Box display="flex" justifyContent="center" alignItems="center" position="relative">
                                            <img loading="lazy" src={logo6} alt="logo6" style={{ width: '100%' }} />
                                            <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    fontFamily: 'Metropolis',
                                                    fontWeight: '600',
                                                    color: "#fff",
                                                    position: 'absolute',
                                                    bottom: '22%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, 70%)',
                                                    textAlign: 'center', // Optional: Center text horizontally
                                                    width: '100%'
                                                }}
                                            >
                                                35% Cashback
                                            </Typography>
                                        </Box></a>
                                </Grid>
                                <Grid item xs={4} md={4} >
                                    <a href="cashkaro://stores/koparoclean-coupons">
                                        <Box display="flex" justifyContent="center" alignItems="center" position="relative">
                                            <img loading="lazy" src={logo19} alt="logo19" style={{ width: '100%' }} />
                                            <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    fontFamily: 'Metropolis',
                                                    fontWeight: '600',
                                                    color: "#fff",
                                                    position: 'absolute',
                                                    bottom: '22%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, 70%)',
                                                    textAlign: 'center', // Optional: Center text horizontally
                                                    width: '100%'
                                                }}
                                            >
                                                35% Cashback
                                            </Typography>
                                        </Box></a>
                                </Grid>
                            </Grid>
                            <Grid container my={4} px={2}>
                                <Grid item xs={4} md={4} >
                                    <a href="cashkaro://stores/blue-tea-coupons">
                                        <Box display="flex" justifyContent="center" alignItems="center" position="relative">
                                            <img loading="lazy" src={logo20} alt="logo20" style={{ width: '100%' }} />
                                            <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    fontFamily: 'Metropolis',
                                                    fontWeight: '600',
                                                    color: "#fff",
                                                    position: 'absolute',
                                                    bottom: '22%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, 70%)',
                                                    textAlign: 'center', // Optional: Center text horizontally
                                                    width: '100%'
                                                }}
                                            >
                                                25% Cashback
                                            </Typography>
                                        </Box></a>
                                </Grid>
                                <Grid item xs={4} md={4} >
                                    <a href="cashkaro://stores/amala-earth-coupons">
                                        <Box display="flex" justifyContent="center" alignItems="center" position="relative">
                                            <img loading="lazy" src={logo21} alt="logo21" style={{ width: '100%' }} />
                                            <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    fontFamily: 'Metropolis',
                                                    fontWeight: '600',
                                                    color: "#fff",
                                                    position: 'absolute',
                                                    bottom: '22%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, 70%)',
                                                    textAlign: 'center', // Optional: Center text horizontally
                                                    width: '100%'
                                                }}
                                            >
                                                25% Cashback
                                            </Typography>
                                        </Box></a>
                                </Grid>
                                <Grid item xs={4} md={4} >
                                    <a href="cashkaro://stores/zanducare-coupons">
                                        <Box display="flex" justifyContent="center" alignItems="center" position="relative">
                                            <img loading="lazy" src={logo5} alt="logo22" style={{ width: '100%' }} />
                                            <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    fontFamily: 'Metropolis',
                                                    fontWeight: '600',
                                                    color: "#fff",
                                                    position: 'absolute',
                                                    bottom: '22%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, 70%)',
                                                    textAlign: 'center', // Optional: Center text horizontally
                                                    width: '100%'
                                                }}
                                            >
                                                20% Cashback
                                            </Typography>
                                        </Box></a>
                                </Grid>
                            </Grid>
                            <Grid container px={2}>
                                <Grid item xs={4} md={4} >
                                    <a href="cashkaro://stores/zoomin">
                                        <Box display="flex" justifyContent="center" alignItems="center" position="relative">
                                            <img loading="lazy" src={logo23} alt="logo23" style={{ width: '100%' }} />
                                            <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    fontFamily: 'Metropolis',
                                                    fontWeight: '600',
                                                    color: "#fff",
                                                    position: 'absolute',
                                                    bottom: '22%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, 70%)',
                                                    textAlign: 'center', // Optional: Center text horizontally
                                                    width: '100%'
                                                }}
                                            >
                                                25% Cashback
                                            </Typography>
                                        </Box></a>
                                </Grid>
                                <Grid item xs={4} md={4} >
                                    <a href="cashkaro://stores/asaya-coupon-codes">
                                        <Box display="flex" justifyContent="center" alignItems="center" position="relative">
                                            <img loading="lazy" src={logo7} alt="logo7" style={{ width: '100%' }} />
                                            <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    fontFamily: 'Metropolis',
                                                    fontWeight: '600',
                                                    color: "#fff",
                                                    position: 'absolute',
                                                    bottom: '22%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, 70%)',
                                                    textAlign: 'center', // Optional: Center text horizontally
                                                    width: '100%'
                                                }}
                                            >
                                                50% Cashback
                                            </Typography>
                                        </Box></a>
                                </Grid>
                                <Grid item xs={4} md={4} >
                                    <a href="cashkaro://stores/clove-oral-care-coupons">
                                        <Box display="flex" justifyContent="center" alignItems="center" position="relative">
                                            <img loading="lazy" src={logo1} alt="logo1" style={{ width: '100%' }} />
                                            <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    fontFamily: 'Metropolis',
                                                    fontWeight: '600',
                                                    color: "#fff",
                                                    position: 'absolute',
                                                    bottom: '22%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, 70%)',
                                                    textAlign: 'center', // Optional: Center text horizontally
                                                    width: '100%'
                                                }}
                                            >
                                                65% Cashback
                                            </Typography>
                                        </Box></a>
                                </Grid>
                            </Grid>
                        </Box>

                        <Grid item xs={12}>
                            <div style={{ position: 'relative' }}>
                                <img
                                    loading="lazy"
                                    src={Section6}
                                    alt="Section6"
                                    style={{ width: '100%', padding: 0, margin: 0 }}
                                />
                                <div style={{ position: 'absolute', top: '62%', left: '10%', width: '80%', paddingX: '1rem' }}>
                                    <ProgressBar
                                        className="wrapper"
                                        completed={currentValue}
                                        maxCompleted={10000000}
                                        bgColor={'linear-gradient(#1ef93b , #75db75)'}
                                        baseBgColor={`#FFFFFF33`}
                                        border={'1px solid #FFFFFF66'}
                                        height='18px'
                                        borderRadius={'3px'}
                                        customLabel={
                                            <div
                                                style={{
                                                    zIndex: '99999',
                                                    display: 'flex',
                                                    marginLeft: currentValue === 0 ? '0' : '-20px',
                                                    justifyContent: 'center',
                                                    position: 'absolute',
                                                    alignItems: 'center',
                                                    overflow: 'visible',
                                                }}
                                            >
                                                <CustomTooltip
                                                    title={`Community at ${formatNumber(currentValue)} 💎`}
                                                    open
                                                    placement="bottom"
                                                >
                                                    <ArrowDropDownIcon sx={{ color: currentValue === 0 ? '#FFFFFF33' : '#59e562' }} />
                                                </CustomTooltip>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </Grid>
                        <div style={{ marginTop: '-6rem', marginBottom: '3rem' }} ref={formReff}>
                            <Accordion sx={{ background: 'transparent', color: '#fff', padding: '0', borderBottom: 'none' }}>
                                <AccordionSummary sx={{ background: 'transparent', color: '#fff', fontSize: '1rem', fontWeight: '700', lineHeight: '1.4rem', borderBottom: 'none' }}
                                    expandIcon={<ExpandMoreIcon sx={{ background: 'transparent', color: '#fff', fontSize: '2rem', }} />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    💡 FAQs
                                </AccordionSummary>
                                <Grid item xs={12} mx={2}>
                                    <Divider sx={{ backgroundColor: '#333333', height: '2px', width: '100%', paddingX: '1rem' }} />
                                </Grid>
                                <AccordionDetails>
                                    <Grid item xs={12} py={2}>
                                        <Typography pb={1} sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#fff', fontFamily: 'Metropolis', lineHeight: '1.225rem' }}>What is the Daily Bonanza?</Typography>
                                        <Typography sx={{ fontWeight: '400', fontSize: '0.875rem', color: '#C0C2C1', fontFamily: 'Metropolis', lineHeight: '1.4rem' }}>The Daily Bonanza is an event by CashKaro to reward users with vouchers for engaging with the app daily.</Typography>
                                    </Grid>
                                    <Grid item xs={12} py={2}>
                                        <Typography pb={1} sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#fff', fontFamily: 'Metropolis', lineHeight: '1.225rem' }}>How do I participate in the campaign?</Typography>
                                        <Typography sx={{ fontWeight: '400', fontSize: '0.875rem', color: '#C0C2C1', fontFamily: 'Metropolis', lineHeight: '1.4rem' }}>To participate, register on the dedicated landing page by entering your mobile number.</Typography>
                                    </Grid>
                                    <Grid item xs={12} py={2}>
                                        <Typography pb={1} sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#fff', fontFamily: 'Metropolis', lineHeight: '1.225rem' }}>What activities earn me points?</Typography>
                                        <Typography sx={{ fontWeight: '400', fontSize: '0.875rem', color: '#C0C2C1', fontFamily: 'Metropolis', lineHeight: '1.4rem' }}>You can earn points by launching the app, visiting store pages, making transactions, initiating referrals, withdrawing funds, and visiting the My Earnings page.</Typography>
                                    </Grid>
                                    <Grid item xs={12} py={2}>
                                        <Typography pb={1} sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#fff', fontFamily: 'Metropolis', lineHeight: '1.225rem' }}>How are the points calculated for each activity?</Typography>
                                        <Typography sx={{ fontWeight: '400', fontSize: '0.875rem', color: '#C0C2C1', fontFamily: 'Metropolis', lineHeight: '1.4rem' }}>Points per activity:
                                        <ul>
                                                    <li>App launch: 1 point</li>
                                                    <li>Store visit: 2 points</li>
                                                    <li>Visit a brand's website: 5 points</li>
                                                    <li>Purchase: 120 points</li>
                                                    <li>Refer a friend: 2 points</li>
                                                    <li>Withdraw cashback: 5 points</li>
                                                    <li>Visit earnings page: 1 point</li>
                                                </ul>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} py={2}>
                                        <Typography pb={1} sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#fff', fontFamily: 'Metropolis', lineHeight: '1.225rem' }}>What are the milestone rewards?</Typography>
                                        <Typography sx={{ fontWeight: '400', fontSize: '0.875rem', color: '#C0C2C1', fontFamily: 'Metropolis', lineHeight: '1.4rem' }}>
                                            <ul>
                                                <li>Milestone 1: Amazon voucher worth INR 75</li>
                                                <li>Milestone 2: Amazon voucher worth INR 125</li>
                                                <li>Milestone 3: Amazon voucher worth INR 300</li>
                                            </ul>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} py={2}>
                                        <Typography pb={1} sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#fff', fontFamily: 'Metropolis', lineHeight: '1.225rem' }}>How are streak bonuses awarded?</Typography>
                                        <Typography sx={{ fontWeight: '400', fontSize: '0.875rem', color: '#C0C2C1', fontFamily: 'Metropolis', lineHeight: '1.4rem' }}>
                                            Streak bonuses are awarded based on consecutive days of app launches:
                                            <ul>
                                                <li>7-day streak: 30 points</li>
                                                <li>14-day streak: 50 points</li>
                                                <li>30-day streak: 75 points</li>
                                            </ul>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} py={2}>
                                        <Typography pb={1} sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#fff', fontFamily: 'Metropolis', lineHeight: '1.225rem' }}>Are transactions from all retailers considered?</Typography>
                                        <Typography sx={{ fontWeight: '400', fontSize: '0.875rem', color: '#C0C2C1', fontFamily: 'Metropolis', lineHeight: '1.4rem' }}>No, transactions from Swiggy and Amazon Recharge are not considered.</Typography>
                                    </Grid>
                                    <Grid item xs={12} py={2}>
                                        <Typography pb={1} sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#fff', fontFamily: 'Metropolis', lineHeight: '1.225rem' }}>What if my purchase is cancelled?</Typography>
                                        <Typography sx={{ fontWeight: '400', fontSize: '0.875rem', color: '#C0C2C1', fontFamily: 'Metropolis', lineHeight: '1.4rem' }}>Points for cancelled purchases will be adjusted accordingly.</Typography>
                                    </Grid>
                                    <Grid item xs={12} py={2}>
                                        <Typography pb={1} sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#fff', fontFamily: 'Metropolis', lineHeight: '1.225rem' }}>When will the rewards be disbursed?</Typography>
                                        <Typography sx={{ fontWeight: '400', fontSize: '0.875rem', color: '#C0C2C1', fontFamily: 'Metropolis', lineHeight: '1.4rem' }}>Rewards will be disbursed at the end of the campaign, post confirmation of each event to be considered for points calculation</Typography>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion sx={{ background: 'transparent', color: '#fff', padding: '0', borderBottom: 'none' }}>
                                <AccordionSummary sx={{ background: 'transparent', color: '#fff', fontSize: '1rem', fontWeight: '700', lineHeight: '1.4rem' }}
                                    expandIcon={<ExpandMoreIcon sx={{ background: 'transparent', color: '#fff', fontSize: '2rem', }} />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    ✏️  T&C

                                    <Divider />
                                </AccordionSummary>
                                <Grid item xs={12} mx={2}>
                                    <Divider sx={{ backgroundColor: '#333333', height: '2px', width: '100%', paddingX: '1rem' }} />
                                </Grid>
                                <AccordionDetails>
                                    <Grid item xs={12} py={2}>
                                        <Typography pb={1} sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#fff', fontFamily: 'Metropolis', lineHeight: '1.225rem' }}>Eligibility</Typography>
                                        <Typography sx={{ fontWeight: '400', fontSize: '0.875rem', color: '#C0C2C1', fontFamily: 'Metropolis', lineHeight: '1.4rem' }}>The Daily Bonanza is open to all registered CashKaro users.</Typography>
                                    </Grid>
                                    <Grid item xs={12} py={2}>
                                        <Typography pb={1} sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#fff', fontFamily: 'Metropolis', lineHeight: '1.225rem' }}>Campaign Duration</Typography>
                                        <Typography sx={{ fontWeight: '400', fontSize: '0.875rem', color: '#C0C2C1', fontFamily: 'Metropolis', lineHeight: '1.4rem' }}>The campaign will run from 15th July 2024 to 15th August 2024. </Typography>
                                    </Grid>
                                    <Grid item xs={12} py={2}>
                                        <Typography pb={1} sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#fff', fontFamily: 'Metropolis', lineHeight: '1.225rem' }}>Participation</Typography>
                                        <Typography sx={{ fontWeight: '400', fontSize: '0.875rem', color: '#C0C2C1', fontFamily: 'Metropolis', lineHeight: '1.4rem' }}>Users must register via the dedicated landing page by entering their mobile number.
                                            <ol>
                                                <li>Only registered users can participate and earn rewards through the campaign.</li>
                                            </ol>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} py={2}>
                                        <Typography pb={1} sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#fff', fontFamily: 'Metropolis', lineHeight: '1.225rem' }}>Points System</Typography>
                                        <Typography sx={{ fontWeight: '400', fontSize: '0.875rem', color: '#C0C2C1', fontFamily: 'Metropolis', lineHeight: '1.4rem' }}>
                                            <ol>
                                                <li>Users can earn points by engaging in specific activities on the CashKaro app, such as app launches, store page visits, transactions, referrals, withdrawals, and visiting the My Earnings page.</li>
                                                <li>Each activity has a predefined point value and daily cap.</li>
                                                <li> Points for each activity:</li>

                                                <ul>
                                                    <li>App launch: 1 point</li>
                                                    <li>Store visit: 2 points</li>
                                                    <li>Visit a brand's website: 5 points</li>
                                                    <li>Purchase: 120 points</li>
                                                    <li>Refer a friend: 2 points</li>
                                                    <li>Withdraw cashback: 5 points</li>
                                                    <li>Visit earnings page: 1 point</li>
                                                </ul>
                                                <li>Bulk orders will not be considered for the point calculation</li>
                                                <li>Transactions from Swiggy and Amazon Recharge will not be considered for rewards</li>
                                                <li>Cancelled transactions will not be considered for points calculation and points earned from such transaction will be adjusted accordingly</li>
                                                <li>All the events considered for points calculation will be post registration only</li>
                                            </ol>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} py={2}>
                                        <Typography pb={1} sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#fff', fontFamily: 'Metropolis', lineHeight: '1.225rem' }}>Reward Disbursement</Typography>
                                        <Typography sx={{ fontWeight: '400', fontSize: '0.875rem', color: '#C0C2C1', fontFamily: 'Metropolis', lineHeight: '1.4rem' }}>
                                            <ol>
                                                <li>Rewards will be disbursed at the end of the campaign, post confirmation of each event to be considered for points calculation</li>
                                                <li>Only genuine transactions will be considered. Cancelled transactions will result in adjusted points.</li>
                                            </ol>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} py={2}>
                                        <Typography pb={1} sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#fff', fontFamily: 'Metropolis', lineHeight: '1.225rem' }}>General</Typography>
                                        <Typography sx={{ fontWeight: '400', fontSize: '0.875rem', color: '#C0C2C1', fontFamily: 'Metropolis', lineHeight: '1.4rem' }}>
                                            <ol>
                                                <li>CashKaro reserves the right to modify or terminate the campaign at any time without prior notice.</li>
                                                <li>By participating, users agree to abide by the terms and conditions of the campaign.</li>
                                            </ol>
                                        </Typography>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                    </Grid>
                </Grid>}
        </div>
    )
};
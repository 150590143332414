import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';
import { Grid, Stack } from '@mui/material';
import CreatePopup from './create';
import EditPopup from './edit';
import FilterPopup from './filter';
import Alert from '../../components/alert';
import Loader from '../../components/loader';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

export default function Index() {
  const navigate = useNavigate();
  // const [open, setOpen] = useState({});
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  // const [openDelete, setOpenDelete] = useState(false);
  const [alert, setAlert] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [type, setType] = useState('');
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    word: '',
  });
  const [onFilter, setOnFilters] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [data_id, setDataID] = useState(null);
  const [record, setRecord] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [message, setMessage] = useState('');
  const login_id = localStorage.getItem("ck_bki");
  const bundle = localStorage.getItem("ck_aat");

  const callApi = () => {
    setLoader(true);
    if (bundle != null) {
      // setAccessToken(bundle);
    } else {
      setAlert(true);
      setType('error');
      setMessage('No Token Found');
      navigate('/login', { replace: true });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${bundle}`
      },
    };

    fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/blacklist?word=${filters.word}&page=${page}&size=${size}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if ('error' in data) {
          setLoader(false)
          setAlert(true);
          setType('error');
          setMessage(data.message);
        } else {
          if (data.dataItems.length !== undefined) {
            setData(data.dataItems);
            setTotalUsers(data.totalItems);
            setTotalItems(data.totalItems % size ? (Math.floor(data.totalItems / size) + 1) : Math.floor(data.totalItems / size));
            setLoader(false);
          } else {
            setLoader(false)
            setAlert(true);
            setType('error');
            setMessage(data.message);
          }
        }
      });
  }
  useEffect(() => {
    callApi();
  }, [page, size]);// eslint-disable-line react-hooks/exhaustive-deps

  const openCreatePopup = () => {
    setOpenCreate(!openCreate);
  };
  const openEditPopup = (e, id) => {
    setDataID(id);
    setRecord(data.find(o => o.id === id));
    setOpenEdit(!openEdit);
  };

  if (onFilter === true) {
    callApi();
    setOnFilters(false);
  }

  const handlePagination = (e, p) => {
    setPage(p - 1);
  }
  const refreshTable = () => {
    callApi();
  };
  const handleDelete = (e, id) => {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${bundle}`
        },
    };
    fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/blacklist/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          refreshTable()
            // if (Object.prototype.hasOwnProperty.call(data, "success")) {
            //     toast.error('Deleted Sucessfully');
            //     const newArray = dataShow.filter((item) => (
            //         item._id !== id
            //     ));
            //     setdataShow(newArray);
            // } else {
            //     toast.error(data.message);
            //     if (data?.message === 'Please authenticate') {
            //         logout()
            //         navigate('/login', { replace: true });
            //     }
            // }
        });
  };
  return (
    <>
      <Accordion sx={{ border: '1px solid gray' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Filter</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FilterPopup setFilters={setFilters} onFilter={setOnFilters} />
        </AccordionDetails>
      </Accordion>

      <Stack direction="row" justifyContent="space-between" >
        <Typography variant="h5" sx={{ paddingY: '10px' }}>Blacklist</Typography>
        <Button variant="contained" startIcon={<AddIcon />}  sx={{ float: 'right', marginY: '10px', backgroundColor: '#1b5e20' }} onClick={openCreatePopup} > Add Keyword</Button>
      </Stack>
      {isLoading === true ? <Loader show={isLoading} /> :
        <>
          <Card sx={{ p: 2 }}>
            {(data.length > 0 && data !== undefined) ? data.map((item, i) => (
              <Chip
                key={i}
                label={item.word}
                onDelete={(e) => handleDelete(e, item.id)}
                deleteIcon={<DeleteIcon style={{ color: "red" }} />}
                variant="outlined"
                style={{ margin: "2px" }}
                size="medium"
              />
            )) : <Grid item xs={12} sm={12} md={12} p={2}>
              <Typography variant="body" gutterBottom>
                No data found
              </Typography>
            </Grid>}
          </Card>

          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4}><Typography sx={{ paddingTop: '20px', paddingRight: '10px', fontWeight: '600' }}>Total Keywords: {totalUsers}</Typography></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              {data.length > 0 ? <Pagination count={totalItems} page={page + 1} color="primary" sx={{ paddingY: '20px' }} onChange={(e, page) => handlePagination(e, page)} /> : ''}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormControl sx={{ minWidth: 100, marginTop: '15px', float: 'right' }} size="small">
                <InputLabel id="demo-simple-select-helper-label">Size Per Page</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={size}
                  label="Size Per Page"
                  onChange={(e) => setSize(e.target.value)}
                >
                  <MenuItem value="25">25</MenuItem>
                  <MenuItem value="50">50</MenuItem>
                  <MenuItem value="100">100</MenuItem>
                  <MenuItem value="500">500</MenuItem>
                  <MenuItem value="1000">1000</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid> </>}
      <CreatePopup openPopup={openCreate} setOpenPopup={openCreatePopup} setMessage={setMessage} setAlert={setAlert} setType={setType} admin_id={login_id} refreshTable={refreshTable} />
      <EditPopup openPopup={openEdit} setOpenPopup={openEditPopup} setMessage={setMessage} setAlert={setAlert} setType={setType} admin_id={login_id} refreshTable={refreshTable} data_id={data_id} record={record} />
      <Alert open={alert} type={type} message={message} setOpen={setAlert} />
    </>
  );
}

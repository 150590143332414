import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { acceleratedBrands, couponBrands } from "../acceleratedCashbackData";
import { Squircle } from "corner-smoothing";

const Toast = ({ message, show }) => {
    return (
      <div className={`toast ${show ? "show" : ""}`}>
        {message}
      </div>
    );
  };

export default function CouponBrands(props) {

    const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

    const copyToClipboard = (code) => {
        navigator.clipboard.writeText(code);
        setToastMessage(`Coupon code ${code} copied to clipboard!`);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 2000);
      };

  return (
    <Grid container>
      <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        lg={4}
        sx={{
          position: "relative",
          backgroundColor: "#fff",
          borderTopLeftRadius: "1rem",
          borderTopRightRadius: "1rem",
          backgroundColor: "#222335",
        }}
      >
        <Box sx={{ width: "100%", margin: "0 !important",height:'70vh',overflow:'auto' }}>
         
          <Grid
            mx={2}
            pb={1}
            pt={1}
            sx={{ width: "100%", margin: "0 !important" }}
          >
            <Box id='header' sx={{ width: '100%', textAlign: 'center', paddingY: '0.5rem', display: 'flex', justifyContent: 'center', backgroundColor: '#222335', position: 'sticky', top: 0, zIndex: 1 }}>
                            <Box sx={{ marginBottom: '0.5rem' }}>
                            <Box  onClick={props.closefunc} sx={{ display: 'flex', justifyContent: 'center',marginBottom:'8px' }}>
                        <Box sx={{ width: '54px', borderTop: '5px solid #D9D9D9', borderRadius: '30px' }}></Box>
                    </Box>
                                <Typography sx={{ fontSize: '16px', fontWeight: 700, lineHeight: '24px', color: '#fff' }}>VIP Coupon Codes</Typography>
                            </Box>
                        </Box>
            <Box sx={{ width: "100%" }}>
              <Divider fullWidth />
            </Box>
            <Box sx={{ width: "100%", backgroundColor: "#030418",overflowX:'hidden' }}>
      <Box sx={{display:'flex',justifyContent:'center'}}>
      <Toast message={toastMessage} show={showToast} />
      </Box>

              <Box p={3} sx={{ backgroundColor: "#030418",overflowX:'hidden' }}>
                {couponBrands.map((r, i) => (
                 <Squircle  topLeftCornerRadius={16}
                 topRightCornerRadius={16} key={i} style={{borderTop:'2px solid #FFEBC9B2',borderRadius:'16px',
background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.09) -10.98%, rgba(153, 153, 153, 0) 93.94%)',
padding:'11px 0 11px 0',

                 
                 }}>
                     <Box px={2} pb={2} sx={{ display: "flex",alignItems:'center',borderRadius:'16px',border: '1px solid',
                  borderTop:'1px solid white !important',
                  borderImage:'linear-gradient(#FFEBC9B2,rgba(0,0,0,0)) 1 100%',
                  


                   }} key={i}>
                    <img
                      src={require(`../images/increasedCashback/${r.image}.png`)}
                      style={{ height: "44px",marginRight:'8px' }}
                    />
                    <Box>
                    <Typography sx={{color:'#fff',fontSize:'16px',fontWeight:600}}>{r?.name}</Typography>
                    <Typography sx={{color:'#9C9DA5',fontSize:'12px',fontWeight:500,width:'130px'}}>{r?.offer}</Typography>
                        
                    </Box>
                    <img src={require('../images/dotted-hr.png')} style={{height:'74px',marginLeft:'18px'}} />
                    <Box onClick={()=>copyToClipboard(r?.coupon_code)} sx={{marginLeft:'18px'}}>
                    <Typography sx={{color:'#fff',fontSize:'14px',fontWeight:600}}>{r?.coupon_code}</Typography>
                    <Typography sx={{color:'#9C9DA5',fontSize:'8px',fontWeight:600,width:'130px'}}>TAP TO COPY</Typography>


                    </Box>
                  </Box>
                 </Squircle>
                ))}
              </Box>
            </Box>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
    </Grid>
  );
}

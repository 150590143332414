import * as React from 'react';
import ShortnerSchema from '../validations/shortner';
import { useFormik, Form, FormikProvider } from 'formik';
import { useState, useEffect, } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Alert from './../components/alert'
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { TextField, Typography, Button } from '@mui/material';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import Loader from '../components/loader';
import ConnecterImg from '../images/connecter.png';
import LoaderImg from '../components/loader5.svg';
import { textTransform } from '@mui/system';

export default function Shortner() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [alert, setAlert] = useState(false);
    const [type, setType] = useState('');
    const [short_url, setShortUrl] = useState(null);
    const user_id = localStorage.getItem("ctui") ? localStorage.getItem("ctui") : '';
    const [isLoading, setLoader] = useState(true);
    const [isSuccess, setSuccess] = useState(false);
    const [isWrongMobile, setWrongMobile] = useState(false);
    const [formLoading, setFormLoader] = useState(false);
    const [message, setMessage] = useState('');
    const [counter, setCounter] = useState(5);
    const [user_name, setUserName] = useState('');
    const [user_mobile, setUserMobile] = useState('');

    const CssTextField = styled(TextField)({
        textAlign: 'center',
        '& label.Mui-focused': {
            color: '#707070'
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#707070',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#707070',
            },
            '& label.Mui-focused': {
                color: 'white',
                borderColor: "white"
            },
            '&:hover fieldset': {
                borderColor: '#707070',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#707070',
            },
        },
    });
    const outlineColor = {
        "& > fieldset": { borderColor: "#707070" },
        '& label.Mui-focused': {
            color: 'white',
            borderColor: "white"
        },
        '&:hover fieldset': {
            borderColor: '#707070',
        },
    }

    const callApi = (short_url) => {
        setLoader(true)
        let initialValues = {
            mobile: '',
            url: short_url,
            user_id: user_id,
        };
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(initialValues),
        };

        fetch(`${process.env.REACT_APP_SITE_URL}/api/link/redirect-request`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data?.success) {
                    localStorage.setItem('ctui', data.data.user_id)
                    window.location.href = data.data.new_link;
                } else {
                    navigate('/', { replace: true });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }
    useEffect(() => {
        let path_name = window.location.pathname;
        if (path_name) {
            let url = path_name.replace('/', '');
            setShortUrl(url)
            if (user_id) {
                callApi(url)
            } else {
                setLoader(false);
            }
        } else {
            navigate('/', { replace: true });
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const formik = useFormik({
        initialValues: {
            mobile: '',
            url: '',
            user_id: '',
        },
        validationSchema: ShortnerSchema,
        onSubmit: (initialValues, {setErrors}) => {
            setFormLoader(true)
            initialValues.url = short_url
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(initialValues),
            };
           
            fetch(`${process.env.REACT_APP_SITE_URL}/api/link/redirect-request`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    setFormLoader(false)
                    setUserMobile(initialValues.mobile)
                    if(data?.success){
                        setUserName(data.data.user_name)
                        setSuccess(true)
                        localStorage.setItem('ctui', data.data.user_id)
                        var c = counter
                        setInterval(() => {
                            if(c > 0){
                                setCounter(counter=>counter-1);
                                c = c-1
                            }  else {
                                setCounter(0)
                            }
                        }, 1000);
                        setTimeout(() => {
                            window.location.href = data.data.new_link;
                        }, 5000);
                        
                    } else {

                        if(data.message.includes('not registered on CashKaro')){
                            setWrongMobile(true)
                            callFunnel('visited_wrong_number_page', '', initialValues.mobile)
                        }
                        // setAlert(true);
                        // setType('error');
                        // setMessage(data.message);
                        // setTimeout(() => {
                        //     navigate('/', { replace: true });
                        //   }, 1000);
                        
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

        }
    });
    const { errors, touched, handleSubmit, getFieldProps } = formik;

    const openCashkaro = () => {
        setUserMobile('')
        callFunnel('click_on_signup_from_main_page', '', '')
        window.open("https://cashkaro.com/login?r=483660", "_blank")
    }
    const onChangeMobile = () => {
        callFunnel('click_on_change_mobile_number', '', user_mobile)
        setWrongMobile(false);
        setUserMobile('');
    }
    const openCashkaro2 = () => {
        callFunnel('click_on_signup_from_wrong_mobile_page', '', user_mobile)
        setUserMobile('')
        setWrongMobile(false);
        window.open("https://cashkaro.com/login?r=483660", "_blank")
    }
    const callFunnel = (action, user_id, user_mobile) => {
        let funnelValues = {
            action: action,
            user_id: user_id,
            user_mobile: user_mobile,
        };
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(funnelValues),
        };

        fetch(`${process.env.REACT_APP_SITE_URL}/api/link/funnel`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
               console.log(data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    return (
        <>{isLoading === true ?
            <Box style={{ backgroundColor: '#FFFFF', minHeight: '100vh', maxHeight: '100vh', overflow: 'hidden', paddingTop:'15%' }}>
                <Loader show={isLoading} />
            </Box> :
            <Box style={{ backgroundColor: '#FFFFFF', minHeight: '100vh', maxHeight: '100vh', overflow: 'hidden' }}>
                <Grid container >
                    <Grid item xs={12} sm={3} md={4} lg={4}></Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} sx={{ textAlign: 'center' }}>
                        <div style={{ justifyContent: 'center', backgroundColor: '#2F3C97',  }}>
                            {/* <span style={{color:'#2F3C97', fontWeight:600}}>CashKaro</span><span style={{color:'#FFFFFF'}}>Telegram</span> */}
                            <img src="https://asset20.ckassets.com/wp/wp-content/uploads/sites/37/2020/04/5e8dbdd7383b9.png" alt="" style={{marginTop: '15px', marginBottom: '10px',}} />
                        </div>

                        {formLoading === true ? <img alt="loader" src={LoaderImg} /> :
                            <>{isSuccess === false ?
                                <>{isWrongMobile === false ? 
                                    <div style={{ justifyContent: 'center',margin:'20px' }}>
                                        <h3 style={{color:'#000000', fontWeight:600, marginBottom:'40px', marginTop:'20px'}}>Enter Your CashKaro account linked Phone number</h3>
                                        <FormikProvider value={formik} >
                                            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                                                <Grid container >
                                                    <Grid item xs={12} >
                                                        {/* <Typography variant="subtitle1" sx={{marginBottom:'10px'}}>Enter Your Cashkaro Mobile Number</Typography> */}
                                                        <Box sx={{ display: 'flex', alignItems: 'flex-end', marginTop: '5px' }}>
                                                            <CssTextField
                                                                value="+91"
                                                                readOnly
                                                                // size="small"
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    style: { color: '#000000', textAlignLast: 'center' },
                                                                }}
                                                                sx={{ width: '20%' }}
                                                                helperText=" "
                                                            />
                                                            <TextField
                                                                {...getFieldProps('mobile')}
                                                                type="number"
                                                                sx={{ width: '80%', marginLeft: '8px', color: '#FFF', "& .MuiOutlinedInput-root": outlineColor }}
                                                                InputProps={{ style: { color: "#000000", border: "white" }, autoComplete: 'off', }}
                                                                InputLabelProps={{
                                                                    style: { color: '#000000' },
                                                                }}
                                                                error={Boolean(touched.mobile && errors.mobile)}
                                                                helperText={(touched.mobile && errors.mobile) || ' '}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <Fab variant="extended" type="submit" sx={{ width: '100%', marginBottom:'0px',marginTop:'10px', fontSize:'25px', backgroundColor: '#F07431',  color: 'white', ':hover': { bgcolor: '#2F3C97', color: '#FFFFFF', } }} >Submit</Fab>
                                                        <p style={{marginTop:'0px', fontSize:'14px'}}>Only required once </p>
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <h3 style={{ fontWeight:600,  marginBottom:'0px',marginTop:'10px'}}>Don't have a CashKaro Account?</h3>
                                                        <h3 style={{ fontWeight:600, marginBottom:'10px', marginTop:'0px',}}>Signup in one minute</h3>
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <ol style={{textAlign:'left',marginBottom:'20px'}}>
                                                            <li>Create your account using the "SIGN UP" button opens a new tab.</li>
                                                            <li>Come back to this page</li>
                                                            <li>Enter phone number of your freshly created account above</li>
                                                        </ol>
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                         <h3 style={{ fontWeight:600,  marginBottom:'0px',marginTop:'10px'}}><a href="" onClick={openCashkaro}>SIGN UP</a></h3>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        </FormikProvider>
                                    </div> :
                                    <div style={{ justifyContent: 'center',margin:'20px' }}>
                                        <div >
                                            <h2 style={{color:'#000000', marginTop:'20px',marginBottom:'0px'}}>There is no CashKaro account for the number:</h2>
                                            <h2 style={{color:'#000000', fontWeight:600, marginBottom:'0px', marginTop:'0px'}}>"{user_mobile}"</h2>
                                            <Button variant="text" onClick={onChangeMobile} sx={{textTransform:'none', marginTop:'0px'}}><u>Change number</u></Button>
                                        </div>
                                        <Grid container >
                                            <Grid item xs={12} >
                                                <h3 style={{ fontWeight:600,  marginBottom:'0px',marginTop:'10px'}}>Don't have a CashKaro Account?</h3>
                                                <h3 style={{ fontWeight:600, marginBottom:'10px', marginTop:'0px',}}>Signup in one minute</h3>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <ol style={{textAlign:'left',marginBottom:'20px'}}>
                                                    <li>Create your account using the "SIGN UP" button opens a new tab.</li>
                                                    <li>Come back to this page</li>
                                                    <li>Enter phone number of your freshly created account above</li>
                                                </ol>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Fab variant="extended"  onClick={openCashkaro2} sx={{ width: '60%', marginBottom:'0px',marginTop:'10px', fontSize:'25px',backgroundColor: '#F07431', color: 'white', ':hover': { bgcolor: '#2F3C97', color: '#FFFFFF', } }} >Sign Up</Fab>
                                            </Grid>
                                        </Grid>
                                    </div>}</>
                                :
                                <div style={{ justifyContent: 'center',margin:'20px' }}>
                                    <div style={{ justifyContent: 'center',  }}>
                                        <img src={ConnecterImg} alt="" style={{marginTop: '15px',  width:'60%'}} />
                                        <p style={{margin: '0px',  }}>Successfully linked</p> 
                                    </div>
                                    <Grid container >
                                        <Grid item xs={12} sx={{marginTop:'20px'}}>
                                            <h1>{user_name ? `Hi ${user_name}`: ''}</h1> 
                                        </Grid>
                                        <Grid item xs={12} sx={{marginTop:'20px'}}>
                                            <p style={{fontSize:'20px', textAlign:'center', marginBottom:'0px'}}>Redirecting you to the deal, your cashback will track in CashKaro account linked to "{user_mobile}"</p>
                                        </Grid>
                                        {/* <Grid item xs={12} sx={{paddingX:'35%', marginTop:'20px'}}>
                                            <div style={{backgroundColor:'#2F3C97',borderRadius:'50%', height:'80px', weight:'80px',  paddingTop:'20px' }}>
                                                <p style={{alignItems:'center', fontSize:'1.3rem', color:'#FFFFFF'}}>{counter} sec</p>
                                            </div> 
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p>for redirection</p>
                                        </Grid> */}
                                        <Grid item xs={12} sx={{marginTop:'40px'}}>
                                                <p style={{ fontSize:'1.3rem'}}>Redirecting you in <span style={{color:'#F07431'}}>{counter}</span></p>
                                            {/* <p>for redirection</p> */}
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                            </>
                        }
                    </Grid>
                </Grid>
                <div style={{ color: '#FFFFFF', display: 'flex', marginBottom: '10px', justifyContent: 'center' }}>
                    <p >Copyright © 2023 CashKaro. All Rights Reserved</p>
                </div>
                <Alert open={alert} type={type} setOpen={setAlert} message={message} />
            </Box>
        }</>

    );
}

export const retailersGroup1 = [
  {
      name: "Dot and Key",
      strikethrough_cashback: "15%",
      new_cashback: "18%",
      greater_savings: "20%",
      image: "dot-and-key",
      url: "cashkaro://stores/dotandkey-coupons-fpp24"
    },
    {
      name: "Cadbury",
      strikethrough_cashback: "40%",
      new_cashback: "50%",
      greater_savings: "25%",
      image: "cadbury",
      url: "cashkaro://stores/cadbury-gift-coupons-fpp24"
    },
    {
      name: "Swiss Beauty",
      strikethrough_cashback: "15%",
      new_cashback: "20%",
      greater_savings: "33%",
      image: "Swiss Beauty",
      url: "cashkaro://stores/swiss-beauty-coupons-fpp24"
    },
]

export const retailersGroup2 = [
  {
      name: "Adidas",
      strikethrough_cashback: "10%",
      new_cashback: "12%",
      greater_savings: "20%",
      image: "adidas",
      url: "cashkaro://stores/adidas-fpp24"
    },
    {
      name: "The Man Company",
      strikethrough_cashback: "12%",
      new_cashback: "15%",
      greater_savings: "25%",
      image: "the-man-company",
      url: "cashkaro://stores/themancompany-coupons-fpp24"
    },
    {
      name: "Zandu Care",
      strikethrough_cashback: "20%",
      new_cashback: "25%",
      greater_savings: "25%",
      image: "zandu-care",
      url: "cashkaro://stores/zanducare-coupons-fpp24"
    },
]

export const retailersGroup3 = [
  {
      name: "Booking.com",
      strikethrough_cashback: "4%",
      new_cashback: "5%",
      greater_savings: "25%",
      image: "booking-com",
      url: "cashkaro://stores/booking-fpp24"
    },
    {
      name: "Renee Cosmetics",
      strikethrough_cashback: "15%",
      new_cashback: "20%",
      greater_savings: "33%",
      image: "Renee",
      url: "cashkaro://stores/renee-cosmetics-coupons-fpp24"
    },
    {
      name: "Fitspire",
      strikethrough_cashback: "20%",
      new_cashback: "27%",
      greater_savings: "35%",
      image: "fitspire",
      url: "cashkaro://stores/fitspire-coupons-fpp24"
    },
]

export const retailersGroup4 = [
  {
      name: "Smytten",
      strikethrough_cashback: "6%",
      new_cashback: "9%",
      greater_savings: "50%",
      image: "smytten",
      url: "cashkaro://stores/smytten-coupon-codes-fpp24"
    },
    {
      name: "Hyugalife",
      strikethrough_cashback: "12%",
      new_cashback: "15%",
      greater_savings: "25%",
      image: "Hyu",
      url: "cashkaro://stores/hyugalife-coupons-fpp24"
    },
    {
      name: "Times Prime",
      strikethrough_cashback: "₹250",
      new_cashback: "₹310",
      greater_savings: "24%",
      image: "times-prime",
      url: "cashkaro://stores/times-prime-coupons-fpp24"
    },
]

export const retailersGroup5 = [
  {
      name: "XYXX Crew",
      strikethrough_cashback: "25%",
      new_cashback: "30%",
      greater_savings: "20%",
      image: "xyxx-crew",
      url: "cashkaro://stores/xyxx-discount-code-fpp24"
    },
    {
      name: "SSBeauty",
      strikethrough_cashback: "20%",
      new_cashback: "25%",
      greater_savings: "25%",
      image: "ssbeauty",
      url: "cashkaro://stores/ssbeauty-coupons-fpp24"
    }, 
    {
      name: "Kama Ayurveda",
      strikethrough_cashback: "20%",
      new_cashback: "25%",
      greater_savings: "25%",
      image: "kama",
      url: "cashkaro://stores/kamaayurveda-coupons-fpp24"
    },

]

export const retailersGroup6 = [
  {
      name: "Hyphen",
      strikethrough_cashback: "10%",
      new_cashback: "13%",
      greater_savings: "30%",
      image: "Hyphen",
      url: "cashkaro://stores/hyphen-coupon-codes-fpp24"
    },
]
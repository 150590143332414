import React from 'react';
import Signup from './signup';
import Leaderboard from './index';

const LeaderboardOrSignup = () => {
  // Check if 'ck_id' is present in localStorage
  const ckId = localStorage.getItem('ck_id');

  // Conditionally render components based on the presence of 'ck_id'
  return ckId ? <Leaderboard /> : <Signup />;
};

export default LeaderboardOrSignup;

import React from 'react';
import { useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { Grid, Button } from '@mui/material';
import Moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
export default function Filter(props) {
    const date_picker = useRef();
    const { setFilters, onFilter} = props;
    const [values, setValues] = useState({
        action: '',
        user_id: '',
        user_mobile: '',
        start_at: '',
        end_at: '',
    });
    // const [btnLoad, setbtnLoad] = React.useState(false);
    const changeValue = (e, parm) => {
        setValues(prevState => ({...prevState, [parm]: e.target.value }))
    }
    const filterdata = () => {
        setFilters(values);
        onFilter(true);
    }
    const handleResetClick = () => {
        setValues({
            action: '',
            user_id: '',
            user_mobile: '',
            start_at: '',
            end_at: '',
        });
        setFilters({
            action: '',
            user_id: '',
            user_mobile: '',
            start_at: '',
            end_at: '',
        });
        onFilter(true);
       date_picker.current.ref.value='';
    }
    const handleApply = (event, picker) => {
        picker.element.val(
        picker.startDate.format('MM/DD/YYYY') +
            ' - ' +
            picker.endDate.format('MM/DD/YYYY')
        );
        let daterange = event.target.value.split(' - ');
        let startDate = new Moment(daterange[0]).format('yyyy-MM-DD');
        let endDate = new Moment(daterange[1]).format('yyyy-MM-DD');
        setValues(prevState => ({ ...prevState, 'start_at' : startDate }))
        setValues(prevState => ({ ...prevState, 'end_at' : endDate }))
    }
    const handleCancel = (event, picker) => {
        console.log(picker)
        picker.element.val('');
      };
    return (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        label="Enter Action"
                        autoFocus
                        size="small"
                        value={values.action}
                        onChange={(e) => changeValue(e, 'action')}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        label="Enter User ID"
                        autoFocus
                        size="small"
                        value={values.user_id}
                        onChange={(e) => changeValue(e, 'user_id')}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        label="Enter User Mobile"
                        autoFocus
                        size="small"
                        value={values.user_mobile}
                        onChange={(e) => changeValue(e, 'user_mobile')}
                    />
                </Grid>
                
               
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <DateRangePicker  initialSettings={{ autoUpdateInput: false,
                        locale: {
                            cancelLabel: 'Clear',
                        }, }} 
                        onApply={handleApply}
                        onCancel={handleCancel}
                        ref={date_picker} 
                    >
                        <input type="text"className="form-control col-4" placeholder="Date Range" defaultValue="" />
                    </DateRangePicker>
                </Grid>
                <Grid item xs={12} md={12} style={{ textAlign: 'right' }}>
                    <Button
                        variant="text"
                        sx={{color:'#1B5E28'}}
                        onClick={filterdata}
                    >
                        Search
                    </Button>
                    <Button
                        variant="text"
                        sx={{color:'#DB0011'}}
                        onClick={handleResetClick}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
    );
}

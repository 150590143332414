import * as Yup from 'yup';
// add validation to driver form
const phoneRegExp = /^[6-9]\d{9}$/;

const userFormSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').max(255),
    email: Yup.string().required('Email is required').email('Email must be an vaild email').max(255),
    mobile: Yup.string().required('Mobile Number is required.').matches(phoneRegExp, 'Mobile number is not valid'),
    ekaro_id: Yup.string().required('EK ID is required.'),
    telegram: Yup.string().required('Telegram is required.'),
    status: Yup.string().required('Status is required.'),
    approved: Yup.string().required('Approval is required.'),
})

export default userFormSchema;

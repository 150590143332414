import * as React from 'react';
import LoginFormSchema from '../validations/loginSchema';
import { useFormik, Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Alert from './../components/alert'
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { TextField, Typography } from '@mui/material';
import Fab from '@mui/material/Fab';
import CryptoJS from "crypto-js";


export default function Login() {
    const navigate = useNavigate();
    const [alert, setAlert] = useState(false);
    const [type, setType] = useState('');
    const [message, setMessage] = useState('');
        const formik = useFormik({
        initialValues: {
          email: '',
          password: '',
        },
        validationSchema: LoginFormSchema,
        onSubmit: (initialValues) => {
            console.log(initialValues);
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(initialValues),
          };
          console.log(requestOptions);
          fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/auth/login`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              console.log('user', data);
              if (Object.prototype.hasOwnProperty.call(data, "token")) {
                localStorage.setItem("ck_aat", data.token);
                localStorage.setItem("ck_bki", data.user.id);
                localStorage.setItem("ck_bkn", data.user.name);
                const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data.user), 'secret key 123').toString();
                localStorage.setItem("ck_bundle", ciphertext);
                setAlert(true);
                setType('success');
                setMessage('Login Successfully');
                navigate('/', { replace: true });
              } else {
                setAlert(true);
                setType('error');
                setMessage(data.message);
              }
            })
            .catch((error) => {
              console.error('Error:', error);
            });
    
        }
      });
    

    const { errors, touched, handleSubmit, getFieldProps } = formik;
    return (
        <Box style={{backgroundColor:'#2F3C97', minHeight:'100vh', maxHeight:'100vh', overflow: 'hidden'}}>
            <Grid container >
                <Grid item xs={12} sm={12} md={4} ></Grid>
                <Grid item xs={12} sm={12} md={4} sx={{marginTop:'130px', textAlign: 'center'}}>
                <div style={{  justifyContent:'center', fontSize:'50px', fontFamily:'BarlowLight'}}>
                  {/* <span style={{color:'#2F3C97', fontWeight:600}}>CashKaro</span><span style={{color:'#FFFFFF'}}>Telegram</span> */}
                  <img src="https://asset20.ckassets.com/wp/wp-content/uploads/sites/37/2020/04/5e8dbdd7383b9.png" alt=""/>
                </div>
                    <Card  sx={{ padding:'20px', margin:'10px'}}>
                        <CardContent>
                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <Typography variant="h5" sx={{fontWeight:'600'}}>Login to your account</Typography>
                                    <Typography variant="subtitle1" sx={{marginBottom:'10px'}}>Enter your username and password</Typography>
                                    <TextField
                                        fullWidth
                                        type="email"
                                        variant="outlined"
                                        label="Enter Email"
                                        {...getFieldProps('email')}
                                        autoFocus
                                        sx={{marginBottom:'10px'}}
                                        error={Boolean(touched.email && errors.email)}
                                        helperText={touched.email && errors.email}
                                    />
                                    <TextField
                                        fullWidth
                                        type="password"
                                        variant="outlined"
                                        label="Enter Password"
                                        {...getFieldProps('password')}
                                        sx={{marginBottom:'10px'}}
                                        error={Boolean(touched.password && errors.password)}
                                        helperText={touched.password && errors.password}
                                    />
                                    <Fab variant="extended" type="submit"  sx={{ width: '100%', bottom:'-20px',backgroundColor:'#F07431', textTransform:'capitalize', color:'white', ':hover': {bgcolor: '#07090A', color: '#FFFFFF', }}} >Submit</Fab>
                                </Form>
                            </FormikProvider>
                        </CardContent>

                    </Card>
                </Grid>
            </Grid>
            <div style={{ color:'#FFFFFF', display:'flex', marginBottom:'10px', justifyContent:'center'}}>
              <p>Copyright © 2023 CashKaro. All Rights Reserved</p>
            </div>
            <Alert open={alert} type={type} setOpen={setAlert} message={message} />
        </Box>
    );
}

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import StoreSchema from '../../validations/storeSchema';
import { useFormik, Form, FormikProvider } from 'formik';
import { Grid } from '@mui/material';
import { TextField, Box } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Link from '@mui/material/Link';
import Alert from '../../components/alert';
import AWS from 'aws-sdk';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';

window.Buffer = window.Buffer || require("buffer").Buffer;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#9e9e9e",
      color: "#000000",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


export default function Edit(props) {
    const navigate = useNavigate();

    const location = useLocation();

    const [apiErrors, setApiErrors] = useState([]);
    const bundle = localStorage.getItem("ck_aat");
    const login_id = localStorage.getItem("ck_bki");
    // const login_type = localStorage.getItem("bka");
    const [preview, setPreview] = useState();
    const [alert, setAlert] = useState(false);
    const [type, setType] = useState('');
    const [message, setMessage] = useState('');
    const [record] = useState(location.state ? location.state.record : '');

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if(record === null){
            navigate('/');
        }
        console.log("record",props)
      }, [record]); // eslint-disable-line react-hooks/exhaustive-deps
    

    const formik = useFormik({
        initialValues: {
            id: record.id,
            name: record?.name ? record.name : '',
            store_id: record?.store_id ? record.store_id : '',
            store_url: record?.store_url ? record.store_url : '',
            secure_tracker_key: record?.secure_tracker_key ? record.secure_tracker_key : '',
            header_text: record?.header_text ? record.header_text : '',
            footer_text: record?.footer_text ? record.footer_text : '',
            url_add: record?.url_add ? record.url_add : '',
            sub_id_keys: record?.sub_id_keys ? record.sub_id_keys : '',
            url_remove: record?.url_remove ? record.url_remove : '',
            status: record.status,
        },
        enableReinitialize: true,
        validationSchema: StoreSchema,
       
            onSubmit: async (initialValues, { resetForm }) => {
                    
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${bundle}`
                        },
                        body: JSON.stringify(initialValues)
                    };
                    fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/stores`, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if ('error' in data) {
                                resetForm();
                                setApiErrors(data.errors);
                            } else if('success' in data) {
                                setAlert(true);
                                setType('success');
                                setMessage(data.message);
                                setTimeout(
                                    function() {
                                        navigate('/stores');
                                    }
                                    .bind(this),
                                    1000
                                );
                            } else {
                                resetForm();
                                setAlert(true);
                                setType('error');
                                setMessage('Something went wrong');
                            }
                        });
                    

            }
        
    });

    const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;
 
    return (
        <>
        <Box >
            <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom:'20px'}}>
                <Link
                    underline="hover" 
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="#1B191F"
                    href="/"
                    key="1"
                    >
                    <HomeIcon sx={{ mr: 0.5 }}  /> Dashboard
                </Link>
                <Link underline="hover" color="#1B191F" href="/stores" key="2" >Stores</Link>
                <Link underline="none" color="gray"  key="3" >Edit</Link>
            </Breadcrumbs>
            <FormikProvider value={formik} >
                <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: '20px',border:'1px solid #1B191F', borderRadius:'5px' }}>
                        <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: '10px' }}>Edit Store</Typography>
                        <Grid container spacing={3} >
                            {Object.keys(apiErrors).length !== 0 && <Grid item xs={12} sm={12} md={12}>
                                <Box p={3} sx={{ background: '#DB0011', borderRadius: '8px', color:'#FFFFFF' }}>
                                    {
                                        Object.keys(apiErrors).map((item, i) => (
                                            <Typography key={i}>{apiErrors[item]}</Typography>
                                        ))
                                    }
                                </Box>
                            </Grid>}
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    variant="outlined"
                                    label="Store ID"
                                    {...getFieldProps('store_id')}
                                    size="small"
                                    error={Boolean(touched.store_id && errors.store_id)}
                                    helperText={touched.store_id && errors.store_id}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Name"
                                    {...getFieldProps('name')}
                                    size="small"
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                            </Grid>
                            
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Store URL"
                                    {...getFieldProps('store_url')}
                                    size="small"
                                    error={Boolean(touched.store_url && errors.store_url)}
                                    helperText={touched.store_url && errors.store_url}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Secure Tracker Key"
                                    {...getFieldProps('secure_tracker_key')}
                                    size="small"
                                    error={Boolean(touched.secure_tracker_key && errors.secure_tracker_key)}
                                    helperText={touched.secure_tracker_key && errors.secure_tracker_key ? touched.secure_tracker_key && errors.secure_tracker_key : <b>If tracking is https://secure.traqkarr.com. Add a key of redirection.</b>}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Url Add"
                                    {...getFieldProps('url_add')}
                                    size="small"
                                    error={Boolean(touched.url_add && errors.url_add)}
                                    helperText={touched.url_add && errors.url_add}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Url Remove"
                                    {...getFieldProps('url_remove')}
                                    size="small"
                                    error={Boolean(touched.url_remove && errors.url_remove)}
                                    helperText={touched.url_remove && errors.url_remove}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Sub ID Keys"
                                    {...getFieldProps('sub_id_keys')}
                                    size="small"
                                    error={Boolean(touched.sub_id_keys && errors.sub_id_keys)}
                                    helperText={touched.sub_id_keys && errors.sub_id_keys}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Header Text"
                                    {...getFieldProps('header_text')}
                                    size="small"
                                    multiline
                                    error={Boolean(touched.header_text && errors.header_text)}
                                    helperText={touched.header_text && errors.header_text}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Footer Text"
                                    {...getFieldProps('footer_text')}
                                    size="small"
                                    multiline
                                    error={Boolean(touched.footer_text && errors.footer_text)}
                                    helperText={touched.footer_text && errors.footer_text}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <FormGroup>
                                    <FormControlLabel control={<Switch checked={values.status} onChange={(event, checked) =>setFieldValue('status',checked ? true : false) } />} label="Status" />
                                </FormGroup>
                                {/* <SwitchButton  {...getFieldProps('status')} checked={values.status} onChange={(event, checked) =>setFieldValue('status',checked ? true : false) } /> */}
                            </Grid>
                            
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Button variant="contained" type="submit" color="secondary" sx={{ float:'right',textTransform: 'capitalize', width: '20%' }}>Update</Button>
                            </Grid>
                        </Grid>
                </Form>
            </FormikProvider>
        </Box>
        <Alert open={alert} type={type} message={message} setOpen={setAlert}/>
        </>
    )
}
import React, { useRef } from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  CircularProgress,
  Button,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function PassActivated() {
  const [loading, setLoading] = useState(false);
  const [cta,setCta] = useState(false)
  const navigate = useNavigate();

  useEffect(()=>{
    setTimeout(()=>{
      setCta(true)
    },2000)
  },[])

  const handleClick = () => {
    // Your onClick handler logic here
   
    // You can navigate to a different page or trigger any other action
    navigate("/vip-pass-active-user/active");
  };

  return (
    <Grid container sx={{ background: "#fff" }}>
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
      {loading ? (
        <Box sx={{ display: "block", margin: "0 auto", marginTop: "50%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ backgroundColor: '#030418',overflowY:'hidden' }}>
          {/* <Grid item xs={12}>
            <div className="video-container">
              <video width="100%" height="100%" loop={false} playsinline autoPlay muted id='myVideo'>
                <source src={require("./images/pass-ani.mp4")} type="video/mp4" />
              </video>
              <div className="video-cta" onClick={handleClick}></div>
            </div>
          </Grid> */}
          <img style={{width:'100%'}} src={require('./images/pass-activated.png')} />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={4} lg={4}>
      <Grid item xs={12} sm={12} md={4} lg={4} sx={{ position: 'fixed', zIndex: 1, bottom: 0, width: '100%' }}>
               
               <div style={{ backgroundColor: 'transparent', textAlign: 'center', padding: '20px' }} >
                 <Button
                 onClick={()=>navigate('/vip-pass-active-user/active')}
                 loading={loading}
                   sx={{
                     height:'48px',
                     width: '100%',
                     borderRadius: '0.5rem',
                     background: 'linear-gradient(302.14deg, #C68B4A 1.32%, #FFEBC9 91.59%)',
                     color: '#000', textAlign: 'center', fontSize: '14px', fontWeight: 700, paddingY: '0.9rem',
                     textTransform: 'capitalize',
                     "&.Mui-disabled": {
                       background: "#3F4052",
                       color: '#67687A'
                     }
                   }}
                 >
                   {loading ? "" : <Typography sx={{fontSize: '14px', fontWeight: 700}}>Access Now</Typography>}
                 </Button>
               </div>
             </Grid>
      </Grid>
    </Grid>
  );
}

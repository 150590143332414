import React, { useRef, useEffect } from 'react';
import { useState } from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, IconButton, Paper, Typography, SwipeableDrawer, TextField, Box, FormHelperText, CircularProgress } from '@mui/material';
import Topbanner from '../../images/main-banner.png';
import Slice1 from '../../images/matchkaro/Slices1.png';
import Slice3Update from '../../images/matchkaro/Slice3-updated.jpg';
import Slice2Update from '../../images/matchkaro/Slice2-updated.png';
import Slice3 from '../../images/matchkaro/Slices3.png';
import Slice4 from '../../images/matchkaro/Slices4.png';
import Slice5 from '../../images/matchkaro/Slices5.png';
import ButtonInput from '../../images/matchkaro/Button.gif';
import Ball from '../../images/matchkaro/Ball.png';
import Tooltip from '@mui/material/Tooltip';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CardLocked from '../../images/matchkaro/DealCardLocked-1.png';
import Cardopen from '../../images/matchkaro/DealCardOpen-1.png';
import Ajio from '../../images/matchkaro/cupon-card/Old-Users-Locked/Ajio.png';
import Biba from '../../images/matchkaro/cupon-card/Old-Users-Locked/Biba.png';
import Boa from '../../images/matchkaro/cupon-card/Old-Users-Locked/Boa.png';
import BombayShaving from '../../images/matchkaro/cupon-card/Old-Users-Locked/Bombay Shaving Company copy.png';
import Caprese from '../../images/matchkaro/cupon-card/Old-Users-Locked/Caprese.png';
import Clovia from '../../images/matchkaro/cupon-card/Old-Users-Locked/Clovia.png';
import Dabur from '../../images/matchkaro/cupon-card/Old-Users-Locked/Dabur.png';
import DotandKey from '../../images/matchkaro/cupon-card/Old-Users-Locked/Dot and Key.png';
import Fab from '../../images/matchkaro/cupon-card/Old-Users-Locked/Fab India.png';
import Forest from '../../images/matchkaro/cupon-card/Old-Users-Locked/Forest Essentials India.png';
import Foxtale from '../../images/matchkaro/cupon-card/Old-Users-Locked/Foxtale.png';
import Hyugalife from '../../images/matchkaro/cupon-card/Old-Users-Locked/Hyugalife.png';
import Mamaearth from '../../images/matchkaro/cupon-card/Old-Users-Locked/Mama Earth.png';
import Mcaffeine from '../../images/matchkaro/cupon-card/Old-Users-Locked/Mcaffeine.png';
import Plum from '../../images/matchkaro/cupon-card/Old-Users-Locked/Plum Goodness.png';
import Puma from '../../images/matchkaro/cupon-card/Old-Users-Locked/Puma.png';
import Derma from '../../images/matchkaro/cupon-card/Old-Users-Locked/The Derma Co 2.png';
import TimesPrime from '../../images/matchkaro/cupon-card/Old-Users-Locked/Times Prime.png';
import Wow from '../../images/matchkaro/cupon-card/Old-Users-Locked/WoW.png';
import XYXX from '../../images/matchkaro/cupon-card/Old-Users-Locked/XYXX Crew.png';
import Zandu from '../../images/matchkaro/cupon-card/Old-Users-Locked/Zandu.png';

import { useFormik, Form, FormikProvider } from 'formik';
import Radio from '@mui/material/Radio';
import CustomProgressBar from '../../components/CustomProgressBar';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import matchKaroSchema from '../../validations/matckarosignupSchema';
import zIndex from '@mui/material/styles/zIndex';
import { useTheme, useMediaQuery, } from '@mui/material';
import { Padding } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';


const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .MuiTooltip-tooltip`]: {
        backgroundColor: '#b40d1d',
        fontSize: '8px',
        padding: '5px',
        fontFamily: 'Metropolis'
    },
    [`& .MuiTooltip-arrow`]: {
        color: '#b40d1d',

    },
});

export default function Signup() {
    const handleBackClick = () => {
        // console.log('Back button clicked');
        // Add your navigation logic here if needed
    };
    // const [formData, setFormData] = useState({
    //     contact: '',
    // });
    const [alert, setAlert] = useState(false);

    const navigate = useNavigate();
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [fieldset, setfieldset] = useState(false)
    const CssTextField = {
        width: "100%",
        "& label.Mui-focused": {
            color: '#80CFDB'
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: '#80CFDB',
            borderRadius: '8px'
        },
        "& .MuiOutlinedInput-root": {
            color: '#fff',
            "& fieldset": {
                background: 'transparent'
            },
            "&:hover fieldset": {
                borderRadius: '8px'

            },
            "&.Mui-focused fieldset": {
                borderRadius: '8px'
            },
        },
        '& .MuiOutlinedInput-input': {
            color: '#fff',
        },
        '& .MuiFormHelperText-root': {
            fontWeight: 600
        }
    };

    const [btnOn, setBtnOn] = React.useState(true);
    const [checked, setChecked] = React.useState(true);
    const formRef = useRef(null)
    const formReff = useRef(null)
    const [type, setType] = useState('');
    const [message, setMessage] = useState('');
    const [progress, setProgress] = useState('0');
    const [signupSuccess, setSignupSuccess] = useState(true);
    const [loading, setLoading] = useState(false);
    const [encId, setEncId] = useState('');
    const [showContent, setShowContent] = useState(false);
    const [match_ck_id, setck_id] = useState({
        enc_id: '',
    });
    const [userData, setUserData] = useState({
        matchMobile: '',
        matchCkId: ''
    });


    const formik = useFormik({
        initialValues: {
            mobile: '',
        },
        validationSchema: matchKaroSchema,
        onSubmit: (initialValues, { setErrors }) => {
            // setLoading(false);
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", "x-api-key": "20d6948f41e306c87b5279690e5fa699" },
                body: JSON.stringify(initialValues),
            };
            fetch(`${process.env.REACT_APP_SITE_URL_MATCHKARO}/api/user/create`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // setLoading(true);
                    console.log(data)
                    if (data?.status === 1) {
                        localStorage.setItem("match_ck_id", data?.enc_id);
                        localStorage.setItem("match_mobile", initialValues?.mobile);
                        setSignupSuccess(true);
                        setEncId(data.enc_id);
                        const lastFiveDigits = values.mobile.slice(5, 10);
                        const url = `https://cashkaro.almonds.ai/?name=xxxxxx${lastFiveDigits}&uid=${data.enc_id}`;
                        window.location.href = url;
                    } else {
                        setAlert(true);
                        setType('error');
                        setMessage(data.message);
                        setErrors({ mobile: data?.message })
                        if (data?.message === "This mobile is not register on CashKaro.") {
                            window.location.href = "https://cashkaro.com/login"
                        }
                    }

                })
                .catch((error) => {
                    // .erconsoleror('Error:', error);
                    if (error?.message === "This contact is not register on CashKaro.") {
                        window.location.href = "https://cashkaro.com/login"
                    }
                });

        }
    });
    useEffect(() => {
        const fetchUserScore = async () => {
            try {

                const matchCkId = localStorage.getItem('match_ck_id');
                if (!matchCkId) {
                    throw new Error('No match_ck_id found in local storage.');
                }

                const url = `${process.env.REACT_APP_SITE_URL_MATCHKARO}/api/user/getscore`;
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': '20d6948f41e306c87b5279690e5fa699',
                    },
                    body: JSON.stringify({ enc_id: matchCkId }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setProgress(data.score); // Assuming the response contains a 'score' field
                    console.log(data)
                } else {
                    throw new Error('Failed to fetch score');
                }
            } catch (error) {
                console.error('Error fetching score:', error);
            }

        };
        const userData = localStorage.getItem("match_ck_id");
        const userMobile = localStorage.getItem("match_mobile");
        if (!userData || !userMobile) {
            console.log("No user data found in localStorage, redirecting to login.");
            setSignupSuccess(false);
        } else {
            setSignupSuccess(true);
            fetchUserScore(); // Fetch the score only if the user is logged in
        }
    }, []);

    useEffect(() => {
        console.log('signup')
        const matchMobile = localStorage.getItem("match_mobile");
        const matchCkId = localStorage.getItem("match_ck_id");
        if (matchMobile && matchCkId) {
            setUserData({
                matchMobile: matchMobile,
                matchCkId: matchCkId
            });
        }
    }, []);
    const isTouched = (touched) => {
        return Object.values(touched).some(Boolean);
    }
    const { values, errors, touched, handleSubmit, getFieldProps } = formik;
    return (
        <Grid container sx={{ background: '#fff' }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                {/* {loading && (
                <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(0, 0, 0, 0.5)' }}>
                    <CircularProgress color="inherit" />
                </Box>
            )} */}
                 <Grid item xs={12} sx={{background: '#218400',height:'100vh'}}>
                    <img loading="lazy"  src={Slice1} alt="Slice1" style={{ width: '100%',padding:'0',margin:'0' }} />
                </Grid>

                {/* <Grid container item xs={12} sm={12} md={12} lg={12} justifyContent="center" alignItems="center">
                    <img loading="lazy" src={Slice2Update} alt="Slice2Update" style={{ width: '100%', overflow: 'visible', }} />
                    <Grid item xs={12} md={12} lg={12} sx={{ position: 'absolute', zIndex: 1, marginTop: { xs: '116%', sm: '95%', md: '39rem', lg: '39rem' }, }}>
                        {signupSuccess ? (
                            <img loading="lazy" src={ButtonInput} alt="ButtonInput" style={{ width: '100%', maxHeight: '50px' }} onClick={() => {
                                
                                const lastFiveDigits = values.mobile.slice(5, 10);
                                const url = `https://cashkaro.almonds.ai/?name=xxxxxx${lastFiveDigits}&uid=${encId}`;
                                window.location.href = url;
                                
                            }} />
                        ) : (<FormikProvider value={formik}  >
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container sx={{ display: 'flex', alignItems: 'center', }}>
                                    <Grid item xs={8} sm={8} md={8} style={{ display: 'flex', alignItems: 'center', width: '100%', maxHeight: '10px' }}>
                                        <TextField
                                        type='tel'
                                            variant="outlined"
                                            placeholder="Enter your registered mobile number"
                                            sx={{
                                                width: '100%',
                                                "& .MuiOutlinedInput-root": {
                                                    "&:hover fieldset": {
                                                        borderColor: 'none',
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: 'none',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    fontWeight: 400,
                                                    color: '#fff',
                                                    fontFamily: 'Metropolis',
                                                    padding: '0.815rem',
                                                },
                                                '& .MuiFormHelperText-root': {
                                                    fontWeight: 600,
                                                },
                                            }}
                                            fullWidth
                                            InputProps={{ style: { color: '#fff', background: '#68c43d', borderTopLeftRadius: '2rem', borderBottomLeftRadius: '2rem', fontSize: '0.875rem', marginLeft: '0.685rem', fontWeight: '400' } }}
                                            {...formik.getFieldProps('mobile')}
                                        
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} sx={{ display: 'flex', maxHeight: '10px', alignItems: 'center', justifyContent: 'center', marginLeft: '-0.685rem', zIndex: '99999' }}>
                                        <button type="submit" style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }} >
                                            <img loading="lazy" src={ButtonInput} alt="ButtonInput" style={{ width: '100%', maxHeight: '60px' }} />
                                        </button>
                                    </Grid>
                                    <Grid item xs={12} mt={2.2} mx={2}>
                                        {formik.touched.mobile && formik.errors.mobile && (
                                            <Typography style={{ color: 'red', fontSize: '0.875rem', fontFamily: 'Metropolis', }}>
                                                {formik.errors.mobile}
                                            </Typography>
                                        )}
                                    </Grid>

                                </Grid>
                            </Form>
                            <Typography pt={3} mx={2} style={{ color: 'white', textAlign: 'center', fontWeight: '500', fontFamily: 'Metropolis', fontSize: '1rem', fontStyle: 'italic' }}>If your mobile number is not registered with CashKaro,<br/> Please sign up!</Typography>

                        </FormikProvider>
                        )}

                    </Grid>
                </Grid> */}

                {/* <Grid item xs={12} >
                    <img loading="lazy" src={Slice3Update} alt="Slice3Update" style={{ width: '100%', position: 'relative' }} />
                </Grid> */}
                {/* <Grid item xs={12} sm={12} md={4} px={4} sx={{ position: 'absolute', width: '100%', marginTop: { xs: '28%', sm: '29%', md: '9%', lg: '9%' }, }}>
                    <Typography sx={{ color: 'white', textAlign: 'center', fontWeight: '500', fontFamily: 'Metropolis', fontSize: '1rem', fontStyle: 'italic', marginBottom: '1rem' }}>You are 100 runs away<br /> from unlocking increased Cashback.</Typography>
                    <ProgressBar
                        completed={progress}
                        className="wrapper"
                        bgColor={'linear-gradient(#1ef93b , #75db75)'}
                        baseBgColor={`linear-gradient(to right, rgba(52, 62, 94, 0) 0, rgba(52, 62, 94, 0) 10px, #343e5e 10px, #343e5e 100%)`}
                        border={'2px solid #fff'}
                        height='18px'
                        customLabelStyles={{ padding: 0 }}
                        borderRadius={'25px'}
                        customLabel={
                            <div style={{
                                zIndex: '99999',
                                display: 'flex',
                                marginLeft: '10px',
                                justifyContent: 'flex-start',
                                alignItems: 'center', 
                                height: '100%', 
                                overflow: 'visible', 
                            }}>
                                <CustomTooltip title={`${progress} Runs`} arrow open sx={{ marginTop: '-10px !important', position: 'absolute', }}>
                                    <img loading="lazy" src={Ball} alt="Ball" style={{ width: '30px', position: 'absolute', }} />
                                </CustomTooltip>
                            </div>
                        }
                    />

                    <div style={{ position: 'absolute', right: 0, bottom: 0, color: 'white', fontSize: '0.625rem', paddingLeft: '0px', paddingRight: '2.5rem', paddingBottom: '4.1rem' }}>
                        100 Runs
                    </div>
                    <Grid item xs={12} pt={4} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '0.625rem', color: '#fff', fontStyle: 'italic', textAlign: 'center', fontFamily: 'Metropolis' }}>You are<br /> Here</Typography>
                        <Typography sx={{ fontSize: '0.625rem', color: '#fff', fontStyle: 'italic', textAlign: 'center', fontFamily: 'Metropolis' }}>Offers<br /> Unlocked</Typography>
                    </Grid>
                </Grid> */}
                {/* <Grid item xs={12} >
                    <img loading="lazy" src={Slice4} alt="Slice4" style={{ width: '100%', zIndex: '99999', display: 'flex', overflow: 'visible', height: '100%', }} />
                </Grid> */}
                {/* {progress < 100 ? (
                    <Grid container  px={2} sx={{ background: '#3db400', marginTop: { xs: '-56%', sm: '-80%', md: '-60%', lg: '-75%' } }}>
                        <Grid item xs={6} textAlign="center">
                            <img loading="lazy" src={Ajio} alt="Ajio" style={{ width: '90%', }} />
                        </Grid>
                        <Grid item xs={6} textAlign="center">
                            <img loading="lazy" src={Biba} alt="Biba" style={{ width: '90%', }} />
                        </Grid>
                        <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={Boa} alt="Boa" style={{ width: '90%', }} />
                        </Grid>
                        <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={BombayShaving} alt="BombayShaving" style={{ width: '90%', }} />
                        </Grid>
                    </Grid>

                ) : (
                    <Grid container px={2} sx={{ background: '#3db400', marginTop: '-12.5rem' }}>
                        <Grid item xs={6}>
                            <img loading="lazy" src={Cardopen} alt="Cardopen" style={{ width: '100%', }} />
                        </Grid>
                        <Grid item xs={6}>
                            <img loading="lazy" src={Cardopen} alt="CardOpen" style={{ width: '100%', }} />
                        </Grid>
                    </Grid>
                )} */}
                {/* <Grid container px={2} pt={1} sx={{ background: '#3db400', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                    {!showContent && (  
                        <Button
                            variant="contained"
                            sx={{ borderRadius: '25px', background: 'blue', fontSize: '1rem',fontFamily:'Metropolis' }}
                            endIcon={<ArrowRightIcon />}
                            onClick={() => setShowContent(true)}  
                        >
                            SEE ALL
                        </Button>
                    )}
                    {showContent && ( 
                        <Grid container sx={{ background: '#3db400', }}>
                        <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={Clovia} alt="Clovia" style={{ width: '90%', }} />
                        </Grid>
                        <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={Dabur} alt="Dabur" style={{ width: '90%', }} />
                        </Grid>
                        <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={DotandKey} alt="DotandKey" style={{ width: '90%', }} />
                        </Grid>
                        <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={Fab} alt="Fab" style={{ width: '90%', }} />
                        </Grid>
                        <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={Forest} alt="Forest" style={{ width: '90%', }} />
                        </Grid>
                        <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={Foxtale} alt="Foxtale" style={{ width: '90%', }} />
                        </Grid>
                            <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={Mcaffeine} alt="Mcaffeine" style={{ width: '90%', }} />
                        </Grid>
                        <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={Mamaearth} alt="Mamaearth" style={{ width: '90%', }} />
                        </Grid>
                        <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={Plum} alt="Plum" style={{ width: '90%', }} />
                        </Grid>
                        <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={Puma} alt="Plum" style={{ width: '90%', }} />
                        </Grid>
                        <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={Derma} alt="Derma" style={{ width: '90%', }} />
                        </Grid>
                        <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={TimesPrime} alt="TimesPrime" style={{ width: '90%', }} />
                        </Grid>
                        <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={Wow} alt="Wow" style={{ width: '90%', }} />
                        </Grid>
                        <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={XYXX} alt="XYXX" style={{ width: '90%', }} />
                        </Grid>
                        <Grid item xs={6} pt={2} textAlign="center">
                            <img loading="lazy" src={Zandu} alt="Zandu" style={{ width: '90%', }} />
                        </Grid>
                        </Grid>
                    )}
                </Grid> */}
                {/* <Grid container px={3} pt={4} sx={{ background: '#3db400', }}>
                    <div>
                        <Accordion sx={{
                            boxShadow: '0px 12px 3px -2px rgba(0,0,0,0.2)',
                            borderRadius: '15px !important',
                            '.MuiAccordion-root.Mui-expanded' :{
                                margin:"0px"
                            }
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{
                                    fontFamily: 'metropolis', fontSize: '1.1rem', fontWeight: '500',
                                }}>
                                Frequently Asked Questions
                            </AccordionSummary>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{
                                        fontFamily: 'metropolis', fontSize: '1rem', fontWeight: '500'
                                    }}>
                                    What is Power Play T20 Dhamaka on CashKaro?
                                </AccordionSummary>
                                <AccordionDetails sx={{
                                    fontFamily: 'metropolis', fontSize: '0.725rem', fontWeight: '500'
                                }}>
                                    It is a game event for CashKaro users where they can play a cricket game, score runs and win exciting rewards such as Amazon gift vouchers, increased Cashback and a chance to win an iPhone 15. The game will be available on CashKaro from 1st June 2024 to 30th June 2024.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{
                                        fontFamily: 'metropolis', fontSize: '1rem', fontWeight: '500'
                                    }}>
                                    How do I register and start the game?
                                </AccordionSummary>
                                <AccordionDetails sx={{
                                    fontFamily: 'metropolis', fontSize: '0.725rem', fontWeight: '500'
                                }}>
                                    Enter your registered CashKaro phone number to  start the game. Tap the screen as the ball is delivered to hit designated areas and score runs.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{
                                        fontFamily: 'metropolis', fontSize: '1rem', fontWeight: '500'
                                    }}>
                                    Are there any limits or rules for gameplay?
                                </AccordionSummary>
                                <AccordionDetails sx={{
                                    fontFamily: 'metropolis', fontSize: '0.725rem', fontWeight: '500'
                                }}>
                                    There are no limits on the number of matches you can play. The more you play, your chance to win an Amazon Voucher increases.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{
                                        fontFamily: 'metropolis', fontSize: '1rem', fontWeight: '500'
                                    }}>
                                    How can I win an iPhone 15?
                                </AccordionSummary>
                                <AccordionDetails sx={{
                                    fontFamily: 'metropolis', fontSize: '0.725rem', fontWeight: '500'
                                }}>
                                    You will stand a chance to win an iPhone by entering into the lucky draw. To qualify for the lucky draw, you must play the game at least once and place an order via CashKaro during the game duration.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{
                                        fontFamily: 'metropolis', fontSize: '1rem', fontWeight: '500'
                                    }}>
                                    What rewards can I win and how are they determined?
                                </AccordionSummary>
                                <AccordionDetails sx={{
                                    fontFamily: 'metropolis', fontSize: '0.725rem', fontWeight: '500'
                                }}>
                                    <ul>
                                        <li>Top 10 daily scorers per inning will receive a Rs.100 Amazon Gift Voucher each.
                                        </li>
                                        <li>Top 3 run scorers of the event, all innings combined, will receive a Rs.1000 Amazon Gift Voucher each.
                                        </li>
                                        <li>Players who place an order via CashKaro during this event will be eligible for a lucky draw and stand a chance to win an iPhone 15.
                                        </li>
                                        <li>Once you reach a cumulative score of 100 runs, some Extra Cashback stores will be unlocked exclusively for you. You can access them via the game page.
                                        </li>
                                    </ul>

                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{
                                        fontFamily: 'metropolis', fontSize: '1rem', fontWeight: '500'
                                    }}>
                                    How will I receive my Amazon voucher rewards and check my scores?
                                </AccordionSummary>
                                <AccordionDetails sx={{
                                    fontFamily: 'metropolis', fontSize: '0.725rem', fontWeight: '500'
                                }}>
                                    Rewards are sent via Email to your registered email address within 72 hours of winning. Scores can be checked on the in-game leaderboard.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{
                                        fontFamily: 'metropolis', fontSize: '1rem', fontWeight: '500'
                                    }}>
                                    Who should I contact for support or more information?</AccordionSummary>
                                <AccordionDetails sx={{
                                    fontFamily: 'metropolis', fontSize: '0.725rem', fontWeight: '500'
                                }}>
                                    For assistance, contact CashKaro customer support at contact@cashkaro.com</AccordionDetails>
                            </Accordion>
                        </Accordion>
                    </div>
                </Grid> */}

                {/* <Grid container px={3} pt={2} sx={{ background: '#3db400', }}>
                    <div>
                        <Accordion sx={{
                            boxShadow: '0px 12px 3px -2px rgba(0,0,0,0.2)',
                            borderRadius: '15px !important'
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{
                                    fontFamily: 'metropolis', fontSize: '1.1rem', fontWeight: '500'
                                }}>
                                Terms and conditions
                            </AccordionSummary>
                            <Accordion>
                                <AccordionDetails sx={{
                                    fontFamily: 'metropolis', fontSize: '0.825rem', fontWeight: '500'
                                }}>
                                    <ul>
                                        <li><b>Offer Validity:</b> 1st June 2024 –  30th June 2024</li>
                                        <li><b>How to enter the event:</b> Go to the ‘Power Play T20 Dhamaka’ section on the CashKaro app, enter your registered CashKaro number, and press the play button to start the game.
                                        </li>
                                        <li>Extra cashback stores will only be unlocked on successful completion of 100 runs milestone (cumulative of all innings played till now) and can be accessed through the game entry page.
                                        </li>
                                        <li>Top 10 daily scorers per inning will receive ₹100 Amazon Gift Voucher. This voucher will be delivered to the eligible players within 72 hours via Email to their registered email ID.
                                        </li>
                                        <li>At the end of the campaign (30th June 2024), the Top 3 run scorers of the event,all innings combined, will receive ₹1000 Amazon Gift Voucher each. This voucher will be delivered to the eligible players on 4th July 2024 via email to their registered email id.
                                        </li>
                                        <li>In the event of multiple players scoring the same number of runs, the player who scored first will be eligible for the voucher.
                                        </li>
                                        <li>All the users who have played the game at least once and completed at least one purchase via CashKaro during the period of the event will be eligible to enter a lucky draw to win an iPhone 15. There are a total of 5 unique participants who can get an iPhone 15.
                                        </li>
                                        <li>Lucky draw winners will be declared by 15th July 2024 through telephonic confirmation and electronic email to their registered email
                                        </li>
                                        <li>CashKaro has the right to modify, suspend, or end the campaign at its discretion.
                                        </li>
                                        <li>Participation is subject to CashKaro and retailer/ brand terms.
                                        </li>
                                        <li>Attempts to manipulate the campaign will lead to disqualification and potential further actions.
                                        </li>
                                        <li>The decision of CashKaro for disqualification will be final and irreversible.
                                        </li>
                                    </ul>

                                </AccordionDetails>
                            </Accordion>
                        </Accordion>
                    </div>
                </Grid> */}

                {/* <Grid item xs={12} >
                    <img loading="lazy" src={Slice5} alt="Slice5" style={{ width: '100%', }} />
                </Grid> */}

            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
            </Grid>
        </Grid>

    )
};
import React from "react";
import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { acceleratedBrands } from "../acceleratedCashbackData";

export default function AcceleratedBrands(props) {
    return (
        <Grid container sx={{overflow:'hidden'}}>
            <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ position: 'relative', backgroundColor: '#fff', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', backgroundColor: '#222335' }}>
                <Box sx={{ width: '100%', margin: '0 !important', height: '70vh', overflow: 'auto' }}>
                    
                    <Grid mx={2} pb={1} pt={1} sx={{ width: '100%', margin: '0 !important' }}>
                        <Box id='header' sx={{ width: '100%', textAlign: 'center', paddingY: '0.5rem', display: 'flex', justifyContent: 'center', backgroundColor: '#222335', position: 'sticky', top: 0, zIndex: 1 }}>
                            <Box sx={{ marginBottom: '0.5rem' }}>
                            <Box  onClick={props.closefunc} sx={{ display: 'flex', justifyContent: 'center',marginBottom:'8px' }}>
                        <Box sx={{ width: '54px', borderTop: '5px solid #D9D9D9', borderRadius: '30px' }}></Box>
                    </Box>
                                <Typography sx={{ fontSize: '16px', fontWeight: 700, lineHeight: '24px', color: '#fff' }}>Accelerated Cashback Retailers</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Divider fullWidth />
                        </Box>
                        <Box sx={{ width: '100%', backgroundColor: '#030418' }}>
                            <Box p={3} sx={{ backgroundColor: '#030418' }}>
                                {acceleratedBrands.map((r, i) =>
                                    <Box key={i} py={2} sx={{ display: 'flex', alignItems: 'center', gap: '8px', borderBottom: i === acceleratedBrands.length - 1 ? 'none' : '1px solid white' }}>
                                        <img src={require(`../images/increasedCashback/${r.image}.png`)} style={{ height: '44px' }} />
                                        <Typography sx={{ color: '#fff', fontSize: '16px', fontWeight: 600 }}>{r?.name}</Typography>
                                    </Box>)}
                            </Box>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
        </Grid>
    );
}

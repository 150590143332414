import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/loader';

export default function Home() {
  const navigate = useNavigate();
  const [isLoading, setLoader] = useState(false);
  const login_id = localStorage.getItem("ck_bki");
  const bundle = localStorage.getItem("ck_aat");

  useEffect(() => {
    
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading === true ? <Loader  show={isLoading}/> :
      <>
        <p>Dashboard</p>
      </>
    }

    </>
  );
}

import * as Yup from 'yup';
// add validation to driver form

const loginFormSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('must be an vaild email').max(255),
    password: Yup.string().required('Password is required.').min(8),
})

export default loginFormSchema;


import React, { useRef } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import BlacklistSchema from '../../validations/blacklistSchema';
import { useFormik, Form, FormikProvider } from 'formik';
import { Grid } from '@mui/material';
import { TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';


export default function Create(props) {
    const dialogBox = useRef()
    const { openPopup, setOpenPopup } = props;
    const bundle = localStorage.getItem("ck_aat");
    // const login_type = localStorage.getItem("bka");

    const formik = useFormik({
        initialValues: {
            word: '',
        },
        enableReinitialize: true,
        validationSchema: BlacklistSchema,

        onSubmit: (initialValues, { resetForm, setErrors }) => {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bundle}`
                },
                body: JSON.stringify(initialValues)
            };

            fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/blacklist`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if ('error' in data) {
                        console.log(data.message)
                        setErrors({word: data.message.toString()})
                    } else if('success' in data) {
                        resetForm();
                        setOpenPopup(false);
                        props.setAlert(true);
                        props.refreshTable();
                        props.setType('success');
                        props.setMessage(data.message);
                    } else {
                        resetForm();
                        setOpenPopup(false);
                        props.setAlert(true);
                        props.setType('error');
                        props.setMessage('Something went wrong');
                    }
                });

        }
    });

    const { errors, touched, handleSubmit, getFieldProps,  } = formik;
    return (
        <Dialog
            ref={dialogBox}
            open={openPopup}
            onClose={() => { setOpenPopup(false) }}
            maxWidth="lg"
        >
            <FormikProvider value={formik}  >
                <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: '10px' }}>
                    <DialogContent >
                        <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: '10px' }}>Add User</Typography>
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Enter Keyword"
                                    {...getFieldProps('word')}
                                    error={Boolean(touched.word && errors.word)}
                                    helperText={touched.word && errors.word}
                                />
                            </Grid>
                           
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" type="button"  color="primary" sx={{ float: 'right', textTransform: 'capitalize', marginRight: '10px' }} onClick={() => setOpenPopup(!openPopup) }>Cancel</Button>
                        <Button variant="contained" type="submit"  color="secondary" sx={{ float: 'right', textTransform: 'capitalize', marginRight: '10px' }}>Save</Button>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}
import React, { useRef } from 'react';
import { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, IconButton, Paper, Typography, SwipeableDrawer, TextField, Box, FormHelperText, Stack, Divider, Alert, AlertTitle } from '@mui/material';
// import Topbanner from '../../images/main-banner.png';
import Topbanner from '../../images/Bachat-Challenge/Main-banner.png';
import Step1 from '../../images/Bachat-Challenge/Step-1.jpg';
import Step2 from '../../images/Bachat-Challenge/Step-2.jpg';
import Step3 from '../../images/Bachat-Challenge/Step-3.jpg';
import Step4 from '../../images/Bachat-Challenge/Step-4.jpg';
import Step5 from '../../images/Bachat-Challenge/Step-5.jpg';
import Banner3 from '../../images/Bachat-Challenge/banner-3.png';
import Banner4 from '../../images/Bachat-Challenge/banner-4.jpg';
import Banner5 from '../../images/Bachat-Challenge/banner-5.png';
import Term from '../../images/Bachat-Challenge/term.png';
import CTA1 from '../../images/Bachat-Challenge/CTA1.jpg';
import CTA2 from '../../images/Bachat-Challenge/CTA2.png';
import bottomBanner from '../../images/Bachat-Challenge/bottom-footer.png';
import register from '../../images/Bachat-Challenge/register.png';
import Topfifty from '../../images/top-fifty.svg';
import Footer from '../../images/Bachat-Challenge/footer.jpg';
import Button from '@mui/material/Button';
import RemoveIcon from '@mui/icons-material/Remove';
import { useFormik, Form, FormikProvider } from 'formik';
import Radio from '@mui/material/Radio';
import { useNavigate } from 'react-router-dom';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxTickIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxIconn from '@mui/icons-material/CheckBox';
import AccordionActions from '@mui/material/AccordionActions';
import ExpandMoreIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import signupSchema from '../../validations/signupSchema';
import zIndex from '@mui/material/styles/zIndex';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));



const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    padding: '10px 15px 10px 15px',
    fontSize: '0.875rem',
    fontWeight: '600',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: '10px 0px 10px 0px',
}));




export default function Signup() {
    const handleBackClick = () => {
        // console.log('Back button clicked');
        // Add your navigation logic here if needed
    };
    const [formData, setFormData] = useState({
        contact: '',
        gender: '',
        consent: ''
    });
    const [alert, setAlert] = useState(false);

    const navigate = useNavigate();


    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [fieldset, setfieldset] = useState(false)
    const CssTextField = {
        width: "100%",
        "& label.Mui-focused": {
            color: '#80CFDB'
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: '#80CFDB',
            borderRadius: '8px'
        },
        "& .MuiOutlinedInput-root": {
            color: '#000000',
            "& fieldset": {
                borderColor: '#fff',
                border: '1px solid #80CFDB',
                borderRadius: '8px'
            },
            "&:hover fieldset": {
                borderColor: '#80CFDB',
                border: '1px solid #80CFDB',
                borderRadius: '8px'

            },
            "&.Mui-focused fieldset": {
                borderColor: '#80CFDB',
                border: '1px solid #80CFDB',
                borderRadius: '8px'
            },
        },
        '& .MuiOutlinedInput-input': {
            color: '#262626'
        },
        '& .MuiFormHelperText-root': {
            fontWeight: 600
        }
    };
    const [expanded, setExpanded] = useState('panel1');
    const handleAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const [btnOn, setBtnOn] = React.useState(true);
    const [checked, setChecked] = React.useState(true);
    const formRef = useRef(null)
    const formReff = useRef(null)
    const [type, setType] = useState('');
    const [message, setMessage] = useState('');


    const scrollToElement = () => {
        formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    const scrollToElementt = () => {
        formReff.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const handleChange = (event) => {
        setIsCheckboxChecked(event.target.checked);
    };
    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    };

    const formik = useFormik({
        initialValues: {
            contact: '',
            gender: '',
            consent: false
        },
        validationSchema: signupSchema,
        onSubmit: (initialValues, { setErrors }) => {
            // console.log(initialValues);
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(initialValues),
            };
            // fetch(`${process.env.REACT_APP_SITE_URL_LEADERBOARD}/api/user/create/`, requestOptions)
            fetch(`${process.env.REACT_APP_SITE_URL_BEAUTYSTAR}/api/user/create_bsc`, requestOptions)

                .then((response) => response.json())
                .then((data) => {
                    if (data?.status === 1) {
                        localStorage.setItem("bsc_id", data?.ck_id);
                        localStorage.setItem("bsc_contact", initialValues?.contact);
                        navigate('/beauty-star/index', { replace: true });
                    } else {
                        setAlert(true);
                        setType('error');
                        setMessage(data.message);
                        setErrors({ contact: data?.message })
                        if (data?.message === "This contact is not register on CashKaro.") {
                            window.location.href = "https://cashkaro.com/login"
                        }
                    }

                })
                .catch((error) => {
                    // .erconsoleror('Error:', error);
                    if (error?.message === "This contact is not register on CashKaro.") {
                        window.location.href = "https://cashkaro.com/login"
                    }
                });

        }
    });
    const isTouched = (touched) => {
        return Object.values(touched).some(Boolean);
    }
    const enableSubmit = formik.isValid && formik.dirty && Object.keys(formik.touched).length === Object.keys(formik.initialValues).length;
    // console.log(enableSubmit, formik.isValid , formik.dirty , Object.keys(formik.touched).length , Object.keys(formik.initialValues).length)
    const { values, errors, touched, handleSubmit, getFieldProps } = formik;
    return (
        <Grid container sx={{ background: '#fff' }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                {/* <Grid item xs={12} sx={{
                    display: 'flex', alignItems: 'center',
                    borderBottom: '1px solid rgba(105, 102, 102, 0.10)',
                    background: '#303B97',
                    
                    height: '3rem',
                    zIndex:'99',
                    position:'relative'
                   }}>
                    
                    <Typography ml={2.25} sx={{ fontWeight: '400', color: '#fff', fontSize: '0.875rem', fontFamily: 'Metropolis', }}>
                        Leaderboard
                    </Typography>
                </Grid> */}
                <Grid item xs={12} >
                    <img src={Topbanner} alt="Topbanner" style={{ width: '100%', }} />
                </Grid>
                <Grid container px={2.25} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <img src={Step1} alt="Earnpoints" style={{ width: '100%', }} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography sx={{ fontWeight: '800', color: '#262626', fontSize: '1.2rem', fontFamily: 'Metropolis', lineHeight: '1.5rem', }}>
                            1. Register
                        </Typography>
                        <Typography pt={1} sx={{ fontWeight: '600', color: '#5c5c5c', fontSize: '0.750rem', fontFamily: 'Metropolis', textAlign: 'Left' }}>
                            Fill out the form below to begin your journey.
                        </Typography>

                    </Grid>
                </Grid>
                <Grid container px={2.25} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography sx={{ fontWeight: '800', color: '#262626', fontSize: '1.2rem', fontFamily: 'Metropolis', lineHeight: '1.5rem' }}>
                            2. Shop From Featured Brands
                        </Typography>
                        <Typography pt={1} sx={{ fontWeight: '600', color: '#5c5c5c', fontSize: '0.750rem', fontFamily: 'Metropolis', textAlign: 'Left' }}>
                            Earn <b>100 points</b> with every purchase.</Typography>

                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <img src={Step2} alt="Shopdeal" style={{ width: '100%', }} />
                    </Grid>
                </Grid>
                <Grid container px={2.25} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <img src={Step3} alt="Earnpoints" style={{ width: '85%', }} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography sx={{ fontWeight: '800', color: '#262626', fontSize: '1.2rem', fontFamily: 'Metropolis', lineHeight: '1.5rem', }}>
                            3. Earn Cashback
                        </Typography>
                        <Typography pt={1} sx={{ fontWeight: '600', color: '#5c5c5c', fontSize: '0.750rem', fontFamily: 'Metropolis', textAlign: 'Left' }}>
                            Get up to <b>80% Cashback</b> on all your purchases.
                        </Typography>

                    </Grid>
                </Grid>
                <Grid container px={2.25} sx={{ display: 'flex', alignItems: 'center' }}my={5}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography sx={{ fontWeight: '800', color: '#262626', fontSize: '1.2rem', fontFamily: 'Metropolis', lineHeight: '1.5rem' }}>
                            4. Shop, Slay, Repeat!
                        </Typography>
                        <Typography pt={1} sx={{ fontWeight: '600', color: '#5c5c5c', fontSize: '0.750rem', fontFamily: 'Metropolis', textAlign: 'Left' }}>
                            Climb to the top of the beauty leaderboard. Read detailed T&C <span style={{ color: '#0A32AC' }}><a onClick={scrollToElementt}> here.</a></span></Typography>

                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <img src={Step4} alt="Shopdeal" style={{ width: '100%', }} />
                    </Grid>
                </Grid>
                <Grid container px={2.25} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <img src={Step5} alt="Earnpoints" style={{ width: '100%', }} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} mb={4}>
                        <Typography sx={{ fontWeight: '800', color: '#262626', fontSize: '1.2rem', fontFamily: 'Metropolis', lineHeight: '1.5rem', }}>
                            5. Win Big
                        </Typography>
                        <Typography pt={1} sx={{ fontWeight: '600', color: '#5c5c5c', fontSize: '0.750rem', fontFamily: 'Metropolis', textAlign: 'Left' }}>
                            Top 50 leaderboard stars will win Amazon Vouchers worth up to ₹<b>2000.</b></Typography>

                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <img src={Banner3} alt="Banner3" style={{ width: '100%', }} />
                </Grid>
                <Grid item xs={12} >
                    <img src={Banner4} alt="Banner4" style={{ width: '100%', }} />
                </Grid>
                <Grid item xs={12} >
                    <img src={Banner5} alt="Banner5" style={{ width: '100%', }} />
                </Grid>
                {/* <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={scrollToElement}>
                    <img src={CTA1} alt="CTA1" style={{ width: '70%' }} />
                </Grid> */}
                <Grid container>
                    <Grid item xs={12} mt={2} mx={2.25}>
                        <img src={register} alt="register" style={{ width: '100%', }} />
                    </Grid>

                    <Grid ref={formRef} item xs={12} sm={12} md={12} lg={12} mt={2} mx={2.25}>
                        <FormikProvider value={formik}  >
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container px={3} sx={{ background: '#F5F7F9', borderRadius: '1rem' }}>
                                    <Typography variant="h6" mt={3} sx={{ color: '#000000', fontSize: '0.75rem', fontWeight: '600', paddingBottom: '4px', fontFamily: 'Metropolis', }} >Enter your mobile number registered
                                        with Cashkaro</Typography>
                                    <Grid item xs={12} sm={12} md={12} mb={2}>
                                        <TextField
                                            variant="outlined"
                                            // {...getFieldProps('name')}
                                            placeholder="Enter mobile number"
                                            sx={{
                                                padding: '20px, 24px, 20px, 24px',
                                                width: '100%',
                                                "& .MuiOutlinedInput-root": {
                                                    "&:hover fieldset": {
                                                        borderColor: '#80CFDB',
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: '#80CFDB',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    fontWeight: 600,
                                                    color: '#262626',
                                                    fontFamily: 'Metropolis'
                                                },
                                                '& .MuiFormHelperText-root': {
                                                    fontWeight: 600
                                                }
                                            }}
                                            // value={capitalizeWords(values.name)}
                                            fullWidth
                                            InputProps={{ style: { color: '#000000', background: '#ffffff', border: "white", paddingLeft: '10px', padding: '6px', fontSize: '0.875rem', fontWeight: '500' } }}
                                            {...getFieldProps('contact')}
                                            error={Boolean(touched.contact && errors.contact)}
                                            helperText={touched.contact && errors.contact}
                                        // onClick={() => handleLabels('name')}
                                        // name="contact"
                                        // value={formData.contact}
                                        // onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} mb={2}>
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontWeight: '600', fontSize: '0.75rem', color: '#000', fontFamily: 'Metropolis' }}>Gender</FormLabel>
                                            <RadioGroup
                                                row
                                                {...formik.getFieldProps('gender')}
                                            >
                                                <FormControlLabel
                                                    value="M"
                                                    control={<Radio />}
                                                    label="Male"
                                                    sx={{
                                                        fontWeight: '500',
                                                        color: '#262626',
                                                        fontFamily: 'Metropolis',
                                                        fontSize: '0.75rem',
                                                        '& .MuiTypography-root': { // Target the nested Typography component
                                                            fontSize: '0.75rem !important',
                                                            fontFamily: 'Metropolis !important',
                                                            color: '#262626', // Apply !important to the Typography component's fontSize
                                                        }
                                                    }}
                                                />
                                                <FormControlLabel value="F" control={<Radio />} label="Female" sx={{
                                                    fontWeight: '500',
                                                    color: '#262626',
                                                    fontSize: '0.75rem',
                                                    fontFamily: 'Metropolis',
                                                    '& .MuiTypography-root': { // Target the nested Typography component
                                                        fontSize: '0.75rem !important',
                                                        fontFamily: 'Metropolis !important',
                                                        color: '#262626', // Apply !important to the Typography component's fontSize
                                                    }
                                                }} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} mb={4}>
                                        <FormControlLabel
                                            sx={{ display: 'flex', alignItems: 'flex-start' }}
                                            labelPlacement="end"
                                            label={
                                                <Typography sx={{ fontSize: '0.625rem', fontWeight: 600, color: '#262626', fontFamily: 'Metropolis' }}>
                                                    I consent to have my leaderboard ranking displayed publicly, understanding this is integral to contest participation
                                                </Typography>
                                            }
                                            control={<Checkbox icon={<CheckBoxIcon />} checkedIcon={<CheckBoxIconn sx={{ color: '#0036DA' }} />} onChange={handleChange} sx={{ paddingTop: '0' }}  {...formik.getFieldProps('consent')}
                                                checked={formik.values.consent} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: "center" }} mb={3}>
                                        {/* {isCheckboxChecked ? (
                                    <Button variant="contained" sx={{ color: '#fff', fontWeight: '600', fontSize: '1rem', fontFamily: 'Metropolis', background: '#FF6D1D', width: '85%', height: '3rem', width: '100%', textTransform: 'capitalize', borderRadius: '8px' }} onClick={handleSubmit} type="submit">Register Now</Button>
                                ) : (
                                    <Button variant="contained" sx={{ color: '#fff', fontWeight: '600', fontSize: '1rem', fontFamily: 'Metropolis', background: '#FF6D1D', width: '85%', height: '3rem', width: '100%', textTransform: 'capitalize', borderRadius: '8px' }} disabled>Register Now</Button>
                                )} */}
                                        {/* <Button variant="contained" sx={{ color: '#fff', fontWeight: '600', fontSize: '1rem', fontFamily: 'Metropolis', background: '#FF6D1D', width: '85%', height: '3rem', width: '100%', textTransform: 'capitalize', borderRadius: '8px' }} type="submit" disabled={!formik.isValid || !isTouched(formik.touched)}
                                            onClick={formik.handleSubmit}>Register Now</Button> */}
                                        {/* <Button variant="contained" sx={{ color: '#fff', fontWeight: '600', fontSize: '1rem', fontFamily: 'Metropolis', background: '#FF6D1D', width: '85%', height: '3rem', width: '100%', textTransform: 'capitalize', borderRadius: '8px' }} type="submit" disabled={!formik.isValid || !isTouched(formik.touched)}
                                            onClick={formik.handleSubmit}>Register Now</Button> */}
                                        <img src={CTA2} alt="CTA2" style={{ width: '80%', }} onClick={formik.handleSubmit} />
                                    </Grid>
                                </Grid>
                            </Form>
                        </FormikProvider>
                    </Grid>
                    <Grid ref={formReff} item xs={12} mt={4} mx={2.25}>
                        <Grid item xs={12} >
                            <img src={Term} alt="Term" style={{ width: '100%', }} />
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: '2rem', width: '100%', }}>

                        <Accordion expanded={expanded === 'panel1'} onChange={handleAccordion('panel1')}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={expanded === 'panel1' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Eligibilty</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}><ul><li>The contest is open only to registered users of CashKaro.</li></ul></Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleAccordion('panel2')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded === 'panel2' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Contest Period</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}><ul>
                                            <li>The contest will commence on 09th July 2024 and conclude on 31st July 2024.</li>
                                            <li>CashKaro reserves the right to extend, modify, or terminate the contest period at its sole discretion.</li>
                                        </ul></Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleAccordion('panel3')}>
                            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={expanded === 'panel3' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Participation</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}>
                                            <ul>
                                                <li>To participate, users must register for the contest through the dedicated registration page.</li>
                                                <li>Participation requires the phone number linked to the participant’s CashKaro account and consent to share ranking publicly.</li>
                                            </ul>

                                        </Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion><Accordion expanded={expanded === 'panel4'} onChange={handleAccordion('panel4')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded === 'panel4' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Contest Mechanics</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}><ul>
                                            <li>Points are awarded based on the number of transactions completed on the featured beauty & grooming brands.</li>
                                            <li>The leaderboard will be updated daily at 9:00 AM and will reflect rankings based on transactions from the previous day.</li>
                                            <li>Rankings are determined by the total points accumulated during the contest period.</li>
                                            <li>In the event that two or more participants achieve the same rank based on the points accumulated, the total order value of their transactions during the contest period will be used as a tie-breaker.</li>
                                            <li>Participants can access the leaderboard via the banners provided on the homepage to check their current standing and points total.</li>
                                        </ul></Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel5'} onChange={handleAccordion('panel5')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded === 'panel5' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Prizes</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}>
                                            <ul>
                                                <li>By 10th August 2024, leaderboard rankings will be finalized, and participants will be notified of their ranks. If cancellations cause a participant's points to drop below the thresholds for their current tier, their ranking and reward tier will be adjusted accordingly:
                                                    <ol>
                                                        <li><b>Top 5:</b> Each winner will receive an Amazon Voucher worth Rs. 2000</li>
                                                        <li><b>Positions 6-20:</b> Each winner will receive an Amazon Voucher worth Rs. 1500</li>
                                                        <li><b>Positions 21-50:</b> Each winner will receive an Amazon Voucher worth Rs. 750</li>
                                                    </ol>
                                                </li>
                                            </ul>
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel6'} onChange={handleAccordion('panel6')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded === 'panel6' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Prize Distribution</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}>
                                            <ul>
                                                <li>Winners will receive their gift cards within 10 days of their transactions getting validated and confirmed as legitimate and final. The gift cards will be shared with users on their registered CashKaro email ID.</li>
                                                <li>If any transaction contributing to a participant's points is cancelled for any reason, the points associated with that transaction will be deducted from the participant’s total. This will affect the participant’s ranking on the leaderboard.</li>
                                                <li>Rewards will be distributed only after all necessary validations are complete. CashKaro reserves the right to withhold rewards until all aspects of a participant's transactions are confirmed.</li>
                                            </ul>
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel7'} onChange={handleAccordion('panel7')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded === 'panel7' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Disqualification and Adjustment of Scores</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}>
                                            <ul>
                                                <li>In the event of any discrepancies, including but not limited to cancelled transactions or fraudulent activities, CashKaro reserves the right to adjust the scores and rankings of participants.</li>
                                                <li>While scores and ranks can decrease due to transaction cancellations, no participant will move upward in rank as a result of another participant’s score adjustment.</li>

                                            </ul>
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel8'} onChange={handleAccordion('panel8')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded === 'panel8' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Amendments to Terms</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}><ul><li>CashKaro reserves the right to amend these terms and conditions at any time.</li></ul></Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion><Accordion expanded={expanded === 'panel9'} onChange={handleAccordion('panel9')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded === 'panel9' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Acceptance of Terms</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}><ul><li>By entering the contest, participants agree to be bound by these terms and conditions.</li></ul></Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>

                    </div>
                </Grid>

                <Grid item xs={12} mt={3}>

                    <img src={bottomBanner} alt="bottomBanner" style={{ width: '100%',marginBottom:'80px' }} />

                </Grid>
                <Grid container sx={{ position: 'relative', background: 'transparent', }}>
                    <Grid item xs={12} sm={12} md={4} lg={4}
                        sx={{ position: 'fixed', bottom: 0, width: '100%' }}
                    >
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={scrollToElement}>
                            <img src={CTA1} alt="CTA1" style={{ width: '100%' }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
            </Grid>
        </Grid>

    )
};
import React, { useState, useEffect } from 'react';
import { Grid, Typography, Card, CardContent, Alert, backdropClasses } from '@mui/material';
import Loader from '../../components/loader';
import FilterPopup from './filter';
import { useNavigate } from 'react-router-dom';

export default function Index() {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [previousData, setPreviousData] = useState([]); // Store the previous data state
    const [filters, setFilters] = useState({});
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [error, setError] = useState('');
    const email = localStorage.getItem('user');
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
            navigate('/admin/login');
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (isFilterActive) {
            fetchData();
            setIsFilterActive(false);
        }
    }, [isFilterActive, filters]);

    const fetchData = async () => {
        setIsLoading(true);
        if (Object.keys(filters).length === 0) {
            setData([]);
            setIsLoading(false);
            return;
        }

        try {
            const response = await fetch(`https://leaderboard-1.earningshub.co/api/user/getleaderboard_crm_bsc?ck_id=${filters.ck_id}`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const jsonData = await response.json();
            if (jsonData.error) {
                throw new Error(jsonData.error);
            }
            setPreviousData(data); // Update previous data before setting new data
            setData([jsonData]);
            setError('');
        } catch (error) {
            console.error('Fetch error:', error);
            setError(error.message);
            setData(previousData); // Revert to previous data on error
        }
        setIsLoading(false);
    };

    return (
        <>
            <div className="header">
                <img src="https://asset22.ckassets.com/wp/wp-content/themes/CashKaro_WP_V1/img/logo.png" alt="ck-logo" />
            </div>
            <Grid container sx={{ margin: 'auto' }}>
            <Grid item xs={2} sm={2} md={2} px={2} sx={{ fontFamily: 'Metropolis', fontWeight: '600',background:'#f8f9fa',height:'100vh'  }}>
            {/* <button style={{background:'#f8f9fa', width:'100%',border:'1px solid #2f3c97',borderRadius:'6px',marginTop:'1rem'}} onClick={() => window.location.href = '/admin/index'}>
  <Typography  py={1} sx={{color:'#2f3c97'}}>CashKaro Leaderboard</Typography>
</button> */}
                {/* <button style={{background:'#f8f9fa', width:'100%',border:'1px solid #2f3c97',borderRadius:'6px',marginTop:'1rem'}} onClick={() => window.location.href = '/admin-flash'}>
  <Typography  py={1} sx={{color:'#2f3c97'}}>Flash Clash Leaderboard</Typography>
</button> */}
<button style={{background:'#f8f9fa', width:'100%',border:'1px solid #2f3c97',borderRadius:'6px',marginTop:'1rem'}}>
  <Typography  py={1} sx={{color:'#2f3c97'}}>Big Beauty Sale</Typography>
</button>
            </Grid>
            <Grid item xs={10} sm={10} md={10} pl={2}>
                <Typography variant="h5" sx={{ marginY: '2rem', fontFamily: 'Metropolis', fontWeight: '600',  }}>
        Biggest Beauty Star Leaderboard (09th July to 31st July)
                </Typography>
                <FilterPopup setFilters={setFilters} onFilter={setIsFilterActive} onEnter={() => setIsFilterActive(true)} />
                {error && (
                <Alert severity="error" sx={{ width: '80%', margin: 'auto', marginTop: '20px' }}>
                    CK_ID not found.
                </Alert>
            )}

            {isLoading ? (
                <Loader show={isLoading} />
            ) : (
                <Grid container sx={{ margin: 'auto' }}>
                    {data.length > 0 ? data.map(item => (
                        <Card key={item.ck_id} sx={{ minWidth: '100%', marginBottom: '20px', marginTop: '2rem' }}>
                            <CardContent>
                                <Typography variant="h6" sx={{ fontFamily: 'Metropolis' }}>
                                    <b>Name:</b> {item?.name}
                                </Typography>
                                <Typography variant="h6" sx={{ fontFamily: 'Metropolis' }}>
                                    <b>Mobile:</b> {item?.contact}
                                </Typography>
                                <Typography variant="h6" sx={{ fontFamily: 'Metropolis' }}>
                                    <b>Gender:</b> {item?.gender === 'M' ? 'Male' : item?.gender === 'F' ? 'Female' : 'Unspecified'}
                                </Typography>
                                <Typography variant="h6" sx={{ fontFamily: 'Metropolis' }}>
    <b>Onboarding Date:</b> {new Date(item?.createdAt).toLocaleDateString('en-IN', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'Asia/Kolkata'
    })} at {new Date(item?.createdAt).toLocaleTimeString('en-IN', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Asia/Kolkata'
    })}
</Typography>


                                <Typography variant="h6" sx={{ fontFamily: 'Metropolis' }}>
                                    <b>Current Rank:</b> {item?.leaderboards_bsc?.current_rank}
                                </Typography>
                                <Typography variant="h6" sx={{ fontFamily: 'Metropolis' }}>
                                    <b>Last Rank:</b> {item?.leaderboards_bsc?.last_rank}
                                </Typography>
                                <Typography variant="h6" sx={{ fontFamily: 'Metropolis' }}>
                                    <b>Points:</b> {item?.leaderboards_bsc?.points}
                                </Typography>
                                <Typography variant="h6" sx={{ fontFamily: 'Metropolis' }}>
                                    <b>Transactions:</b> {item?.leaderboards_bsc?.transactions}
                                </Typography>
                                <Typography variant="h6" sx={{ fontFamily: 'Metropolis' }}>
                                    <b>Total Order Value:</b> {item?.leaderboards_bsc?.total_order_value}
                                </Typography>
                            </CardContent>
                        </Card>
                    )) : (
                        <Typography sx={{ width: '80%', margin: 'auto', marginTop: '20px' }}>

                        </Typography>
                    )}
                </Grid>
            )}
            
            </Grid>
            </Grid>
            
        </>
    );
}

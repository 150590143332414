import * as React from "react";
import { useRoutes } from "react-router-dom";
import Dashboard from "./layouts/index";
// import Footer from './layouts/dashboard/footer';
import Login from "./pages/login";
import Home from "./pages/home";
import Shortner from "./pages/shortner";
import Error from "./pages/error";
import Stores from "./pages/stores/index";
import StoreCreate from "./pages/stores/create";
import StoreEdit from "./pages/stores/edit";
import Users from "./pages/users/index";
import Blacklists from "./pages/blacklist/index";
import Settings from "./pages/settings/index";
import Deals from "./pages/deals/index";
import Exits from "./pages/exits/index";
import Funnels from "./pages/funnels/index";
import Signup from "./pages/leaderboard/signup";
import Leaderboard from "./pages/leaderboard/index";
import LeaderboardOrSignup from "./pages/leaderboard/leaderIndex";
import FlashSignup from "./pages/leaderboard-Flash-clash/signup";
import FlashLeaderboard from "./pages/leaderboard-Flash-clash/index";
import FlashLeaderboardOrSignup from "./pages/leaderboard-Flash-clash/leaderIndex";
import MahaBachatSignup from "./pages/leaderboard-MahaBachat/signup";
import MahaBachatLeaderboard from "./pages/leaderboard-MahaBachat/index";
import MahaBachatLeaderboardOrSignup from "./pages/leaderboard-MahaBachat/leaderIndex";
import AdminLogin from "./pages/admin/login";
import AdminIndex from "./pages/admin/index";
import AdminFlashIndex from "./pages/admin/flashIndex";
import BachatIndex from "./pages/admin/bachatIndex";
import MatchSignup from "./pages/matchKaro/signup";
import MatchIndex from "./pages/matchKaro/index";
import MatchOrSignup from "./pages/matchKaro/matchIndex";
import BeautyIndex from "./pages/biggest-beauty-star/index";
import BeautySignUp from "./pages/biggest-beauty-star/signup";
import BeautyLeaderIndex from "./pages/biggest-beauty-star/leaderIndex";
import DailyBonanzaIndex from "./pages/dailyBonanza/bonanzaIndex";
import BeautyAdmin from "./pages/admin/beautyIndex";

// import Loader from './component/loader';
import Logout from "./components/logout";
import VipPass from "./pages/vip-pass-new-user/VipPass";
import VipPassLapsed from "./pages/vip-pass-lapsed-user/VipPass";
import VipPassPrevious from "./pages/vip-pass-previous-user/VipPass";
import ActivatePass from "./pages/vip-pass-new-user/components/ActivatePass";
import PassActivated from "./pages/vip-pass-new-user/PassActivated";
import PassActivatedLapsed from "./pages/vip-pass-lapsed-user/PassActivated";
import PassActivatedPrevious from "./pages/vip-pass-previous-user/PassActivated";
import VipPassActivated from "./pages/vip-pass-new-user/ActivatedVipPass";
import VipPassActivatedLapsed from "./pages/vip-pass-lapsed-user/ActivatedVipPass";
import VipPassActivatedPrevious from "./pages/vip-pass-previous-user/ActivatedVipPass";


export default function Router() {
  const aat = localStorage.getItem("ck_aat");
  const bundle = localStorage.getItem("ck_bundle");
  const ck_id = localStorage.getItem("ck_id");
  let isLoggedIn = false;
  if (aat && bundle) {
    isLoggedIn = true;
  }
  return useRoutes(
    isLoggedIn
      ? [
          {
            path: "/",
            element: <Dashboard />,
            children: [
              { path: "/", element: <Home /> },
              { path: "/stores", element: <Stores /> },
              { path: "/exits", element: <Exits /> },
              { path: "/stores/create", element: <StoreCreate /> },
              { path: "/stores/edit", element: <StoreEdit /> },
              { path: "/users", element: <Users /> },
              { path: "/blacklists", element: <Blacklists /> },
              { path: "/settings", element: <Settings /> },
              { path: "/deals", element: <Deals /> },
              { path: "/funnels", element: <Funnels /> },
              { path: "/leaderboard", element: <LeaderboardOrSignup /> },
              {
                path: "/maha-bachat/index",
                element: <MahaBachatLeaderboardOrSignup />,
              },
              {
                path: "/flash-clash/index",
                element: <FlashLeaderboardOrSignup />,
              },
              { path: "/beauty-star/index", element: <BeautyLeaderIndex /> },
              // {path: "/admin/login", element:<AdminLogin />},
              // {path: "/admin/index", element:<AdminIndex />},
            ],
          },
          { path: "/logout", element: <Logout /> },
        ]
      : [
          {
            path: "/login",
            element: <Login />,
            children: [{ path: "/login", element: <Login /> }],
          },
          { path: "/admin/login", element: <AdminLogin /> },
          { path: "/admin/index", element: <AdminIndex /> },
          // { path: "/admin-flash/login", element: <FlashLogin /> },
          { path: "/admin-flash", element: <AdminFlashIndex /> },
          { path: "/admin-bachat", element: <BachatIndex /> },
          { path: "/admin-beauty", element: <BeautyAdmin /> },
          { path: "/:id", element: <Shortner /> },
          { path: "/leaderboard", element: <LeaderboardOrSignup /> },
          { path: "/match/index", element: <MatchOrSignup /> },
          { path: "/flash-clash/index", element: <FlashLeaderboardOrSignup /> },
          {
            path: "/maha-bachat/index",
            element: <MahaBachatLeaderboardOrSignup />,
          },
          { path: "/beauty-star/index", element: <BeautyLeaderIndex /> },
          { path: "/daily-bonanza/index", element: <DailyBonanzaIndex /> },
          { path: "/vip-pass-new-user/index", element: <VipPass /> },
          { path: "/vip-pass-lapsed-user/index", element: <VipPassLapsed /> },
          { path: "/vip-pass-active-user/index", element: <VipPassPrevious /> },
          { path: "/vip-pass-new-user/activated", element: <PassActivated /> },
          { path: "/vip-pass-new-user/active", element: <VipPassActivated /> },
          { path: "/vip-pass-lapsed-user/active", element: <VipPassActivatedLapsed /> },
          { path: "/vip-pass-active-user/active", element: <VipPassActivatedPrevious /> },
          { path: "/vip-pass-lapsed-user/activated", element: <PassActivatedLapsed /> },
          { path: "/vip-pass-active-user/activated", element: <PassActivatedPrevious /> },


          // {path:"/matchkaro/index", element:<MatchIndex />},
          // {path:"/matchkaro/signup", element:<MatchSignup />},

          { path: "/", element: <Error /> },
        ]
  );
}

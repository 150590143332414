import * as Yup from 'yup';
// add validation to driver form
const storeFormSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').max(255),
    store_id: Yup.number().required('Store ID is required').typeError('Store ID must be number.'),
    store_url: Yup.string().required('Store URL is required').max(255),
    header_text: Yup.string().max(255),
    footer_text: Yup.string().max(255),
    url_add: Yup.string().max(255),
    url_remove: Yup.string().max(255),
    sub_id_keys: Yup.string().required('Sub ID Keys is required').max(255),
    status: Yup.string().required('Status is required').max(255),
});

export default storeFormSchema;

import * as Yup from 'yup';
// Define a validation schema for the signup form
const phoneRegExp = /^[6-9]\d{9}$/;  // Regex for validating Indian mobile numbers starting with 6-9 and having 10 digits

const signupSchema = Yup.object().shape({
    contact: Yup.string()
        .required('Mobile Number is required.').matches(phoneRegExp, 'Mobile number is not valid'),
    gender: Yup.string()
        .required('Gender is required.').oneOf(['M', 'F'], 'Invalid gender'),  // Validates that the gender is either 'male' or 'female'
    consent: Yup.boolean()
        .required('Consent is required.').oneOf([true], 'Consent is required to participate.'),  // Ensures the consent checkbox must be checked
});

export default signupSchema;
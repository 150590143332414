import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Grid, Card } from '@mui/material';
import { TextField, Box } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Link from '@mui/material/Link';
import Alert from '../../components/alert';
import Loader from '../../components/loader';
export default function Edit(props) {
    const navigate = useNavigate();
    // const [apiErrors, setApiErrors] = useState([]);
    const bundle = localStorage.getItem("ck_aat");
    // const login_type = localStorage.getItem("bka");
    const [data, setData] = useState([]);
    const [alert, setAlert] = useState(false);
    const [type, setType] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setLoader] = useState(false);
    // create a preview as a side effect, whenever selected file is changed
    const callApi = () => {
        setLoader(true);
        if (bundle != null) {
            // setAccessToken(bundle);
        } else {
            setAlert(true);
            setType('error');
            setMessage('No Token Found');
            navigate('/login', { replace: true });
        }
        const requestOptions = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${bundle}`
            },
        };

        fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/settings`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if ('error' in data) {
                    setLoader(false)
                    setAlert(true);
                    setType('error');
                    setMessage(data.message);
                } else {
                    console.log(data);
                    if (data.length !== undefined) {
                        setData(data);
                        setLoader(false);
                    } else {
                        setLoader(false)
                        setAlert(true);
                        setType('error');
                        setMessage('No Settings Found');
                    }
                }
            });
    }
    useEffect(() => {
        callApi()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps



    const updateData = (id, key, value) => {
        setData(
            data.map((item) => {
                if (item.id === id) {
                    if (key === 'value') {
                        return { ...item, value };
                    } else {
                        let status = value
                        return { ...item, status };
                    }
                } else {
                    return item;
                }
            })
        );
    }
    const submitForm = () => {
        setLoader(true);

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${bundle}`
            },
            body: JSON.stringify(data)
        };
        fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/settings`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setLoader(false);
                if ('success' in data) {
                    setAlert(true);
                    setType('success');
                    setMessage(data.message);
                } else {
                    setAlert(true);
                    setType('error');
                    setMessage('Something went wrong');
                }
            }
            );

    }


    return (
        <>
            <Box >
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '20px' }}>
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="#1B191F"
                        href="/"
                        key="1"
                    >
                        <HomeIcon sx={{ mr: 0.5 }} /> Dashboard
                    </Link>
                    <Link underline="none" color="gray" key="2" >Settings</Link>
                </Breadcrumbs>
                {isLoading === true ? <Loader show={isLoading} /> :
                    <Card style={{ padding: '20px', border: '1px solid #1B191F', borderRadius: '5px' }}>
                        <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: '10px' }}>Settings</Typography>
                        <Grid container spacing={3} >
                            
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Grid container spacing={3} >
                                    {(data.length > 0 && data !== undefined) ? data.map((item, i) => (
                                        <Grid item key={i} xs={12} sm={12} md={12} lg={12}>
                                            <Typography >{item.name}</Typography>
                                            <Grid container spacing={1} >
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        variant="outlined"
                                                        value={item.value}
                                                        size="small"
                                                        onChange={(e) => updateData(item.id, 'value', e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <FormGroup>
                                                        <FormControlLabel control={<Switch checked={item.status} onChange={(event, checked) => updateData(item.id, 'status', checked ? true : false)} />} label="Status" />
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )) : <Grid item xs={12} sm={12} md={12} p={2}>
                                        <Typography variant="body" gutterBottom>
                                            No data found
                                        </Typography>
                                    </Grid>}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Button variant="contained" type="button" color="secondary" sx={{ float: 'right', textTransform: 'capitalize', width: '20%' }} onClick={() => submitForm()}>Update</Button>
                            </Grid>
                        </Grid>
                    </Card>
                }
            </Box>
            <Alert open={alert} type={type} message={message} setOpen={setAlert} />
        </>
    )
}
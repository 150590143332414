import React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid, Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';

export default function Filter(props) {
    const { setFilters, onFilter, setData, totalData } = props;
    const [values, setValues] = useState({
        ck_id: '',
        // bank_name :'',
        // product_type :'',
        // commission_type :'',
        // status :''
    });
    // const [btnLoad, setbtnLoad] = React.useState(false);
    const changeValue = (e, parm) => {
        setValues(prevState => ({ ...prevState, [parm]: e.target.value }))
    }
    const filterdata = () => {
        setFilters(values);
        onFilter(true);
    }
    const handleResetClick = () => {
        setValues({
            ck_id: '',
        });
        setFilters({
            ck_id: '',
        });
        // setData(totalData)
        onFilter(true);
    }

    return (
        <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={12} sm={12} mt={1}>
                <TextField
                    fullWidth
                    type="number"
                    variant="outlined"
                    label="CK ID"
                    size="small"
                    value={values.ck_id}
                    onChange={(e) => changeValue(e, 'ck_id')}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) { // Check if the Enter key is pressed
                            filterdata();
                        }
                    }}
                />
            </Grid>
            <Grid item lg={6} md={6} sm={12} mt={1.2}>
                <Button
                    variant="text"
                    sx={{
                        color: '#fff',
                        background: '#2f3c97',
                        '&:hover': {
                            background: '#ee7430', // Keeps the background color the same on hover
                        }
                    }}
                    onClick={filterdata}
                >
                    Search
                </Button>
            </Grid>
        </Grid>
    );
}

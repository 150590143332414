import React, { useRef } from "react";
import { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Grid,
  IconButton,
  Paper,
  Typography,
  SwipeableDrawer,
  TextField,
  Box,
  FormHelperText,
  Stack,
  Divider,
  CircularProgress,
} from "@mui/material";
import Topbanner from "../../images/Background.svg";
import Loader from "../../components/loader";
// import Avator1 from '../../images/boy/avatar1.png';
// import Avator2 from '../../images/boy/avatar2.png';
// import Avator3 from '../../images/boy/avatar3.png';
// import Avator4 from '../../images/boy/avatar4.png';
// import Avator5 from '../../images/boy/avatar5.png';
// import Avator6 from '../../images/boy/avatar6.png';
// import Avator7 from '../../images/boy/avatar7.png';
// import Avator8 from '../../images/boy/avatar8.png';
// import Avator9 from '../../images/boy/avatar9.png';
// import Avator10 from '../../images/boy/avatar10.png';
// import girl1 from '../../images/girl/girl1.png';
// import girl2 from '../../images/girl/girl2.png';
// import girl3 from '../../images/girl/girl3.png';
// import girl4 from '../../images/girl/girl4.png';
// import girl5 from '../../images/girl/girl5.png';
// import girl6 from '../../images/girl/girl6.png';
// import girl7 from '../../images/girl/girl7.png';
// import girl8 from '../../images/girl/girl8.png';
// import girl9 from '../../images/girl/girl9.png';
// import girl10 from '../../images/girl/girl10.png';
import Alert from "../../components/alert";
import First from "../../images/avatar1.png";
import Second from "../../images/Boy2.png";
import Third from "../../images/Boy1.png";
import Gold from "../../images/gold.png";
import LoaderImg from "../../components/loader4.svg";
import Silver from "../../images/silver.png";
import Bronze from "../../images/bronze.png";
import Rank from "../../images/rank.png";
import { useNavigate } from "react-router-dom";
import GoldMedal from "../../images/gold-medal.png";
import BronzeMedal from "../../images/bronze-medal.png";
import Up from "../../images/up.png";
import Down from "../../images/down.png";
import SilverMedal from "../../images/silver-medal.png";
import Prize from "../../images/reward-banner.svg";
import Button from "@mui/material/Button";
import RemoveIcon from "@mui/icons-material/Remove";
import { useFormik, Form, FormikProvider } from "formik";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxTickIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxIconn from "@mui/icons-material/CheckBox";
import { Squircle } from "corner-smoothing";
import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import Badge from "@mui/material/Badge";
import bg from "./images/activeGridBg.png";
import {
  retailersGroup1,
  retailersGroup2,
  retailersGroup3,
  retailersGroup4,
  retailersGroup5,
  retailersGroup6,
} from "./increasedBrandsData";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AcceleratedBrands from "./components/AcceleratedBrands";
import CouponBrands from "./components/CouponBrands";
import EligibleBrands from "./components/EligibleBrands";
import ActivatePass from "./components/ActivatePass";
import { faqs } from "./acceleratedCashbackData";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TermsAndConditions from "./components/Terms";
import earned from './images/activeSlice2.png'
import elipse from './images/elipse.png'

function useGoBackTwoPages() {
  useEffect(() => {
    const handlePopState = (event) => {
      // Go back two pages
      window.history.go(-2);
    };

    // Listen for the popstate event (back button press)
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
}

export default function ActivatedVipPass() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [acceleratedBrands, setAcceleratedBrands] = useState(false);
  const [couponCodes, setCouponCodes] = useState(false);
  const [eligibleBrands, setEligibleBrands] = useState(false);
  const [activatePass, setActivatePass] = useState(false);
  const [faqExpand, setFaqExpand] = useState(false);
  const [earnings, setEarnings] = useState(0);

  const ck_id = localStorage.getItem('vp_ck_id');

  // useGoBackTwoPages()

  const fetchData = async () => {

    // if (!ck_id) {
    //   navigate('/vip-pass-active-user/index')
    // }
    setLoading(true);
    try {
      let res = await fetch(`${process.env.REACT_APP_SITE_URL_VP}/api/user/getuserdata_vp?ck_id=${ck_id}`)
      res = await res.json()
      console.log(res)
      if (!res?.user_data) {
        navigate('/vip-pass-active-user/index')
      }
      setEarnings(Number(res?.user_data?.extra_cashback_earned))
    } catch (error) {
      console.log(error.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Grid container sx={{ background: "#fff" }}>
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
      {loading ? (
        <Box sx={{ display: "block", margin: "0 auto", marginTop: "50%" }}>
          <CircularProgress />
        </Box>
      ) : ""}
      <Grid sx={{ display: loading ? 'none' : 'block', overflow: 'hidden' }} item xs={12} sm={12} md={4} lg={4}>
        <Grid item xs={12}>
          <img
            src={require("./images/activeSlice1.png")}
            alt="Topbanner"
            style={{ width: "100%" }}
            onLoad={() => setLoading(false)}
          />
          {/* <div
              style={{
                position: "relative",
                width: "100%",
                display: "inline-block",
              }}
            >
              <img
                src={require("./images/activeSlice2.png")}
                alt="view brands cta"
                style={{ width: "100%" }}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "30%", // Adjust this value based on how much of the bottom you want clickable
                  backgroundColor: "rgba(0, 0, 0, 0)", // Transparent background
                  cursor: "pointer",
                }}
                onClick={() => setActivatePass(true)}
              ></div>
            </div> */}
          <Box
            xs={12}
            sx={{
              backgroundImage: `url(${earned})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#000",
              height: '250px',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              // alignItems: 'center',
              color: '#fff'
            }}
          >
            <Box sx={{ zIndex: 1, width: '270px', marginTop: '80px' }}>
              <Typography sx={{ background: 'linear-gradient(180deg, #FFF0D2 0%, #B07631 100%);', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '32px', fontWeight: 600, textAlign: 'center' }}>{earnings === 0 || isNaN(earnings) ? '₹0' : earnings.toLocaleString('en-IN', {
                maximumFractionDigits: 0,
                style: 'currency',
                currency: 'INR'
              })}</Typography>

              {earnings === 0 || isNaN(earnings) ? <Typography sx={{ textAlign: 'center', color: '#B9BAD9', fontWeight: 400, fontSize: '12px', marginTop: '2px' }}>Your extra cashback earned will show up here once you start shopping with your VIP Pass.</Typography> : <Typography sx={{ textAlign: 'center', color: '#B9BAD9', fontWeight: 400, fontSize: '12px', marginTop: '2px' }}>Your VIP Pass has earned you an extra {earnings.toLocaleString('en-IN', {
                maximumFractionDigits: 0,
                style: 'currency',
                currency: 'INR'
              })} in Cashback! Keep shopping to boost your earnings even further!</Typography>}




            </Box>
            <Box
              onClick={() => {
                console.log("hello");
                const targetElement =
                  document.getElementById("increased_cashback");
                if (targetElement) {
                  targetElement.scrollIntoView({ behavior: "smooth" });
                }
              }}
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '50px', // Adjust the height as needed
                cursor: 'pointer', zIndex: 999
              }}
            />
          </Box>

          <div
            style={{
              position: "relative",
              width: "100%",
              display: "inline-block",
            }}
          >
            <img
              src={require("./images/slice4.png")}
              alt="view brands cta"
              style={{ width: "100%" }}
            />
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "30%", // Adjust this value based on how much of the bottom you want clickable
                backgroundColor: "rgba(0, 0, 0, 0)", // Transparent background
                cursor: "pointer",
              }}
              onClick={() => {
                console.log("hello");
                const targetElement =
                  document.getElementById("increased_cashback");
                if (targetElement) {
                  targetElement.scrollIntoView({ behavior: "smooth" });
                }
              }}
            ></div>
          </div>
          <div
            style={{
              position: "relative",
              width: "100%",
              display: "inline-block",
            }}
          >
            <img
              src={require("./images/slice5.png")}
              alt="accelerated cashback"
              style={{ width: "100%" }}
            />
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "30%", // Adjust this value based on how much of the bottom you want clickable
                backgroundColor: "rgba(0, 0, 0, 0)", // Transparent background
                cursor: "pointer",
              }}
              onClick={() => setAcceleratedBrands(true)}
            ></div>
          </div>
          <div
            style={{
              position: "relative",
              width: "100%",
              display: "inline-block",
            }}
          >
            <img
              src={require("./images/slice6.png")}
              alt="accelerated cashback"
              style={{ width: "100%" }}
            />
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "30%", // Adjust this value based on how much of the bottom you want clickable
                backgroundColor: "rgba(0, 0, 0, 0)", // Transparent background
                cursor: "pointer",
              }}
              onClick={() => setCouponCodes(true)}
            ></div>
          </div>
          {/* <div
              style={{
                position: "relative",
                width: "100%",
                display: "inline-block",
              }}
            >
              <img
                src={require("./images/slice7.png")}
                alt="coupon codes cta"
                style={{ width: "100%" }}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "30%", // Adjust this value based on how much of the bottom you want clickable
                  backgroundColor: "rgba(0, 0, 0, 0)", // Transparent background
                  cursor: "pointer",
                }}
                onClick={() => setEligibleBrands(true)}
              ></div>
            </div>
            <div
              style={{
                position: "relative",
                width: "100%",
                display: "inline-block",
              }}
            >
              <img
                src={require("./images/slice8.png")}
                alt="coupon codes cta"
                style={{ width: "100%" }}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "30%", // Adjust this value based on how much of the bottom you want clickable
                  backgroundColor: "rgba(0, 0, 0, 0)", // Transparent background
                  cursor: "pointer",
                }}
                onClick={() => console.log("hello")}
              ></div>
            </div> */}

          <Box
            xs={12}
            id="increased_cashback"
            sx={{
              backgroundImage: `url(${bg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#000",
              height: "700px",
              // width:'360px'
            }}
          >
            <Box pt={3.2}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img
                  style={{ height: "32px" }}
                  src={require("./images/graph.png")}
                />
              </Box>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "1.25rem",
                  fontFamily: "Metropolis",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                VIP Pass - Increased <br /> Cashback Brands
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box>
                <Box m={4} p={1} sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', gap: '12px', width: '328px', justifyContent: 'center', background: 'rgba(255, 184, 146, 0.2);', borderRadius: '8px' }}>
                  <img style={{ height: '23.28px', width: '23.28px' }} src={require('./images/excla.png')} />
                  <Typography sx={{ fontWeight: 400, fontSize: '12px', width: '266px', color: '#fff', opacity: '1.0' }}>Access these brands via this section only to earn increased cashback. Other app sections will not provide these rates.</Typography>
                </Box>
              </Box>
            </Box>
            <Grid container pt={4} px={2}>
              {retailersGroup1.map((r, i) => (
                <Grid key={i} item xs={4} md={4} mt={-0.75}>
                  <a href={r?.url}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      position="relative"
                    >
                      <img
                        loading="lazy"
                        src={require(`./images/increasedCashback/${r?.image}.png`)}
                        alt="logo18"
                        style={{ height: "68px" }}
                      />
                      <Typography
                        sx={{
                          fontSize: '10px',
                          fontFamily: 'Metropolis',
                          fontWeight: '600',
                          color: "#fff",
                          position: 'absolute',
                          bottom: '10%',
                          left: '50%',
                          transform: 'translate(-50%, 70%)',
                          textAlign: 'center', borderRadius: '50%', padding: '2px', height: '24px', width: '24px',

                          display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(88, 123, 216, 0.6)',
                          border: '1.5px solid rgba(255, 255, 255, 0.3)',

                        }}
                      >
                        <img style={{ height: '8px' }} src={require('./images/click.png')} alt="click" />
                      </Typography>
                    </Box>
                  </a>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: 600,
                        marginTop: "18px",
                      }}
                    >
                      {r?.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Metropolis",
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        display: "flex",
                        gap: "2px",
                        marginTop: "8px",
                        justifyContent: "center",
                      }}
                    >
                      {r.new_cashback}{" "}
                      <span
                        style={{
                          fontSize: "10px",
                          display: "flex",
                          marginTop: "3px",
                          gap: "2px",
                        }}
                      >
                        <s style={{ color: "#9F9FAA" }}>
                          {r?.strikethrough_cashback}
                        </s>{" "}
                        Cashback
                      </span>
                    </Typography>
                    <img
                      src={require("./images/line.png")}
                      alt="line"
                      style={{ width: "100%", marginTop: "-6px" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "400",
                        color: "#FFD6B9",
                        fontSize: "10px",
                        textAlign: "center",
                        fontFamily: "Metropolis",
                      }}
                    >
                      {r?.greater_savings} More Savings
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Grid container pt={4} px={2}>
              {retailersGroup2.map((r, i) => (
                <Grid key={i} item xs={4} md={4} mt={-0.75}>
                  <a href={r?.url}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      position="relative"
                    >
                      <img
                        loading="lazy"
                        src={require(`./images/increasedCashback/${r?.image}.png`)}
                        alt="logo18"
                        style={{ height: "68px" }}
                      />
                      <Typography
                        sx={{
                          fontSize: '10px',
                          fontFamily: 'Metropolis',
                          fontWeight: '600',
                          color: "#fff",
                          position: 'absolute',
                          bottom: '10%',
                          left: '50%',
                          transform: 'translate(-50%, 70%)',
                          textAlign: 'center', borderRadius: '50%', padding: '2px', height: '24px', width: '24px',

                          display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(88, 123, 216, 0.6)',
                          border: '1.5px solid rgba(255, 255, 255, 0.3)',

                        }}
                      >
                        <img style={{ height: '8px' }} src={require('./images/click.png')} alt="click" />
                      </Typography>
                    </Box>
                  </a>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: 600,
                        marginTop: "18px",
                      }}
                    >
                      {r?.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Metropolis",
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        display: "flex",
                        gap: "2px",
                        marginTop: "8px",
                        justifyContent: "center",
                      }}
                    >
                      {r.new_cashback}{" "}
                      <span
                        style={{
                          fontSize: "10px",
                          display: "flex",
                          marginTop: "3px",
                          gap: "2px",
                        }}
                      >
                        <s style={{ color: "#9F9FAA" }}>
                          {r?.strikethrough_cashback}
                        </s>{" "}
                        Cashback
                      </span>
                    </Typography>
                    <img
                      src={require("./images/line.png")}
                      alt="line"
                      style={{ width: "100%", marginTop: "-6px" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "400",
                        color: "#FFD6B9",
                        fontSize: "10px",
                        textAlign: "center",
                        fontFamily: "Metropolis",
                      }}
                    >
                      {r?.greater_savings} More Savings
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          {expanded && (
            <Box xs={12} sx={{ backgroundColor: "#030418" }}>
              <Grid container px={2}>
                {retailersGroup3.map((r, i) => (
                  <Grid key={i} item xs={4} md={4} mt={-0.75}>
                    <a href={r?.url}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        position="relative"
                      >
                        <img
                          loading="lazy"
                          src={require(`./images/increasedCashback/${r?.image}.png`)}
                          alt="logo18"
                          style={{ height: "68px" }}
                        />
                        <Typography
                          sx={{
                            fontSize: '10px',
                            fontFamily: 'Metropolis',
                            fontWeight: '600',
                            color: "#fff",
                            position: 'absolute',
                            bottom: '10%',
                            left: '50%',
                            transform: 'translate(-50%, 70%)',
                            textAlign: 'center', borderRadius: '50%', padding: '2px', height: '24px', width: '24px',

                            display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(88, 123, 216, 0.6)',
                            border: '1.5px solid rgba(255, 255, 255, 0.3)',

                          }}
                        >
                          <img style={{ height: '8px' }} src={require('./images/click.png')} alt="click" />
                        </Typography>

                      </Box>
                    </a>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: 600,
                          marginTop: "18px",
                        }}
                      >
                        {r?.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Metropolis",
                          fontWeight: "600",
                          color: "#fff",
                          textAlign: "center",
                          display: "flex",
                          gap: "2px",
                          marginTop: "8px",
                          justifyContent: "center",
                        }}
                      >
                        {r.new_cashback}{" "}
                        <span
                          style={{
                            fontSize: "10px",
                            display: "flex",
                            marginTop: "3px",
                            gap: "2px",
                          }}
                        >
                          <s style={{ color: "#9F9FAA" }}>
                            {r?.strikethrough_cashback}
                          </s>{" "}
                          Cashback
                        </span>
                      </Typography>
                      <img
                        src={require("./images/line.png")}
                        alt="line"
                        style={{ width: "100%", marginTop: "-6px" }}
                      />

                      <Typography
                        sx={{
                          fontSize: "400",
                          color: "#FFD6B9",
                          fontSize: "10px",
                          textAlign: "center",
                          fontFamily: "Metropolis",
                        }}
                      >
                        {r?.greater_savings} More Savings
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>

              <Grid container pt={4} px={2}>
                {retailersGroup4.map((r, i) => (
                  <Grid key={i} item xs={4} md={4} mt={-0.75}>
                    <a href={r?.url}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        position="relative"
                      >
                        <img
                          loading="lazy"
                          src={require(`./images/increasedCashback/${r?.image}.png`)}
                          alt="logo18"
                          style={{ height: "68px" }}
                        />
                        <Typography
                          sx={{
                            fontSize: '10px',
                            fontFamily: 'Metropolis',
                            fontWeight: '600',
                            color: "#fff",
                            position: 'absolute',
                            bottom: '10%',
                            left: '50%',
                            transform: 'translate(-50%, 70%)',
                            textAlign: 'center', borderRadius: '50%', padding: '2px', height: '24px', width: '24px',

                            display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(88, 123, 216, 0.6)',
                            border: '1.5px solid rgba(255, 255, 255, 0.3)',

                          }}
                        >
                          <img style={{ height: '8px' }} src={require('./images/click.png')} alt="click" />
                        </Typography>
                      </Box>
                    </a>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: 600,
                          marginTop: "18px",
                        }}
                      >
                        {r?.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Metropolis",
                          fontWeight: "600",
                          color: "#fff",
                          textAlign: "center",
                          display: "flex",
                          gap: "2px",
                          marginTop: "8px",
                          justifyContent: "center",
                        }}
                      >
                        {r.new_cashback}{" "}
                        <span
                          style={{
                            fontSize: "10px",
                            display: "flex",
                            marginTop: "3px",
                            gap: "2px",
                          }}
                        >
                          <s style={{ color: "#9F9FAA" }}>
                            {r?.strikethrough_cashback}
                          </s>{" "}
                          Cashback
                        </span>
                      </Typography>
                      <img
                        src={require("./images/line.png")}
                        alt="line"
                        style={{ width: "100%", marginTop: "-6px" }}
                      />
                      <Typography
                        sx={{
                          fontSize: "400",
                          color: "#FFD6B9",
                          fontSize: "10px",
                          textAlign: "center",
                          fontFamily: "Metropolis",
                        }}
                      >
                        {r?.greater_savings} More Savings
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>

              <Grid container pt={4} px={2}>
                {retailersGroup5.map((r, i) => (
                  <Grid key={i} item xs={4} md={4} mt={-0.75}>
                    <a href={r?.url}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        position="relative"
                      >
                        <img
                          loading="lazy"
                          src={require(`./images/increasedCashback/${r?.image}.png`)}
                          alt="logo18"
                          style={{ height: "68px" }}
                        />
                        <Typography
                          sx={{
                            fontSize: '10px',
                            fontFamily: 'Metropolis',
                            fontWeight: '600',
                            color: "#fff",
                            position: 'absolute',
                            bottom: '10%',
                            left: '50%',
                            transform: 'translate(-50%, 70%)',
                            textAlign: 'center', borderRadius: '50%', padding: '2px', height: '24px', width: '24px',

                            display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(88, 123, 216, 0.6)',
                            border: '1.5px solid rgba(255, 255, 255, 0.3)',

                          }}
                        >
                          <img style={{ height: '8px' }} src={require('./images/click.png')} alt="click" />
                        </Typography>
                      </Box>
                    </a>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: 600,
                          marginTop: "18px",
                        }}
                      >
                        {r?.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Metropolis",
                          fontWeight: "600",
                          color: "#fff",
                          textAlign: "center",
                          display: "flex",
                          gap: "2px",
                          marginTop: "8px",
                          justifyContent: "center",
                        }}
                      >
                        {r.new_cashback}{" "}
                        <span
                          style={{
                            fontSize: "10px",
                            display: "flex",
                            marginTop: "3px",
                            gap: "2px",
                          }}
                        >
                          <s style={{ color: "#9F9FAA" }}>
                            {r?.strikethrough_cashback}
                          </s>{" "}
                          Cashback
                        </span>
                      </Typography>
                      <img
                        src={require("./images/line.png")}
                        alt="line"
                        style={{ width: "100%", marginTop: "-6px" }}
                      />
                      <Typography
                        sx={{
                          fontSize: "400",
                          color: "#FFD6B9",
                          fontSize: "10px",
                          textAlign: "center",
                          fontFamily: "Metropolis",
                        }}
                      >
                        {r?.greater_savings} More Savings
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Grid container pt={4} px={2}>
                {retailersGroup6.map((r, i) => (
                  <Grid key={i} item xs={4} md={4} mt={-0.75}>
                    <a href={r?.url}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        position="relative"
                      >
                        <img
                          loading="lazy"
                          src={require(`./images/increasedCashback/${r?.image}.png`)}
                          alt="logo18"
                          style={{ height: "68px" }}
                        />
                        <Typography
                          sx={{
                            fontSize: '10px',
                            fontFamily: 'Metropolis',
                            fontWeight: '600',
                            color: "#fff",
                            position: 'absolute',
                            bottom: '10%',
                            left: '50%',
                            transform: 'translate(-50%, 70%)',
                            textAlign: 'center', borderRadius: '50%', padding: '2px', height: '24px', width: '24px',

                            display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(88, 123, 216, 0.6)',
                            border: '1.5px solid rgba(255, 255, 255, 0.3)',

                          }}
                        >
                          <img style={{ height: '8px' }} src={require('./images/click.png')} alt="click" />
                        </Typography>
                      </Box>
                    </a>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: 600,
                          marginTop: "18px",
                        }}
                      >
                        {r?.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Metropolis",
                          fontWeight: "600",
                          color: "#fff",
                          textAlign: "center",
                          display: "flex",
                          gap: "2px",
                          marginTop: "8px",
                          justifyContent: "center",
                        }}
                      >
                        {r.new_cashback}{" "}
                        <span
                          style={{
                            fontSize: "10px",
                            display: "flex",
                            marginTop: "3px",
                            gap: "2px",
                          }}
                        >
                          <s style={{ color: "#9F9FAA" }}>
                            {r?.strikethrough_cashback}
                          </s>{" "}
                          Cashback
                        </span>
                      </Typography>
                      <img
                        src={require("./images/line.png")}
                        alt="line"
                        style={{ width: "100%", marginTop: "-6px" }}
                      />
                      <Typography
                        sx={{
                          fontSize: "400",
                          color: "#FFD6B9",
                          fontSize: "10px",
                          textAlign: "center",
                          fontFamily: "Metropolis",
                        }}
                      >
                        {r?.greater_savings} More Savings
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>


              {/* <Grid container pt={4} px={2}>
                  {retailersGroup7.map((r, i) => (
                    <Grid key={i} item xs={4} md={4} mt={-0.75}>
                      <a href={r?.url}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          position="relative"
                        >
                          <img
                            loading="lazy"
                            src={require(`./images/increasedCashback/${r?.image}.png`)}
                            alt="logo18"
                            style={{ height: "68px" }}
                          />
                                        <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    fontFamily: 'Metropolis',
                                                    fontWeight: '600',
                                                    color: "#fff",
                                                    position: 'absolute',
                                                    bottom: '10%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, 70%)',
                                                    textAlign: 'center',borderRadius:'50%',padding:'2px',height:'24px',width:'24px',
                                                   
                                                    display:'flex',justifyContent: 'center',alignItems: 'center',backgroundColor:'rgba(88, 123, 216, 0.6)',
                                                    border: '1.5px solid rgba(255, 255, 255, 0.3)',

                                                }}
                                            >
                                                <img style={{height:'8px'}} src={require('./images/click.png')} alt="click" />
                                            </Typography>
                        </Box>
                      </a>
                      <Box sx={{ textAlign: "center" }}>
                        <Typography
                          sx={{
                            textAlign: "center",
                            color: "#fff",
                            fontSize: "12px",
                            fontWeight: 600,
                            marginTop: "18px",
                          }}
                        >
                          {r?.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontFamily: "Metropolis",
                            fontWeight: "600",
                            color: "#fff",
                            textAlign: "center",
                            display: "flex",
                            gap: "2px",
                            marginTop: "8px",
                            justifyContent: "center",
                          }}
                        >
                          {r.new_cashback}{" "}
                          <span
                            style={{
                              fontSize: "10px",
                              display: "flex",
                              marginTop: "3px",
                              gap: "2px",
                            }}
                          >
                            <s style={{ color: "#9F9FAA" }}>
                              {r?.strikethrough_cashback}
                            </s>{" "}
                            Cashback
                          </span>
                        </Typography>
                        <img
                          src={require("./images/line.png")}
                          alt="line"
                          style={{ width: "100%", marginTop: "-6px" }}
                        />
                        <Typography
                          sx={{
                            fontSize: "400",
                            color: "#FFD6B9",
                            fontSize: "10px",
                            textAlign: "center",
                            fontFamily: "Metropolis",
                          }}
                        >
                          {r?.greater_savings} More Savings
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid> */}
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#030418",
              paddingTop: expanded ? "30px" : "0px",
            }}
          >
            <Button
              onClick={() => setExpanded(!expanded)}
              sx={{
                height: "36px",
                padding: "8px 43px",
                borderRadius: "12px",
                border: "1px solid #fff",
                textTransform: "none",
                color: "#fff",
                backgroundColor: "#1c1d2f",
              }}
            >
              View {expanded ? "less" : "all"}{" "}
              {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Button>
          </Box>

          <Box sx={{ textAlign: 'center', background: "#030418", paddingTop: '60px' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 700, color: '#fff' }}>VIP Coupon Codes</Typography>
            <Typography px={'10px'} sx={{ color: '#B9BAD9', fontSize: '12px', width: '320px', fontWeight: 400, textAlign: 'center', margin: 'auto', marginTop: '8px' }}>As a pass holder, you gain access to the below exclusive offers not available to the general public!</Typography>
            <img onClick={() => setCouponCodes(true)} style={{ height: '63px', marginTop: '18px' }} src={require('./images/seeCoupons.png')} />
          </Box>



          <div
            style={{
              position: "relative",
              width: "100%",
              display: "inline-block",
              paddingTop: '60px',
              background: "#030418"
            }}
          >
            <img
              src={require("./images/slice7.png")}
              alt="coupon codes cta"
              style={{ width: "100%" }}
            />
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "30%", // Adjust this value based on how much of the bottom you want clickable
                backgroundColor: "rgba(0, 0, 0, 0)", // Transparent background
                cursor: "pointer",
              }}
              onClick={() => setEligibleBrands(true)}
            ></div>
          </div>
          <div
            style={{
              position: "relative",
              width: "100%",
              display: "inline-block",
            }}
          >
            <img
              src={require("./images/slice8.png")}
              alt="coupon codes cta"
              style={{ width: "100%" }}
            />
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "30%", // Adjust this value based on how much of the bottom you want clickable
                backgroundColor: "rgba(0, 0, 0, 0)", // Transparent background
                cursor: "pointer",
              }}
              onClick={() => console.log("hello")}
            ></div>
          </div>

          <Box p={2} sx={{ paddingTop: "50px", background: "#030418" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                color: "#fff",
                marginBottom: "16px",
                textAlign: "center",
              }}
            >
              F A Qs
            </Typography>
            {faqExpand ? faqs.map((faq, i) => (
              <Box
                sx={{
                  marginBottom: "16px",
                  padding: "2px",
                  background:
                    "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);",
                  borderRadius: "14px",
                }}
              >
                <Accordion
                  sx={{
                    borderRadius: "14px !important",
                    background: "#1c1d2f",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}>
                    {faq?.question}
                  </AccordionSummary>
                  <AccordionDetails>{faq?.answer}</AccordionDetails>
                </Accordion>
              </Box>
            )) : faqs.slice(0, 3).map((faq, i) => (
              <Box
                sx={{
                  marginBottom: "16px",
                  padding: "2px",
                  background:
                    "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);",
                  borderRadius: "14px",
                }}
              >
                <Accordion
                  sx={{
                    borderRadius: "14px !important",
                    background: "#1c1d2f",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}>
                    {faq?.question}
                  </AccordionSummary>
                  <AccordionDetails>{faq?.answer}</AccordionDetails>
                </Accordion>
              </Box>
            ))}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
              <Button
                onClick={() => setFaqExpand(!faqExpand)}
                sx={{
                  height: "36px",
                  padding: "8px 43px",
                  borderRadius: "12px",
                  border: "1px solid #fff",
                  textTransform: "none",
                  color: "#fff",
                  backgroundColor: "#1c1d2f",
                }}
              >
                View {faqExpand ? "less" : "all"}{" "}
                {faqExpand ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Button>
            </Box>

          </Box>

          <Box p={2} sx={{ paddingTop: "8px", background: "#030418" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                color: "#fff",
                marginBottom: "16px",
                textAlign: "center",
              }}
            >
              TERMS & CONDITIONS
            </Typography>
            <Box
              sx={{
                marginBottom: "16px",
                padding: "2px",
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);",
                borderRadius: "14px",
              }}
            >
              <Accordion
                sx={{
                  borderRadius: "14px !important",
                  background: "#1c1d2f",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}>
                  Terms & Conditions
                </AccordionSummary>
                <AccordionDetails>
                  <TermsAndConditions />
                </AccordionDetails>
              </Accordion>
            </Box>


          </Box>
        </Grid>

        <Box>
          <SwipeableDrawer
            anchor="bottom"
            open={acceleratedBrands}
            onClose={() => setAcceleratedBrands(false)}
            onOpen={() => setAcceleratedBrands(true)}
            // swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{ width: "100%", marginX: "0.625rem" }}
            PaperProps={{
              style: { backgroundColor: "transparent", boxShadow: "none" },
            }}
          >
            <AcceleratedBrands
              closefunc={() => setAcceleratedBrands(false)}
            />
          </SwipeableDrawer>
        </Box>

        <Box>
          <SwipeableDrawer
            anchor="bottom"
            open={couponCodes}
            onClose={() => setCouponCodes(false)}
            onOpen={() => setCouponCodes(true)}
            // swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{ width: "100%", marginX: "0.625rem" }}
            PaperProps={{
              style: { backgroundColor: "transparent", boxShadow: "none" },
            }}
          >
            <CouponBrands closefunc={() => setCouponCodes(false)} />
          </SwipeableDrawer>
        </Box>

        <Box>
          <SwipeableDrawer
            anchor="bottom"
            open={eligibleBrands}
            onClose={() => setEligibleBrands(false)}
            onOpen={() => setEligibleBrands(true)}
            // swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{ width: "100%", marginX: "0.625rem" }}
            PaperProps={{
              style: { backgroundColor: "transparent", boxShadow: "none" },
            }}
          >
            <EligibleBrands closefunc={() => setEligibleBrands(false)} />
          </SwipeableDrawer>
        </Box>

        <Box>
          <SwipeableDrawer
            anchor="bottom"
            open={activatePass}
            onClose={() => setActivatePass(false)}
            onOpen={() => setActivatePass(true)}
            // swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{ width: "100%", marginX: "0.625rem" }}
            PaperProps={{
              style: { backgroundColor: "transparent", boxShadow: "none" },
            }}
          >
            <ActivatePass closefunc={() => setActivatePass(false)} />
          </SwipeableDrawer>
        </Box>
      </Grid>


      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
    </Grid>
  );
}


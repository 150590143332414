import React, { useEffect } from 'react';
// import { useCookies } from "react-cookie";
import {useNavigate } from 'react-router-dom';

export default function Logout() {
    const navigate = useNavigate();
    // const [cookie, setCookie,removeCookie] = useCookies(['user']);

    useEffect(() => {
        // removeCookie("token");
        localStorage.removeItem("aat");
        localStorage.removeItem("ck_bundle");
        localStorage.removeItem("access_token");
        localStorage.removeItem("bki");
        localStorage.removeItem("bka");
        navigate('/login')
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <></>

    )

}
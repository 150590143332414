import React, { useRef } from 'react';
import { useState, useEffect, } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, IconButton, Paper, Typography, SwipeableDrawer, TextField, Box, FormHelperText, Stack, Divider, CircularProgress } from '@mui/material';
import Topbanner from '../../images/Background.svg';
import Loader from '../../components/loader';
// import Avator1 from '../../images/boy/avatar1.png';
// import Avator2 from '../../images/boy/avatar2.png';
// import Avator3 from '../../images/boy/avatar3.png';
// import Avator4 from '../../images/boy/avatar4.png';
// import Avator5 from '../../images/boy/avatar5.png';
// import Avator6 from '../../images/boy/avatar6.png';
// import Avator7 from '../../images/boy/avatar7.png';
// import Avator8 from '../../images/boy/avatar8.png';
// import Avator9 from '../../images/boy/avatar9.png';
// import Avator10 from '../../images/boy/avatar10.png';
// import girl1 from '../../images/girl/girl1.png';
// import girl2 from '../../images/girl/girl2.png';
// import girl3 from '../../images/girl/girl3.png';
// import girl4 from '../../images/girl/girl4.png';
// import girl5 from '../../images/girl/girl5.png';
// import girl6 from '../../images/girl/girl6.png';
// import girl7 from '../../images/girl/girl7.png';
// import girl8 from '../../images/girl/girl8.png';
// import girl9 from '../../images/girl/girl9.png';
// import girl10 from '../../images/girl/girl10.png';
import Alert from '../../components/alert';
import First from '../../images/avatar1.png';
import Second from '../../images/Boy2.png';
import Third from '../../images/Boy1.png';
import Gold from '../../images/gold.png';
import LoaderImg from '../../components/loader4.svg'
import Silver from '../../images/silver.png';
import Bronze from '../../images/bronze.png';
import Rank from '../../images/rank.png';
import { useNavigate } from 'react-router-dom';
import GoldMedal from '../../images/gold-medal.png';
import BronzeMedal from '../../images/bronze-medal.png';
import Equal from '../../images/equal.svg';
import Up from '../../images/up.png';
import Down from '../../images/down.png';
import SilverMedal from '../../images/silver-medal.png';
import Prize from '../../images/reward-banner.svg';
import Button from '@mui/material/Button';
import RemoveIcon from '@mui/icons-material/Remove';
import { useFormik, Form, FormikProvider } from 'formik';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxTickIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxIconn from '@mui/icons-material/CheckBox';
import { Squircle } from 'corner-smoothing';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import Badge from '@mui/material/Badge';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));



const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    padding: '10px 15px 10px 15px',
    fontSize: '0.875rem',
    fontWeight: '600',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: '10px 0px 10px 0px',
}));

export default function Index() {
    const handleBackClick = () => {
        // console.log('Back button clicked');
        // Add your navigation logic here if needed
    };
    const [data, setData] = useState([]);
    const [rank1User, setRank1User] = useState(null); // State to hold the rank 1 user data
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState('panel1');
    const handleAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const scrollToElementt = () => {
        formReff.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    const formReff = useRef(null)
    const [fieldset, setfieldset] = useState(false)
    const [currentUser, setCurrentUser] = useState({})
    const [ck_id, setck_id] = useState({
        ck_id: '',
    });
    const getMedalImage = (rank) => {
        if (rank <= 3) {
            return GoldMedal;   // Assuming GoldMedal is imported and available
        } else if (rank <= 10) {
            return SilverMedal; // Assuming SilverMedal is imported and available
        } else if (rank <= 50) {
            return BronzeMedal; // Assuming BronzeMedal is imported and available
        } else {
            return null;        // Return null for ranks above 50
        }
    };

    const [topRanks, setTopRanks] = useState({
        rank1: null,
        rank2: null,
        rank3: null
    });
    const [rank1, setRank1] = useState({
        ck_id: 0, current_rank: 0, last_rank: 0, points: 0, total_order_value: 100, transactions: 0, user: { avatar: 0, avatar: 0, name: '' }

    })


    const [checked, setChecked] = React.useState(true);


    const fetchLeaderboardData = () => {
        if (!localStorage.getItem('fc_id')) {
            navigate('/flash-clash')
        } else {
            setLoading(true);
            const requestOptions = {
                method: "GET",
                // headers: { "Authorization": `Bearer ${token}` },
                header: { "Content-Type": "application/json" },
            };
            fetch(`${process.env.REACT_APP_SITE_URL_FLASHCLASH}/api/user/getleaderboard_fc?ck_id=${localStorage.getItem('fc_id')}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setLoading(false)
                    if (data && data?.user_data) {
                        setCurrentUser(data?.user_data)
                    }
                    if (data && data?.rows) {
                        setData(data?.rows);

                        setRank1User(data?.rows.slice(0, 3));
                    } else {
                        throw new Error(data.message || "Failed to load data");
                    }
                })
                .catch(error => {
                    // console.error('Error fetching data: ', error);
                    setError(error?.message);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        fetchLeaderboardData();
    }, []);

    function maskNumber(number) {
        // Convert the number to a string
        const numStr = number.toString();

        // Get the first 2 and last 3 digits
        const firstTwo = numStr.slice(0, 2);
        const lastThree = numStr.slice(-3);

        // Calculate the number of 'x' characters needed
        const numXs = numStr.length - 5;

        // Create the masked part of the number
        const maskedSection = 'x'.repeat(numXs);

        // Combine all parts to form the final masked number
        return `${firstTwo}${maskedSection}${lastThree}`;
    }

    // Example usage:
    // console.log(maskNumber(123456789)); // Output: 12xxxxx789

    // console.log(rank1User)

    const mobile = localStorage.getItem('fc_contact') ? maskNumber(localStorage.getItem('fc_contact')) : "N/A"

    return (

        <Grid container sx={{ background: '#fff' }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
            </Grid>
            {loading ? <Box sx={{ display: "block", margin: "0 auto", marginTop: "50%" }}>
                <CircularProgress />
            </Box> : <Grid item xs={12} sm={12} md={4} lg={4}>
                <Grid item xs={12}>
                    <img src={Topbanner} alt="Topbanner" style={{ width: '100%', }} />
                </Grid>
                {rank1User &&
                    <Grid container px={2.25} sx={{ background: '#0C3BCB' }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} sx={{ marginTop: '-48%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: '700', fontSize: '0.875rem', fontFamily: 'Metropolis', color: '#FFFFFF' }}>{rank1User[1]?.current_rank}</Typography>
                            <Badge
                                badgeContent={<img src={Silver} alt="silver" style={{ width: '80%', marginTop: '10px', marginLeft: '10px' }} />}
                            >
                                {rank1User[1]?.user?.gender === 'F' ? <img style={{ width: '64px' }} src={'/static/girl/girl' + rank1User[1]?.users_fc?.avatar + '.png'} /> :
                                    <img style={{ width: '64px' }} src={'/static/boy/avatar' + rank1User[1]?.users_fc?.avatar + '.png'} />
                                }
                            </Badge>
                            <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', fontFamily: 'Metropolis', color: '#E7E9F3' }}>{rank1User[1]?.users_fc?.name}</Typography>
                            <Typography sx={{ fontWeight: '600', fontSize: '0.625rem', fontFamily: 'Metropolis', color: '#E7E9F3' }}>{rank1User[1]?.users_fc?.contact}</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} sx={{ marginTop: '-65%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                            <Typography sx={{ fontWeight: '700', fontSize: '0.875rem', fontFamily: 'Metropolis', color: '#FFFFFF' }}>{rank1User[0]?.current_rank}</Typography>
                            <Badge
                                badgeContent={<img src={Gold} alt="Gold" style={{ width: '80%', marginTop: '10px', marginLeft: '10px' }} />}
                            >
                                {rank1User[0]?.user?.gender === 'F' ? <img style={{ width: '64px' }} src={'/static/girl/girl' + rank1User[0]?.users_fc?.avatar + '.png'} /> :
                                    <img style={{ width: '64px' }} src={'/static/boy/avatar' + rank1User[0]?.users_fc?.avatar + '.png'} />
                                }
                            </Badge>
                            <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', fontFamily: 'Metropolis', color: '#E7E9F3' }}>{rank1User[0]?.users_fc?.name}</Typography>
                            <Typography sx={{ fontWeight: '600', fontSize: '0.625rem', fontFamily: 'Metropolis', color: '#E7E9F3' }}>{rank1User[0]?.users_fc?.contact}</Typography>


                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} sx={{ marginTop: '-48%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: '700', fontSize: '0.875rem', fontFamily: 'Metropolis', color: '#FFFFFF' }}>{rank1User[2]?.current_rank}</Typography>
                            <Badge
                                badgeContent={<img src={Bronze} alt="Bronze" style={{ width: '80%', marginTop: '10px', marginLeft: '10px' }} />}
                            >
                                {rank1User[2]?.user?.gender === 'F' ? <img style={{ width: '64px' }} src={'/static/girl/girl' + rank1User[2]?.users_fc?.avatar + '.png'} /> :
                                    <img style={{ width: '64px' }} src={'/static/boy/avatar' + rank1User[2]?.users_fc?.avatar + '.png'} />
                                }
                            </Badge>
                            <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', fontFamily: 'Metropolis', color: '#E7E9F3' }}>{rank1User[2]?.users_fc.name}</Typography>
                            <Typography sx={{ fontWeight: '600', fontSize: '0.625rem', fontFamily: 'Metropolis', color: '#E7E9F3' }}>{rank1User[2]?.users_fc.contact}</Typography>
                        </Grid>
                    </Grid>


                }



                <Grid container>
                    <Squircle
                        topLeftCornerRadius={16}
                        topRightCornerRadius={16}
                        style={{
                            marginTop: '-1.5rem',
                            padding: '0.75rem 0rem 1.5rem 0rem',
                            backgroundColor: '#0C3BCB',
                            color: '#fff',
                            display: 'inline-block',
                            width: '100%'
                        }}
                    >
                        <Grid item xs={12} >
                            <Typography sx={{ fontWeight: '400', fontSize: '0.75rem', fontFamily: 'Metropolis', textAlign: 'center', }}>
                                Rankings are refreshed daily at 9AM.<span style={{ color: '#FED624', fontWeight: '600', fontSize: '0.625rem', }}><a onClick={scrollToElementt}> Read T&Cs here.</a></span>
                            </Typography>
                        </Grid>
                    </Squircle>
                </Grid>

                {!currentUser?.user?.leaderboards_fc?.current_rank && <><Squircle
                    topLeftCornerRadius={16}
                    topRightCornerRadius={16}
                    style={{
                        marginTop: '-0.725rem',
                        backgroundColor: '#fff',
                        color: '#fff',
                        display: 'inline-block',
                        width: '100%'
                    }}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography px={2.25} mt={3} mb={1.5} sx={{ color: '#000', fontWeight: '800', fontSize: '1rem', fontFamily: 'Metropolis', lineHeight: '1.5rem' }}>Keep your eyes on the prize!</Typography>
                        <img src={Prize} style={{ width: '100%', paddingLeft: '0.125rem', paddingRight: '0.125rem' }} />
                    </Grid>

                    <Grid container sx={{ alignItems: 'center', }} px={2.25} mt={3}>
                        <Squircle
                            topLeftCornerRadius={16}
                            topRightCornerRadius={16}
                            style={{
                                backgroundColor: '#F9E0D2',
                                color: '#fff',
                                width: '100%',
                            }}
                        >

                            <Grid container alignItems="center" pt={2.5}>
                                <Grid item xs={3} sm={3} md={4} lg={3}>
                                    {currentUser?.user?.gender === 'F' ? <img style={{ width: '100%' }} src={'/static/girlwithoutborder/Girl' + currentUser?.user?.avatar + '.png'} /> :
                                        <img style={{ width: '100%' }} src={'/static/boywithoutborder/avatar' + currentUser?.user?.avatar + '.png'} />
                                    }
                                </Grid>
                                <Grid item xs={5} sm={5} md={5} lg={5}>
                                    <Typography variant="body1" sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#000', fontFamily: 'Metropolis' }}>{currentUser?.user?.name ? currentUser?.user?.name : 'N/A'}</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: '700', fontSize: '1rem', color: '#000', fontFamily: 'Metropolis' }}>Points: N/A</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                    <Typography variant="body1" sx={{ fontWeight: '600', fontSize: '0.725rem', color: '#262626', fontFamily: 'Metropolis' }}>{currentUser?.user?.contact ? currentUser?.user?.contact : mobile}</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: '700', fontSize: '1rem', color: '#000', fontFamily: 'Metropolis' }}>Rank: N/A</Typography>
                                </Grid>
                            </Grid>
                        </Squircle>
                    </Grid>
                </Squircle>


                    <Grid container>
                        <Grid item xs={12} mx={2.25}>
                            <Squircle
                                bottomLeftCornerRadius={16}
                                bottomRightCornerRadius={16}
                                style={{
                                    backgroundColor: '#F7F9FF',
                                    color: '#fff',
                                    display: 'inline-block',
                                    width: '100%'
                                }}
                            >
                                <Typography variant="body1" px={6} py={2.5} sx={{ fontWeight: '500', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis', textAlign: 'center' }}>
                                    Get started by making your first transaction on flash deals and begin scoring points
                                </Typography>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: "center" }} pt={1} pb={3}>
                                    <Button
                                        variant="contained"
                                        sx={{ color: '#fff', fontWeight: '600', fontSize: '1rem', fontFamily: 'Metropolis', background: '#FF6D1D', width: '80%', height: '3rem', textTransform: 'inherit', borderRadius: '8px' }}
                                    // onClick={() => window.open('https://cashkaro.com/top-selling-products/flash-deals')}
                                    >
                                        <a style={{ textDecoration: "none", color: "#fff" }} href="cashkaro://top-selling-products/flash-deals">
                                            Shop on Flash Deals
                                        </a>
                                    </Button>
                                </Grid>
                            </Squircle>
                        </Grid>

                    </Grid></>}
                {/* if user already exist */}
                {currentUser?.user?.leaderboards_fc?.current_rank && <Squircle
                    topLeftCornerRadius={16}
                    topRightCornerRadius={16}
                    style={{
                        marginTop: '-0.725rem',
                        backgroundColor: '#fff',
                        color: '#fff',
                        display: 'inline-block',
                        width: '100%'
                    }}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography px={2.25} mt={3} mb={1.5} sx={{ color: '#000', fontWeight: '800', fontSize: '1rem', fontFamily: 'Metropolis', lineHeight: '1.5rem' }}>Keep your eyes on the prize!</Typography>
                        <img src={Prize} style={{ width: '100%', paddingLeft: '0.125rem', paddingRight: '0.125rem' }} />
                    </Grid>
                    <Grid container sx={{ alignItems: 'center', position: "relative" }} px={2.25} mt={3}>
                        <Squircle
                            cornerRadius={16}
                            style={{
                                backgroundColor: '#F9E0D2',
                                color: '#fff',
                                width: '100%',

                            }}
                        >
                            <Grid container alignItems="center" pt={2.5}>
                                <Grid item xs={3} sm={3} md={4} lg={3}>
                                    {currentUser?.user?.gender === 'F' ? <img style={{ width: '100%' }} src={'/static/girlwithoutborder/Girl' + currentUser?.user?.avatar + '.png'} /> :
                                        <img style={{ width: '100%' }} src={'/static/boywithoutborder/avatar' + currentUser?.user?.avatar + '.png'} />
                                    }
                                </Grid>
                                <Grid item xs={5} sm={5} md={5} lg={5}>
                                    <Typography variant="body1" sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#000', fontFamily: 'Metropolis' }}>{currentUser?.user?.name}</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: '700', fontSize: '1rem', color: '#000', fontFamily: 'Metropolis' }}>Points: {currentUser?.user?.leaderboards_fc?.points}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                    <Typography variant="body1" sx={{ fontWeight: '600', fontSize: '0.725rem', color: '#262626', fontFamily: 'Metropolis' }}>{currentUser?.user?.contact}</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: '700', fontSize: '1rem', color: '#000', fontFamily: 'Metropolis' }}>Rank: {currentUser?.user?.leaderboards_fc?.current_rank}</Typography>
                                    <img
                                        src={currentUser?.user?.leaderboard?.last_rank === null ||
                                            currentUser?.user?.leaderboard?.last_rank == 0 ||
                                            currentUser?.user?.leaderboard?.current_rank <= currentUser?.user?.leaderboard?.last_rank ? Up : Down}
                                        alt={currentUser?.user?.leaderboard?.last_rank === null ||
                                            currentUser?.user?.leaderboard?.last_rank == 0 ||
                                            currentUser?.user?.leaderboard?.current_rank <= currentUser?.user?.leaderboard?.last_rank ? "Up" : "Down"}
                                        style={{ width: '6%', position: "absolute", top: "12px", right: "30px" }}
                                    />

                                    {/* <img src={currentUser?.user?.leaderboard?.current_rank < currentUser?.user?.leaderboard?.last_rank ? Up : Down} alt={currentUser?.user?.current_rank < currentUser?.user?.last_rank ? "Up" : "Down"} style={{ width: '6%', position: "absolute", top: "12px", right: "30px" }} /> */}
                                </Grid>
                            </Grid>
                        </Squircle>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: "flex-end" }} pt={1} pb={3} mt={-2.5} mr={2}>
                            {/* <a href="cashkaro://top-selling-products/flash-deals"> */}
                            <Button
                                variant="contained"
                                sx={{ color: '#fff', fontWeight: '600', fontSize: '0.875rem', fontFamily: 'Metropolis', background: '#FF6D1D', width: '60%', height: '3rem', textTransform: 'inherit', borderRadius: '8px' }}
                            >
                                <a style={{ textDecoration: "none", color: "#fff" }} href="cashkaro://top-selling-products/flash-deals">
                                    Shop on Flash Deals
                                </a>
                                {/* Shop on Flash Deals */}
                            </Button>
                            {/* </a>                  */}
                        </Grid>
                    </Grid>
                </Squircle>
                }

                <Grid container sx={{ background: '#fff', }} pt={2} pb={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} px={2} >
                        <Box >
                            <Grid container px={2}>
                                <Grid item xs={6} sm={6} md={6} lg={6} >
                                    <Typography sx={{ fontFamily: 'Metropolis', color: '#ACB5BD', fontSize: '0.75rem', fontWeight: '600' }}>Leaderboard</Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2}  >
                                    <Typography sx={{ fontFamily: 'Metropolis', color: '#ACB5BD', fontSize: '0.75rem', fontWeight: '600' }}>Points</Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2}  >
                                    <Typography sx={{ fontFamily: 'Metropolis', color: '#ACB5BD', fontSize: '0.75rem', fontWeight: '600' }}>Rank</Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2} sx={{ textAlign: 'center' }}>
                                    <Typography sx={{ fontFamily: 'Metropolis', color: '#ACB5BD', fontSize: '0.75rem', fontWeight: '600' }}>Pos</Typography>
                                </Grid></Grid>
                        </Box>


                    </Grid>
                </Grid>

                {data.map((item, index) => (
                    <Grid container key={index} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '4rem',
                        background: index % 2 === 0 ? '#F3F3F3' : '#FFF'  // Even rows get #F3F3F3, odd rows get #FFF
                    }}>
                        <Grid item xs={1} sm={1} md={1} lg={1} pl={2}>
                            {getMedalImage(item?.current_rank) ? <img src={getMedalImage(item?.current_rank)} alt="Medal" style={{ width: '90%' }} /> : ''}
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                            {item?.users_fc?.gender === 'F' ? <img style={{ width: '100%' }} src={'/static/girl/girl' + item?.users_fc?.avatar + '.png'} /> :
                                <img style={{ width: '100%' }} src={'/static/boy/avatar' + item?.users_fc?.avatar + '.png'} />
                            }
                            {/* <img src={getImageBasedOnGender(item.user.gender)} alt="Avatar" style={{ width: '100%' }} /> */}
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} pl={2}>
                            <Typography sx={{ color: '#262626', fontWeight: '600', fontSize: '0.75rem', textTransform: 'capitalize', fontFamily: 'Metropolis', }}>
                                {item?.users_fc?.name}
                            </Typography>
                            <Typography sx={{ color: '#7E858B', fontWeight: '500', fontSize: '0.625rem', fontFamily: 'Metropolis', }}>{item?.users_fc?.contact}</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                            <Typography sx={{ color: '#111111', fontWeight: '600', fontSize: '0.875rem', fontFamily: 'Metropolis' }}>{item?.points}</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={4} lg={2}>
                            <Typography sx={{ color: '#111111', fontWeight: '600', fontSize: '0.875rem', fontFamily: 'Metropolis' }}>{item?.current_rank}</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                            <img src={item.last_rank === null || item.last_rank === 0 || item.current_rank <= item.last_rank ? Up : Down}
                                alt={item.last_rank === null || item.last_rank === 0 || item.current_rank <= item.last_rank ? "Up" : "Down"}
                                style={{ width: '30%' }}
                            />

                        </Grid>
                    </Grid>
                ))}

                <Grid ref={formReff} item xs={12} mt={4} mx={2.25}>
                    <Typography variant='h2' mt={1} sx={{ fontWeight: '700', color: '#262626', fontSize: '1.375rem', fontFamily: 'Metropolis', lineHeight: '1.25rem', textAlign: 'center', }}>
                        Terms & Conditions </Typography>
                    <Typography variant='h2' mt={1} sx={{ fontWeight: '600', color: '#262626', fontSize: '0.75rem', fontFamily: 'Metropolis', lineHeight: '1.25rem', textAlign: 'center', }}>
                        Welcome to the Leaderboard Contest at CashKaro! By participating, you engage in a spirited competition to earn points and win exciting prizes. Please carefully review these terms and conditions.</Typography>
                </Grid>
                 <div style={{ marginTop: '2rem', width: '100%', }}>

                        <Accordion expanded={expanded === 'panel1'} onChange={handleAccordion('panel1')}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={expanded === 'panel1' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Eligibilty</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}><ul><li>The contest is open only to registered users of CashKaro.</li></ul></Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleAccordion('panel2')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded === 'panel2' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Contest Period</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}><ul>
                                            <li>The contest will commence on 04th June 2024 and conclude on 30th June 2024.</li>
                                            <li>CashKaro reserves the right to extend, modify, or terminate the contest period at its sole discretion.</li>
                                            </ul></Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleAccordion('panel3')}>
                            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={expanded === 'panel3' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Participation</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}>
                                        <ul>
                                            <li>To participate, users must register for the contest through the dedicated registration page.</li>
                                            <li>Participation requires the phone number linked to the participant’s CashKaro account and consent to share ranking publicly.</li>
                                            </ul>

                                        </Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion><Accordion expanded={expanded === 'panel4'} onChange={handleAccordion('panel4')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded === 'panel4' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Contest Mechanics</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}><ul>
                                            <li>Points are awarded based on the number of transactions completed on Flash Deals. Each transaction will earn you 100 points.</li>
                                            <li>The leaderboard will be updated daily at 9:00 AM and will reflect rankings based on transactions from the previous day.</li>
                                            <li>Rankings are determined by the total points accumulated during the contest period.</li>
                                            <li>In the event that two or more participants achieve the same rank based on the points accumulated, the total order value of their transactions during the contest period will be used as a tie-breaker.</li>
                                            <li>Participants can access the leaderboard via the banners provided on the homepage to check their current standing and points total.</li>
                                            </ul></Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel5'} onChange={handleAccordion('panel5')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded === 'panel5' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Prizes</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}>
                                        <ul>
                                            <li>By 10th July 2024, leaderboard rankings will be finalized and minimum point threshold for each reward tier will be set. Participants will be notified of these thresholds and their ranks. If cancellations cause a participant's points to drop below these thresholds, their ranking and reward tier will be adjusted accordingly:
                                                <ol>
                                                    <li><b>Tier 1 - Positions 1-3:</b> If a participant drops below the top 3 threshold, they will shift to a lower tier based on the number of cancellations.</li>
                                                    <li><b>Tier 2 - Positions 4-10:</b> Participants will drop to the 11-50 tier if their revised score falls below the top 10 threshold.</li>
                                                    <li><b>Tier 3 - Positions 11-50:</b> Participants falling below the 50th rank due to point deductions will not receive any rewards.</li>
                                                   </ol>
                                            </li>
                                            </ul>
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel6'} onChange={handleAccordion('panel6')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded === 'panel6' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Prize Distribution</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}>
                                        <ul>
                                            <li>Winners will receive their gift cards within 10 days of their transactions getting validated and confirmed as legitimate and final. The gift cards will be shared with users on their registered CashKaro email ID.</li>
                                            <li>If any transaction contributing to a participant's points is cancelled for any reason, the points associated with that transaction will be deducted from the participant’s total. This will affect the participant’s ranking on the leaderboard.</li>
                                            <li>Rewards will be distributed only after all necessary validations are complete. CashKaro reserves the right to withhold rewards until all aspects of a participant's transactions are confirmed.</li>
                                            </ul>
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel7'} onChange={handleAccordion('panel7')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded === 'panel7' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Disqualification and Adjustment of Scores</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}>
                                        <ul>
                                            <li>In the event of any discrepancies, including but not limited to cancelled transactions or fraudulent activities, CashKaro reserves the right to adjust the scores and rankings of participants.</li>
                                            <li>While scores and ranks can decrease due to transaction cancellations, no participant will move upward in rank as a result of another participant’s score adjustment</li>
                                            <li>These measures are taken to ensure fairness and integrity of the contest.</li>
                                            <li>CashKaro reserves the right to disqualify any participant found to be tampering with the entry process or operating in violation of the rules.</li>
                                            <li>Fraudulent activities will be monitored and will lead to exclusion.</li>

                                            </ul>
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        {/* <Accordion expanded={expanded === 'panel7'} onChange={handleAccordion('panel7')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded === 'panel7' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Disqualification</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}>The contest is open only to registered users of CashKaro.</Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion> */}
                        <Accordion expanded={expanded === 'panel8'} onChange={handleAccordion('panel8')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded === 'panel8' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Amendments to Terms</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}><ul><li>CashKaro reserves the right to amend these terms and conditions at any time.</li></ul></Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion><Accordion expanded={expanded === 'panel9'} onChange={handleAccordion('panel9')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded === 'panel9' ? <RemoveIcon /> : <AddIcon />}>
                                <Typography sx={{ fontWeight: '600', fontSize: '0.875rem', color: '#262626', fontFamily: 'Metropolis' }} >Acceptance of Terms</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" sx={{ widht: '100%' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                                    <Stack direction="row" spacing={2} px={3}>
                                        <Typography variant="body2" sx={{ color: "#262626", fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Metropolis' }}><ul><li>By entering the contest, participants agree to be bound by these terms and conditions.</li></ul></Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>

                    </div>
            </Grid>}

            <Grid item xs={12} sm={12} md={4} lg={4}>
            </Grid>
        </Grid>

    )
};
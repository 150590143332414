import React, { useRef } from 'react';
import { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import UserSchema from '../../validations/userSchema';
import { useFormik, Form, FormikProvider } from 'formik';
import { Grid } from '@mui/material';
import { TextField, Box } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export default function Create(props) {
    const dialogBox = useRef()
    const { openPopup, setOpenPopup } = props;
    const [apiErrors, setApiErrors] = useState([]);
    const bundle = localStorage.getItem("ck_aat");
    const login_id = localStorage.getItem("ck_bki");
    // const login_type = localStorage.getItem("bka");

    const formik = useFormik({
        initialValues: {
            name: '',
            mobile: '',
            email: '',
            ekaro_id: '',
            telegram: '',
            status: '',
            approved: '',
        },
        enableReinitialize: true,
        validationSchema: UserSchema,

        onSubmit: (initialValues, { resetForm }) => {
            console.log(initialValues);
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bundle}`
                },
                body: JSON.stringify(initialValues)
            };

            fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/users`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if ('error' in data) {
                        resetForm();
                        setApiErrors(data.errors);
                    } else if('success' in data) {
                        resetForm();
                        setOpenPopup(false);
                        props.setAlert(true);
                        props.refreshTable();
                        props.setType('success');
                        props.setMessage(data.message);
                    } else {
                        resetForm();
                        setOpenPopup(false);
                        props.setAlert(true);
                        props.setType('error');
                        props.setMessage('Something went wrong');
                    }
                });
            // navigate('/bank-info', { state: { path: 'login' } });

        }
    });

    const { errors, touched, handleSubmit, getFieldProps } = formik;
    return (
        <Dialog
            ref={dialogBox}
            open={openPopup}
            onClose={() => { setOpenPopup(false) }}
            maxWidth="md"
        >
            <FormikProvider value={formik}  >
                <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: '10px' }}>
                    <DialogContent >
                        <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: '10px' }}>Add User</Typography>
                        <Grid container spacing={3}>
                            {Object.keys(apiErrors).length !== 0 && <Grid item xs={12} sm={12} md={12}>
                                <Box p={3} sx={{ background: '#DB0011', borderRadius: '8px', color:'#FFFFFF' }}>
                                    {
                                        Object.keys(apiErrors).map((item, i) => (
                                            <Typography key={i}>{apiErrors[item]}</Typography>
                                        ))
                                    }
                                </Box>
                            </Grid>}
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Enter Name"
                                    {...getFieldProps('name')}
                                    size="small"
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Enter Email"
                                    {...getFieldProps('email')}
                                    size="small"
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="tel"
                                    variant="outlined"
                                    label="Enter Mobile"
                                    {...getFieldProps('mobile')}
                                    size="small"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"  >+91 </InputAdornment>,
                                    }}
                                    error={Boolean(touched.mobile && errors.mobile)}
                                    helperText={touched.mobile && errors.mobile}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Enter EK ID"
                                    {...getFieldProps('ekaro_id')}
                                    size="small"
                                    error={Boolean(touched.ekaro_id && errors.ekaro_id)}
                                    helperText={touched.ekaro_id && errors.ekaro_id}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Enter Telegram ID"
                                    {...getFieldProps('telegram')}
                                    size="small"
                                    error={Boolean(touched.telegram && errors.telegram)}
                                    helperText={touched.telegram && errors.telegram}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select Status"
                                        {...getFieldProps('status')}
                                        error={Boolean(touched.status && errors.status)}
                                    >
                                        <MenuItem value="1">Active</MenuItem>
                                        <MenuItem value="0">Inactive</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">Select Approval</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select Approval"
                                        {...getFieldProps('approved')}
                                        error={Boolean(touched.approved && errors.approved)}
                                    >
                                        <MenuItem value="" disabled>Select Approval</MenuItem>
                                        <MenuItem value="1">Approve</MenuItem>
                                        <MenuItem value="2">Disapprove</MenuItem>
                                        <MenuItem value="0">Pending</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" type="button"  color="primary" sx={{ float: 'right', textTransform: 'capitalize', marginRight: '10px' }} onClick={() => setOpenPopup(!openPopup) }>Cancel</Button>
                        <Button variant="contained" type="submit"  color="secondary" sx={{ float: 'right', textTransform: 'capitalize', marginRight: '10px' }}>Save</Button>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}
import React from "react";
import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { acceleratedBrands, eligibleBrands } from "../acceleratedCashbackData";


export default function EligibleBrands(props) {

    return (
        <Grid container>
            <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ position: 'relative', backgroundColor: '#fff', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem',backgroundColor:'#222335'  }} >
                <Box sx={{ width: '100%', margin: '0 !important',height:'70vh' }}>
                 
                    <Grid mx={2} pb={1} pt={1} sx={{ width: '100%', margin: '0 !important'}}>
                    <Box id='header' sx={{ width: '100%', textAlign: 'center', paddingY: '0.5rem', display: 'flex', justifyContent: 'center', backgroundColor: '#222335', position: 'sticky', top: 0, zIndex: 1 }}>
                            <Box sx={{ marginBottom: '0.5rem' }}>
                            <Box  onClick={props.closefunc} sx={{ display: 'flex', justifyContent: 'center',marginBottom:'8px' }}>
                        <Box sx={{ width: '54px', borderTop: '5px solid #D9D9D9', borderRadius: '30px' }}></Box>
                    </Box>
                                <Typography sx={{ fontSize: '16px', fontWeight: 700, lineHeight: '24px', color: '#fff' }}>Eligible Brands</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Divider fullWidth />
                        </Box>
                        <Box sx={{ width: '100%',backgroundColor:'#030418' }}>

                            <Box sx={{backgroundColor:'#030418'}}>

                                {eligibleBrands.map((r,i)=>
                               <Box px={1.5} key={i} sx={{borderBottom:i===eligibleBrands.length-1 ? 'none':'1px solid white',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                 <Box key={i} py={2} sx={{display:'flex',alignItems:'center',gap:'8px',}}>
                                    <img src={require(`../images/increasedCashback/${r.image}.png`)} style={{height:'44px'}} />
                                    <Typography sx={{color:'#fff',fontSize:'16px',fontWeight:600}}>{r?.name}</Typography>
                                </Box>
                                <Box>
                                <Typography sx={{color:'#fff',fontSize:'20px',fontWeight:600,textAlign:'right'}}>{r?.min_order_value}</Typography>
                                <Typography sx={{color:'#9C9DA5',fontSize:'10px',fontWeight:600}}>Minimum Order Value</Typography>


                                </Box>
                                </Box>
                               )}
                              

                              
                              
                            </Box>

                        </Box>

                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
        </Grid>
    );

}